/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - dateTime.ts
 */

import { DateTime } from 'luxon';

export type DateString = `${ number }-${ number }-${ number }`
export const dateFormatString = `yyyy-MM-dd`;

/**
 * Parse ISO date to DateTime forcing UTC
 * @param date
 */
export function parseDate(date: string) {
  return DateTime.fromISO(date, { zone: 'utc' }).startOf('day');
}

/**
 * Format DateTime to Date `yyyy-mm-dd`
 * @param date
 */
export function formatDate(date: DateTime) {
  return date.toFormat(dateFormatString) as DateString;
}

/**
 * Parse `HH:mm:ss` time to DateTime forcing UTC
 * @param time
 */
export function parseTime(time: string) {
  return DateTime.fromFormat(time, 'HH:mm:ss', { zone: 'utc' });
}

/**
 * Format DateTime to time `HH:mm`
 * @param time
 */
export function formatTime(time: DateTime) {
  return time.toISOTime({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false });
}

/**
 * Format DateTime to time with seconds `HH:mm:ss`
 * @param time
 */
export function formatTimeWithSeconds(time: DateTime) {
  return time.toISOTime({ suppressMilliseconds: true, includeOffset: false });
}
