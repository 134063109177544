/*
 * Copyright 2020 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * neo-access - exceptions.js
 */

import NetworkError from './NetworkError';

export class UnauthorizedError extends NetworkError {
  constructor(response) {
    super(response.code, response.status);
  }
}
