/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Schedule.ts
 */

import { makeRoute }                                                                from 'library/modelsUtils';
import { DateTime }                                                                 from 'luxon';
import { Actor, BroadcastTag, Campaign, Content, ExternalResource, ScheduleReview } from 'models';
import Collection                                                                   from 'library/Collection';
import { HTTPMethod }                                                               from 'library/Request';
import { Model }                                                                    from 'library/Model';
import { handleBadRequest, preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import ScheduleStatus                                                               from './enums/ScheduleStatus';
import Capability                                                                   from 'library/Capability';
import { makeRequest }                                                              from 'library/Request/Request';
import { makeModelCollection }                                                      from 'library/Model/factories';
import { formatTimeWithSeconds }                                                    from 'library/utils/dateTime';

interface ScheduleDetails {
  is_approved: boolean,
}

export interface ISchedule {
  id: number,
  campaign_id: number,
  owner_id: number
  batch_id: string | null,
  start_date: DateTime,
  start_time: DateTime,
  end_date: DateTime,
  end_time: DateTime,
  broadcast_days: number,

  broadcast_tags: Collection<BroadcastTag>

  order: number,
  is_locked: boolean,
  locked_at: DateTime | null,

  details: ScheduleDetails

  status: ScheduleStatus,

  owner: Actor,
  contents: Collection<Content>,
  campaign: Campaign,

  reviews_count: number,
  reviews: Collection<ScheduleReview>

  external_representations: Collection<ExternalResource>,
  external_representations_count: number,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,
}

class ScheduleModel extends Model<ISchedule> {
  _slug = 'schedule';

  basePath = '/v2/schedules';

  attributesTypes: { [attr in keyof ISchedule]?: (sourceAttr: any) => ISchedule[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    locked_at : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),

    start_date: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    start_time: (d) => DateTime.fromFormat(d, 'HH:mm:ss', { zone: 'utc' }),
    end_date  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    end_time  : (d) => DateTime.fromFormat(d, 'HH:mm:ss', { zone: 'utc' }),

    reviews : Collection.ofType(ScheduleReview).make,
    contents: Collection.ofType(Content).make,
    campaign: Model.make(Campaign),
    owner   : Model.make(Actor),

    broadcast_tags          : Collection.ofType(BroadcastTag).make,
    external_representations: Collection.ofType(ExternalResource).make,
  };

  key: keyof ISchedule = 'id';

  routesAttributes = {
    create: [
      'order',
    ],
    save  : {
      start_date    : (attr: ISchedule) => attr.start_date.toISODate(),
      start_time    : (attr: ISchedule) => formatTimeWithSeconds(attr.start_time),
      end_date      : (attr: ISchedule) => attr.end_date.toISODate(),
      end_time      : (attr: ISchedule) => formatTimeWithSeconds(attr.end_time),
      broadcast_days: 'broadcast_days',
      is_locked     : 'is_locked',
      tags          : (attr: ISchedule) => attr.broadcast_tags.pluck('id'),
    },
  };
}

export class Schedule extends ScheduleModel implements ISchedule {
  id!: number;

  campaign_id!: number;

  owner_id!: number;

  batch_id!: string | null;

  start_date!: DateTime;

  start_time!: DateTime;

  end_date!: DateTime;

  end_time!: DateTime;

  broadcast_days!: number;

  broadcast_tags!: Collection<BroadcastTag>;

  order!: number;

  is_locked!: boolean;

  details!: ScheduleDetails;

  status!: ScheduleStatus;

  owner!: Actor;

  contents!: Collection<Content>;

  campaign!: Campaign;

  reviews_count!: number;

  reviews!: Collection<ScheduleReview>;

  external_representations!: Collection<ExternalResource>;

  external_representations_count!: number;

  created_at!: DateTime;

  updated_at!: DateTime;

  deleted_at!: DateTime | null;

  locked_at!: DateTime | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }


  /**
   * Load all pending schedules accessible by the current user
   * @returns Promise<LegacyModel>
   */
  static pending = async (signal?: AbortSignal) => {
    const relations = [ 'contents', 'reviews', 'owner', 'tags', 'campaign' ];
    const path      = `${ (new Schedule()).basePath }/_pending`;
    const params    = { with: relations };

    const route = makeRoute(path, HTTPMethod.get);
    return makeRequest<ISchedule[]>(route, null, params, undefined, undefined, signal)
      .then(({ data }) => makeModelCollection(Schedule)(data, relations))
      .catch(handleBadRequest);
  };

  /*
   |--------------------------------------------------------------------------
   | Events
   |--------------------------------------------------------------------------
   */

  async onCreated(response: Partial<ISchedule>): Promise<this> {
    // If the created response is an array, that means multiple schedules where created at once.
    // In this case, skip default behaviour, and just return created ids
    if (Array.isArray(response)) {
      // @ts-ignore
      return response;
    }
    return super.onCreated(response);
  }

  /*
   |--------------------------------------------------------------------------
   | Getters
   |--------------------------------------------------------------------------
   */

  isDraft = () => this.status === ScheduleStatus.Draft;

  isExpired = () => this.status === ScheduleStatus.Expired;

  isTrashed = () => this.status === ScheduleStatus.Trashed;

  isEditable = (user: Actor) => {
    return !this.isExpired() && !this.isTrashed() && (
      (this.isDraft() && user.hasCapability(Capability.contentsSchedule)) ||
      (!this.isDraft() && user.hasCapability(Capability.contentsReview))
    );
  };

  isPending() {
    return this.is_locked && this.status === ScheduleStatus.Pending;
  }

  isEmpty() {
    return this.contents.length === 0;
  }

  isMultiContent() {
    return this.contents.length > 1;
  }

  isValidated() {
    return this.status === ScheduleStatus.Pending
      || this.status === ScheduleStatus.Approved
      || this.status === ScheduleStatus.Live;
  }

  /*
   |--------------------------------------------------------------------------
   | Actions
   |--------------------------------------------------------------------------
   */

  lock() {
    this.is_locked = true;
    return this.save({ with: [ 'owner', 'contents', 'tags' ] });
  }

  duplicate() {
    const path   = preparePathForSingleModelAction(this.basePath, '/_clone');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params);
  }
}

export class CampaignSchedule extends Schedule {
  _slug = 'campaign-schedules';

  basePath = '/v2/campaigns/{campaign_id}/schedules';
}

export class CampaignExpiredSchedule extends Schedule {
  _slug = 'expired-schedules';

  basePath = '/v2/campaigns/{campaign_id}/expired-schedules';
}
