/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ReviewTemplate.ts
 */

import { Model }              from 'library/Model';
import { DateTime }           from 'luxon';
import { ModelPersistAction } from 'library/Model/types';
import { Actor }              from 'models';

interface IReviewTemplate {
  id: number,
  text: string,
  owner_id: number
  created_at: DateTime,
  updated_at: DateTime,

  owner: Actor,
}

class ReviewTemplateModel extends Model<IReviewTemplate> {
  _slug: string = 'review-template';

  key: string = 'id';

  basePath: string = '/v1/review-templates';

  attributesTypes: { [attr in keyof IReviewTemplate]?: (sourceAttr: any) => IReviewTemplate[attr] } = {
    owner       : Model.make(Actor),
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [ 'text', 'owner_id' ],
    save  : [ 'text', 'owner_id' ],
  };
}

export class ReviewTemplate extends ReviewTemplateModel implements IReviewTemplate {
  created_at!: DateTime;

  id!: number;

  owner_id!: number;

  text!: string;

  updated_at!: DateTime;

  owner!: Actor;

  constructor(attributes?: Partial<IReviewTemplate>) {
    super();
    this.setAttributes(attributes ?? {});
  }
}
