/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - queryModel.ts
 */

import { Model as ModelRedux }                                          from './Model';
import { getQueryModelKey }                                             from './queryKeys';
import { getQueryModelFn }                                              from './queryFunctions';
import { CommonQueryOptions }                                           from './types';
import { QueryObserverOptions, useQuery, UseQueryResult, WithRequired } from '@tanstack/react-query';
import { queryClient }                                                  from './QueryClient';
import { GetAllModelsOptions }                                          from './queryAllModels';

export interface GetModelOptions extends CommonQueryOptions {
}

function getQueryModelArguments<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  key: number | string,
  options: GetModelOptions = {},
): WithRequired<QueryObserverOptions<Model | null>, 'queryKey'> {
  const {
          params    = {},
          relations = [],
          auth      = true,
          ...queryOptions
        }            = options;
  // Assemble params
  const mergedParams = { with: relations, ...params };

  return {
    ...queryOptions as QueryObserverOptions<Model | null>,
    queryKey: getQueryModelKey(modelType, key, mergedParams),
    queryFn : getQueryModelFn(modelType, key, mergedParams, { auth: options.auth }),
  };
}

export function useModel<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  key: number | string,
  options: GetModelOptions = {},
): Omit<UseQueryResult, 'data'> & { model: Model | null } {
  const { data, ...queryState } = useQuery(getQueryModelArguments(modelType, key, options));
  return { model: data ?? null, ...queryState };
}

export async function getModel<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  key: number | string,
  options: GetAllModelsOptions = {},
) {
  return queryClient.fetchQuery(getQueryModelArguments(modelType, key, options));
}
