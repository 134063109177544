/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryConnectionStatus.ts
 */

export const enum InventoryConnectionStatus {
  NotAssociated      = 'not-associated',
  Associated         = 'associated',

  Disabled           = 'disabled',
  DisabledAssociated = 'disabled-associated',

  Enabled            = 'enabled',
  EnabledAssociated  = 'enabled-associated',
}
