/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryPicture.ts
 */

import { DateTime }             from 'luxon';
import { Model }                from 'library/Model';
import { ModelPersistAction }   from 'library/Model/types';
import { Product, Property }    from 'models';
import { InventoryPictureType } from './enums/InventoryPictureType';

export interface IPropertyPicture {
  id: number,
  property_id: number | null,
  product_id: number | null,
  name: string,
  type: InventoryPictureType,
  width: number,
  height: number,
  description: string,

  order: number,
  extension: string,
  thumbnail_url: string
  url: string

  property: Property | null,
  product: Product | null,

  created_at: DateTime,
  updated_at: DateTime,
}

class PropertyPictureModel extends Model<IPropertyPicture> {
  _slug = 'picture';

  basePath = '/v1/pictures';

  attributesTypes: { [attr in keyof IPropertyPicture]?: (sourceAttr: any) => IPropertyPicture[attr] } = {
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    property    : Model.make(Property),
    product     : Model.make(Product),
  };

  key: keyof IPropertyPicture = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IPropertyPicture | string)[] } = {
    create: [ 'property_id', 'product_id' ],
    save  : [ 'type', 'property_id', 'product_id', 'name', 'description' ],
  };
}

export class InventoryPicture extends PropertyPictureModel implements IPropertyPicture {
  description!: string;

  product_id!: number | null;

  created_at!: DateTime;

  extension!: string;

  height!: number;

  id!: number;

  name!: string;

  order!: number;

  property_id!: number | null;

  updated_at!: DateTime;

  url!: string;

  width!: number;

  product!: Product | null;

  property!: Property | null;

  thumbnail_url!: string;

  type!: InventoryPictureType;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onCreated(response: Partial<IPropertyPicture>): Promise<this> {
    await super.onCreated(response);
    await (new Property({ actor_id: this.property_id })).invalidate(true, false);
    if (this.product_id) {
      await (new Property({ id: this.product_id })).invalidate(true, false);
    }

    return this;
  }

  async onSaved(response: Partial<IPropertyPicture>): Promise<this> {
    await super.onSaved(response);
    await (new Property({ actor_id: this.property_id })).invalidate(true, false);
    if (this.product_id) {
      await (new Property({ id: this.product_id })).invalidate(true, false);
    }

    return this;
  }

  async onDeleted(response: Partial<IPropertyPicture>): Promise<this> {
    await super.onDeleted(response);
    await (new Property({ actor_id: this.property_id })).invalidate(true, false);
    if (this.product_id) {
      await (new Property({ id: this.product_id })).invalidate(true, false);
    }

    return this;
  }
}
