/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - FormatCropFrame.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IFormatCropFrame {
  id: number,
  format_id: number,
  display_type_frame_id: number,

  pos_x: number,
  pos_y: number,
  scale: number,
  aspect_ratio: number,

  created_at: DateTime,
  updated_at: DateTime,
}

class FormatCropFrameModel extends Model<IFormatCropFrame> {
  _slug = 'format-crop-frame';

  basePath = '/v2/formats/{format_id}/crop-frames';

  attributesTypes: { [attr in keyof IFormatCropFrame]?: (sourceAttr: any) => IFormatCropFrame[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IFormatCropFrame = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IFormatCropFrame | string)[] } = {
    create: [ 'name', 'display_type_frame_id', 'pos_x', 'pos_y', 'scale', 'aspect_ratio' ],
    save  : [ 'name', 'pos_x', 'pos_y', 'scale', 'aspect_ratio' ],
  };
}

export class FormatCropFrame extends FormatCropFrameModel implements IFormatCropFrame {

  aspect_ratio!: number;

  pos_x!: number;

  pos_y!: number;

  scale!: number;

  created_at!: DateTime;

  id!: number;

  updated_at!: DateTime;

  display_type_frame_id!: number;

  format_id!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
