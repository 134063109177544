/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PropertyNetwork.ts
 */

import { DateTime } from 'luxon';
import { Property } from 'models';
import Collection   from 'library/Collection';
import { Model }    from 'library/Model';

export interface IPropertyNetwork {
  id: number,
  name: string,
  slug: string,
  color: string,
  ooh_sales: boolean,
  mobile_sales: boolean,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  properties: Collection<Property>,
}

class PropertyNetworkModel extends Model<IPropertyNetwork> {
  _slug = 'network';

  basePath = '/v1/properties-networks';

  attributesTypes: { [attr in keyof IPropertyNetwork]?: (sourceAttr: any) => IPropertyNetwork[attr] } = {
    properties: Collection.ofType(Property).make,
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'id' as const;

  routesAttributes = {
    create: {
      name        : 'name',
      slug        : 'slug',
      color       : 'color',
      ooh_sales   : 'ooh_sales',
      mobile_sales: 'mobile_sales',
    },
    save  : {
      name        : 'name',
      slug        : 'slug',
      color       : 'color',
      ooh_sales   : 'ooh_sales',
      mobile_sales: 'mobile_sales',
    },
  };

}

export class PropertyNetwork extends PropertyNetworkModel implements IPropertyNetwork {
  color!: string;

  created_at!: DateTime;

  deleted_at!: DateTime | null;

  id!: number;

  mobile_sales!: boolean;

  name!: string;

  ooh_sales!: boolean;

  properties!: Collection<Property>;

  slug!: string;

  updated_at!: DateTime;

  constructor(attributes: Partial<IPropertyNetwork> = {}) {
    super();
    this.setAttributes(attributes);
  }
}
