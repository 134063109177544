/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - FieldSegment.ts
 */

import { DateTime }                from 'luxon';
import { Model }                   from 'library/Model';
import { ModelPersistAction }      from 'library/Model/types';
import { DatasetDatapoint, Field } from 'models';

export interface IFieldSegment {
  id: number;
  datapoint_id: number,
  field_id: number,
  name_en: string,
  name_fr: string,
  order: number,
  color: string | null,

  created_at: DateTime,
  updated_at: DateTime,

  datapoint: DatasetDatapoint,
}

class FieldSegmentModel extends Model<IFieldSegment> {
  _slug = 'field-segment';

  basePath = '/v1/fields-segments';

  attributesTypes: { [attr in keyof IFieldSegment]?: (sourceAttr: any) => IFieldSegment[attr] } = {
    datapoint   : Model.make(DatasetDatapoint),
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IFieldSegment = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IFieldSegment | string)[] } = {
    create: [ 'datapoint_id', 'field_id', 'name_en', 'name_fr', 'order', 'color' ],
    save  : [ 'name_en', 'name_fr', 'order', 'color' ],
  };
}


export class FieldSegment extends FieldSegmentModel implements IFieldSegment {
  created_at!: DateTime;

  field_id!: number;

  id!: number;

  datapoint_id!: number;

  name_en!: string;

  name_fr!: string;

  order!: number;

  updated_at!: DateTime;

  color!: string | null;

  datapoint!: DatasetDatapoint;

  constructor(attributes: Partial<IFieldSegment> = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onCreated(response: Partial<IFieldSegment>): Promise<this> {
    await super.onCreated(response);
    await (new Field({ id: this.field_id })).invalidate();
    return this;
  }

  async onSaved(response: Partial<IFieldSegment>): Promise<this> {
    await super.onSaved(response);
    await (new Field({ id: this.field_id })).invalidate();
    return this;
  }

  async onDeleted(response: Partial<IFieldSegment>): Promise<this> {
    await super.onDeleted(response);
    await (new Field({ id: this.field_id })).invalidate();
    return this;
  }
}
