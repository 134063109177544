/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryExternalResource.ts
 */

import { Model } from 'library/Model';

export interface ExternalInventoryResourceID {
  inventory_id: number,
  external_id: string,
  type: string,
  context: Record<string, any>
}

export interface IInventoryExternalResource {
  type: string,
  name: string,
  external_id: ExternalInventoryResourceID,
}

class InventoryExternalResourceModel extends Model<IInventoryExternalResource> {
  _slug = 'inventory-provider';

  basePath = '/v1/inventories/{inventory_id}/external-resources';

  attributesTypes: { [attr in keyof IInventoryExternalResource]?: (sourceAttr: any) => IInventoryExternalResource[attr] } = {};

  key: keyof IInventoryExternalResource = 'external_id';

  routesAttributes = {
    create: [],
    save  : [],
  };
}

export class InventoryExternalResource extends InventoryExternalResourceModel implements IInventoryExternalResource {
  external_id!: ExternalInventoryResourceID;

  name!: string;

  type!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
