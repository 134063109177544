/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - CampaignPlannerSave.ts
 */

import { DateTime }                                               from 'luxon';
import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRequest }                                            from 'library/Request/Request';
import { HTTPMethod }                                             from 'library/Request';
import { makeRoute }                                              from 'library/modelsUtils';

export interface ICampaignPlannerSave {
  id: number,
  uid: string,
  actor_id: number,
  name: string,
  version: string,

  contract: string | null;

  advertiser_name: string | null;
  client_name: string | null;

  plan_url: string,

  created_at: DateTime,
  updated_at: DateTime,
}

class CampaignPlannerSaveModel extends Model<ICampaignPlannerSave> {
  _slug = 'campaign-planner-save';

  basePath = '/v1/actors/{actor_id}/campaign-planner-saves';

  attributesTypes: { [attr in keyof ICampaignPlannerSave]?: (sourceAttr: any) => ICampaignPlannerSave[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof ICampaignPlannerSave = 'uid';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ICampaignPlannerSave | string)[] } = {
    create: [ 'name', 'version' ],
    save  : [ 'name', 'version' ],
  };
}

export class CampaignPlannerSave extends CampaignPlannerSaveModel implements ICampaignPlannerSave {
  actor_id!: number;

  created_at!: DateTime;

  id!: number;

  name!: string;

  uid!: string;

  version!: string;

  plan_url!: string;

  updated_at!: DateTime;

  contract!: string | null;

  advertiser_name!: string | null;

  client_name!: string | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  share(actorIds: number[]) {
    const path           = preparePathForSingleModelAction(this.basePath) + '/_share';
    const pathParameters = preparePathParameters(path, this);

    return makeRequest(makeRoute(path, HTTPMethod.post), pathParameters, { actors: actorIds });
  }
}
