/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Modal.tsx
 */

import classNames     from 'classnames';
import React          from 'react';
import { v4 as uuid } from 'uuid';
import ModalWrapper   from './ModalWrapper';

import 'components/Modal/Modal.scss';
import { useHotkeys } from 'react-hotkeys-hook';

interface ModalProps {
  title: string,
  status?: string,
  wide?: boolean,
  children: React.ReactNode,
  name?: string,
  id?: string,
  onEscape?: () => void
  style?: React.CSSProperties
  header?: React.ReactNode,
}

/**
 * Render a modal above the rest of the page
 */
const Modal = ({
                 id = '',
                 title,
                 status,
                 wide = false,
                 children,
                 name = '',
                 onEscape,
                 style,
                 header,
               }: ModalProps) => {
  const [ titleId ] = React.useState(uuid());

  useHotkeys('esc', onEscape ? onEscape : () => {});

  return (
    <ModalWrapper>
      <div id={ id }
           className={ classNames('modal', status, { wide }, name) }
           role="dialog"
           aria-labelledby={ titleId }
           style={ style }>
        <div className="modal__header">
          <h2 className={ `text-${ status }` } id={ titleId }>{ title }</h2>
          { header }
        </div>
        { children }
      </div>
    </ModalWrapper>
  );
};

export default Modal;
