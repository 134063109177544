/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Brand.ts
 */

import Collection   from 'library/Collection';
import { DateTime } from 'luxon';
import Model        from 'library/Model';
import { Property } from 'models';

export interface IBrand {
  id: number,
  name_en: string,
  name_fr: string,
  parent_id: number | null,

  child_brands: Collection<{ id: number, parent_id: number }>
  properties: Collection<Property>

  created_at: DateTime,
  updated_at: DateTime,
}

class BrandModel extends Model<IBrand> {
  _slug = 'brand';

  basePath = '/v1/brands';

  attributesTypes: { [attr in keyof IBrand]?: (sourceAttr: any) => IBrand[attr]; } = {
    'created_at'  : (d: string) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at'  : (d: string) => DateTime.fromISO(d, { zone: 'utc' }),
    'child_brands': (values: { id: number, parent_id: number }[]) => Collection.make<{
      id: number,
      parent_id: number
    }>(...values),
    'properties'  : Collection.ofType(Property).make,
  };

  key = 'id' as const;

  routesAttributes = {
    create: [
      'name_en', 'name_fr',
    ],
    save  : [
      'name_en', 'name_fr', 'parent_id',
    ],
    delete: [],
  };
}

export class Brand extends BrandModel implements IBrand {
  child_brands!: Collection<{ id: number, parent_id: number }>;

  created_at!: DateTime;

  id!: number;

  name_en!: string;

  name_fr!: string;

  parent_id!: number | null;

  properties!: Collection<Property>;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
