/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Loader.tsx
 */

import neoLoader  from 'assets/images/loading.gif';
import classNames from 'classnames';
import React      from 'react';

import './Loader.scss';

interface LoaderProps {
  small?: boolean,
  medium?: boolean,
  offset?: number
}

const Loader = ({ small = false, medium = false, offset = 0 }: LoaderProps) => {
  return (
    <div className={ classNames('loader', { small }, { medium }) }
         style={ offset ? { marginTop: `${ offset }rem` } : undefined }>
      <img src={ neoLoader } alt="Loading"/>
    </div>
  );
};

export default Loader;
