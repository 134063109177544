/*
 * Copyright 2020 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * neo-access - NetworkContainer.js
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';


interface INetworkContainer {
  id: number,
  parent_id: number | null,
  network_id: number,
  name: string,
  external_id: string | null,
}

class NetworkContainerModel extends Model<INetworkContainer> {
  _slug = 'network-container';

  basePath = '/v2/networks/{network_id}/containers';

  attributesTypes: { [attr in keyof INetworkContainer]?: (sourceAttr: any) => INetworkContainer[attr] } = {};

  key = 'id';

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [],
    save  : [],
  };
}

export class NetworkContainer extends NetworkContainerModel implements INetworkContainer {
  id!: number;

  parent_id!: number | null;

  network_id!: number;

  name!: string;

  external_id!: string | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
