/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - useAuth.ts
 */

import React                     from 'react';
import { AuthContext, IAuthCtx } from '../../providers/AuthProvider/AuthContext';

/**
 * Convenient access to the Authentication Context
 * @return {{}}
 */
const useAuth: () => IAuthCtx = () => {
  return React.useContext(AuthContext);
};

export default useAuth;
