/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PropertyTrafficSettings.ts
 */

import { DateTime }                                   from 'luxon';
import Collection                                     from 'library/Collection';
import { PropertyMonthlyTrafficDatum, TrafficSource } from 'models';
import { Model }                                      from 'library/Model';
import { ModelPersistAction }                         from 'library/Model/types';
import { TrafficFormat }                              from './enums/TrafficFormat';

interface IPropertyTrafficSettings {
  property_id: number,
  format: TrafficFormat,
  is_required: boolean,
  start_year: number,
  grace_override: DateTime | null,
  input_method: string,
  missing_value_strategy: string,
  placeholder_value: number,
  created_at: DateTime,
  updated_at: DateTime,

  source_id: number,
  source: Collection<TrafficSource>,
  venue_id: string

  monthly_data: Collection<PropertyMonthlyTrafficDatum>
  rolling_weekly_traffic: { [week: number]: number }
}

class PropertyTrafficSettingsModel extends Model<IPropertyTrafficSettings> {
  _slug = 'property-traffic-settings';

  basePath = '/v1/properties/{id}/traffic';

  attributesTypes: { [attr in keyof IPropertyTrafficSettings]?: (sourceAttr: any) => IPropertyTrafficSettings[attr] } = {
    'grace_override': (d) => d !== null ? DateTime.fromISO(d, { zone: 'utc' }) : null,
    'created_at'    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at'    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'monthly_data'  : Collection.ofType(PropertyMonthlyTrafficDatum).make,
    'source'        : Collection.ofType(TrafficSource).make,
  };

  key = 'property_id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IPropertyTrafficSettings)[] } = {
    create: [],
    save  : [
      'format',
      'is_required',
      'start_year',
      'grace_override',
      'input_method',
      'missing_value_strategy',
      'placeholder_value',
      'source_id',
      'venue_id',
    ],
  };
}

export class PropertyTrafficSettings extends PropertyTrafficSettingsModel implements IPropertyTrafficSettings {
  created_at!: DateTime;

  format!: TrafficFormat;

  monthly_data!: Collection<PropertyMonthlyTrafficDatum>;

  grace_override!: DateTime | null;

  input_method!: string;

  is_required!: boolean;

  missing_value_strategy!: string;

  placeholder_value!: number;

  property_id!: number;

  source_id!: number;

  start_year!: number;

  updated_at!: DateTime;

  venue_id!: string;

  source!: Collection<TrafficSource>;

  rolling_weekly_traffic!: { [week: number]: number };

  constructor(attributes: Partial<IPropertyTrafficSettings> = {}) {
    super();
    this.setAttributes(attributes);
  }

  /**
   * Get the traffic for this property for the specified year and month.
   * Omiting the month will return the traffic for the entire year.
   * @param {int} year
   * @param {int|null} [month=null] Month of the year with january as 0
   * @return {int|null} Will return null if the specified year + month doesn't has traffic data
   */
  get(year: number, month = null) {
    if (month === null) {
      const values = this.monthly_data.filterBy('year', '===', year);

      // If no values, return null to denote no data
      if (values.length === 0) {
        return null;
      }

      return values.map(t => t?.traffic ?? t?.temporary ?? 0).sum();
    }

    const input = this.monthly_data.find(t => t.year === year && t.month === (month - 1));

    return input?.traffic ?? input?.temporary ?? null;
  }

  getFillableYears() {
    const startYear = this.start_year;

    if (!startYear) {
      return [];
    }

    const range = Array.apply(null, Array((DateTime.now().year - startYear) + 1))
                       .map((_, offset) => DateTime.now().year - offset);

    // remove 2020
    return range.filter(y => y !== 2020);
  }
}
