/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ModulesProvider.tsx
 */

import React   from 'react';
import Request from 'library/Request';
import routes  from 'library/routes';

export const ModulesContext = React.createContext<{
  modules: Record<string, boolean>,
  isEnabled: (module: string) => boolean
}>({
  modules  : {},
  isEnabled: () => false,
});

interface ModulesProviderProps {
  children: React.ReactNode;
}

/**
 * The ModulesProvider fetches the list of enabled modules on Connect, and makes it available through a Context and convenient methods.
 * @return {JSX.Element}
 * @constructor
 */
const ModulesProvider = ({ children }: ModulesProviderProps) => {
  const [ modules, setModules ] = React.useState<Record<string, boolean>>({
    core: true,
  });

  React.useEffect(() => {
    Request.make(routes.modules.status)
           .then(({ data }) => setModules(data));
  }, []);

  const ctx = React.useMemo(() => ({
    modules,
    isEnabled: (module: string) => modules[module] ?? false,
  }), [ modules ]);

  return (
    <ModulesContext.Provider value={ ctx }>
      { children }
    </ModulesContext.Provider>
  );
};

export default ModulesProvider;
