/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ExternalContract.ts
 */

import { Model }               from 'library/Model';
import { ModelPersistAction }  from 'library/Model/types';
import { makeRoute }           from 'library/modelsUtils';
import { HTTPMethod }          from 'library/Request';
import { makeRequest }         from 'library/Request/Request';
import { Contract, IContract } from './Contract';

interface IExternalContract {
  name: string,
  inventory_id: number,
  contract_id: string,
  state: string,
  salesperson_id: string,
  salesperson_name: string,
  client_id: string | null,
  client_name: string | null,
  advertiser_id: string | null,
  advertiser_name: string | null,
}

class ExternalContractModel extends Model<IExternalContract> {
  _slug = 'contract-contract';

  basePath = '/v1/external-contracts';

  attributesTypes: { [attr in keyof IExternalContract]?: (sourceAttr: any) => IExternalContract[attr] } = {};

  key: keyof IExternalContract = 'name';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IExternalContract | string)[] } = {
    create: [],
    save  : [],
  };

}

export class ExternalContract extends ExternalContractModel implements IExternalContract {
  advertiser_id!: string | null;

  advertiser_name!: string | null;

  client_id!: string | null;

  client_name!: string | null;

  contract_id!: string;

  inventory_id!: number;

  name!: string;

  salesperson_id!: string;

  salesperson_name!: string;

  state!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }


  static import(inventoryId: number, contractId: string) {
    const path  = (new ExternalContract()).basePath + '/_import';
    const route = makeRoute(path, HTTPMethod.post);

    return makeRequest<IContract>(route, {}, {
      inventory_id: inventoryId,
      contract_id : contractId,
    }).then((response) => {
      const contract = new Contract(response.data);
      contract.invalidateAll();
      return contract;
    });
  }
}
