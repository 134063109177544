/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - factories.ts
 */

import { ModelAttributes, ModelConstructor } from './types';
import Collection                            from '../Collection';
import { Model }                             from './Model';

export function makeModel<Attributes extends ModelAttributes>(
  modelType: ModelConstructor<Attributes>,
  attributes: Attributes,
  relations: string[] = [],
): InstanceType<ModelConstructor<Attributes>> {
  const model = new modelType();
  model.setAttributes(attributes);
  model._loadedRelations = relations;

  return model;
}

export function makeModelCollection<Attributes extends ModelAttributes>(modelType: ModelConstructor<Attributes>) {
  return (attributes: Attributes[], relations: string[] = []): Collection<InstanceType<ModelConstructor<Attributes>>> => {
    return Collection.from(attributes).map(attr => {
      const model            = new modelType(attr) as Model<Attributes> & Attributes;
      model._loadedRelations = relations;
      return model;
    });
  };
}
