/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ImpersonatingRibbon.tsx
 */

import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useAuth                   from 'library/hooks/useAuth';
import { FlatButton }            from 'components/Button';

const ImpersonatingRibbon = () => {
  const { t }                       = useTranslation('auth');
  const { user, stopImpersonating } = useAuth();

  return (
    <section id="impersonating-ribbon">
      <p>
        <Trans i18nKey="auth:impersonating-as" values={ { name: user.name } }>
          You are viewing connect as <strong> </strong>
        </Trans>
      </p>
      <FlatButton primary status="highlight" onClick={ stopImpersonating }>
        { t('stop-impersonating') }
      </FlatButton>
    </section>
  );
};

export default ImpersonatingRibbon;
