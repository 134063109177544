/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - routes.ts
 */

import { HTTPMethod, Route } from './Request';

export const modules = {
  status: { uri: 'v1/modules/_status', method: HTTPMethod.get, auth: true },
};

export const accessTokens = {
  update: { uri: 'v1/access-tokens/{id}', method: HTTPMethod.put, auth: true },
};

export const actors = {
  index             : { uri: 'v1/actors', method: HTTPMethod.get, auth: true },
  store             : { uri: 'v1/actors', method: HTTPMethod.post, auth: true },
  show              : { uri: 'v1/actors/{id}', method: HTTPMethod.get, auth: true },
  update            : { uri: 'v1/actors/{id}', method: HTTPMethod.put, auth: true },
  destroy           : { uri: 'v1/actors/{id}', method: HTTPMethod.delete, auth: true, invalidate: [ 'v1/actors' ] },
  resendWelcomeEmail: { uri: 'v1/actors/{id}/re-send-signup-email', method: HTTPMethod.post, auth: true },
  newToken          : { uri: 'v1/auth/token-refresh', method: HTTPMethod.get, auth: true },
  impersonate       : { uri: 'v1/actors/{id}/impersonate', method: HTTPMethod.get, auth: true },
  security          : { uri: 'v1/actors/{id}/security', method: HTTPMethod.get, auth: true },
  twoFa             : {
    approve: { uri: 'v1/actors/{id}/two-fa/validate', method: HTTPMethod.post, auth: true },
    recycle: { uri: 'v1/actors/{id}/two-fa/recycle', method: HTTPMethod.post, auth: true },
  },
  accesses          : {
    sync: { uri: 'v1/actors/{id}/accesses', method: HTTPMethod.post, auth: true },
  },
  campaigns         : {
    get: { uri: 'v1/actors/{id}/campaigns', method: HTTPMethod.get, auth: true },
  },
  capabilities      : {
    index: { uri: 'v1/actors/{id}/capabilities', method: HTTPMethod.get, auth: true },
    sync : { uri: 'v1/actors/{id}/capabilities', method: HTTPMethod.put, auth: true },
  },
  locations         : {
    index: { uri: 'v1/actors/{id}/locations', method: HTTPMethod.get, auth: true },
    sync : { uri: 'v1/actors/{id}/locations', method: HTTPMethod.put, auth: true },
  },
  roles             : {
    index: { uri: 'v1/actors/{id}/roles', method: HTTPMethod.get, auth: true },
    sync : { uri: 'v1/actors/{id}/roles', method: HTTPMethod.put, auth: true },
  },
  shares            : {
    index  : { uri: 'v1/actors/{id}/shares', method: HTTPMethod.get, auth: true },
    store  : { uri: 'v1/actors/{id}/shares', method: HTTPMethod.post, auth: true },
    destroy: { uri: 'v1/actors/{id}/shares', method: HTTPMethod.delete, auth: true },
  },
  logo              : {
    store  : { uri: 'v1/actors/{id}/logo', method: HTTPMethod.post, auth: true },
    destroy: { uri: 'v1/actors/{id}/logo', method: HTTPMethod.delete, auth: true },
  },
  phone             : {
    store  : { uri: 'v1/actors/{id}/phone', method: HTTPMethod.put, auth: true },
    destroy: { uri: 'v1/actors/{id}/phone', method: HTTPMethod.delete, auth: true },
  },
  tags              : {
    sync: { uri: 'v1/actors/{id}/tags', method: HTTPMethod.put, auth: true },
  },
};

export const auth = {
  login       : { uri: 'v1/auth/login', method: HTTPMethod.post, auth: false },
  twofa       : { uri: 'v1/auth/two-fa-validation', method: HTTPMethod.post, auth: true },
  recycleTwoFa: { uri: 'v1/auth/recycle-two-fa/{id}', method: HTTPMethod.put, auth: true },
  recovery    : {
    validate : { uri: 'v1/auth/recovery/check-token', method: HTTPMethod.post, auth: false },
    makeToken: { uri: 'v1/auth/recovery/recover-password', method: HTTPMethod.post, auth: false },
    reset    : { uri: 'v1/auth/recovery/reset-password', method: HTTPMethod.post, auth: false },
  },
  tos         : {
    show  : { uri: 'v1/auth/terms-of-service', method: HTTPMethod.get, auth: true },
    accept: { uri: 'v1/auth/terms-of-service', method: HTTPMethod.post, auth: true },
  },
  signinToken : {
    check      : { uri: 'v1/auth/welcome', method: HTTPMethod.get, auth: false },
    setPassword: { uri: 'v1/auth/welcome', method: HTTPMethod.post, auth: false },
  },
};

export const brands = {
  batchStore  : { uri: 'v1/brands/_batch', method: HTTPMethod.post, auth: true },
  syncChildren: { uri: 'v1/brands/{id}/_sync_children', method: HTTPMethod.post, auth: true },
  merge       : { uri: 'v1/brands/{id}/_merge', method: HTTPMethod.post, auth: true },
};

export const broadcasterConnections = {
  index  : { uri: 'v1/broadcaster', method: HTTPMethod.get, auth: true },
  store  : { uri: 'v1/broadcasters', method: HTTPMethod.post, auth: true },
  show   : { uri: 'v1/broadcasters/{id}', method: HTTPMethod.get, auth: true },
  update : { uri: 'v1/broadcasters/{id}', method: HTTPMethod.post, auth: true },
  destroy: { uri: 'v1/broadcasters/{id}', method: HTTPMethod.delete, auth: true },
};


export const bursts = {
  store: { uri: 'v1/bursts', method: HTTPMethod.post, auth: true },
};

export const campaignPlanner = {
  dataChunk1  : {
    uri   : 'v1/campaign-planner/_chunk_1',
    method: HTTPMethod.get,
    auth  : true,
    cache : false,
    // debug : true,
  } as Route,
  dataChunk2  : { uri: 'v1/campaign-planner/_chunk_2', method: HTTPMethod.get, auth: true, cache: false } as Route,
  dataChunk3  : { uri: 'v1/campaign-planner/_chunk_3', method: HTTPMethod.get, auth: true, cache: false } as Route,
  dataChunk4  : { uri: 'v1/campaign-planner/_chunk_4', method: HTTPMethod.get, auth: true, cache: false } as Route,
  trafficChunk: { uri: 'v1/campaign-planner/_chunk_traffic', method: HTTPMethod.get, auth: true, cache: false } as Route,
  static      : {
    save           : { uri: 'v1/campaign-planner/{save}/_save', method: HTTPMethod.get, auth: false, cache: false } as Route,
    dataChunk1     : { uri: 'v1/campaign-planner/{save}/_chunk_1', method: HTTPMethod.get, auth: false, cache: false } as Route,
    dataChunk2     : { uri: 'v1/campaign-planner/{save}/_chunk_2', method: HTTPMethod.get, auth: false, cache: false } as Route,
    dataChunk3     : { uri: 'v1/campaign-planner/{save}/_chunk_3', method: HTTPMethod.get, auth: false, cache: false } as Route,
    dataChunk4     : { uri: 'v1/campaign-planner/{save}/_chunk_4', method: HTTPMethod.get, auth: false, cache: false } as Route,
    trafficChunk   : {
      uri   : 'v1/campaign-planner/{save}/_chunk_traffic',
      method: HTTPMethod.get,
      auth  : false,
      cache : false,
    } as Route,
    demographicData: {
      uri   : 'v1/campaign-planner/{save}/_demographic_values',
      method: HTTPMethod.get,
      auth  : false,
      cache : false,
    } as Route,
  },
};

export const campaigns = {
  index          : { uri: 'v1/campaigns', method: HTTPMethod.get, auth: true },
  store          : { uri: 'v1/campaigns', method: HTTPMethod.post, auth: true },
  show           : { uri: 'v1/campaigns/{id}', method: HTTPMethod.get, auth: true },
  update         : { uri: 'v1/campaigns/{id}', method: HTTPMethod.put, auth: true },
  destroy        : { uri: 'v1/campaigns/{id}', method: HTTPMethod.delete, auth: true },
  insert         : {
    uri   : 'v1/campaigns/{id}/insert',
    method: HTTPMethod.post,
    auth  : true,
  },
  locations      : {
    sync  : { uri: 'v1/campaigns/{id}/locations', method: HTTPMethod.put, auth: true },
    remove: { uri: 'v1/campaigns/{id}/locations/{location}', method: HTTPMethod.delete, auth: true },
  },
  reorder        : { uri: 'v1/campaigns/{id}/reorder', method: HTTPMethod.post, auth: true },
  schedule       : { uri: 'v1/campaigns/{id}/schedules', method: HTTPMethod.post, auth: true },
  setScreensState: { uri: 'v1/campaigns/{id}/screens_state', method: HTTPMethod.put, auth: true },
};

export const capabilities = {
  index : { uri: 'v1/capabilities', method: HTTPMethod.get, auth: true },
  update: { uri: 'v1/capabilities/{id}', method: HTTPMethod.put, auth: true },
};

export const containers = {
  index: { uri: 'v1/containers', method: HTTPMethod.get, auth: true },
};

export const contents = {
  store  : { uri: 'v1/contents', method: HTTPMethod.post, auth: true },
  show   : { uri: 'v1/contents/{id}', method: HTTPMethod.get, auth: true },
  update : { uri: 'v1/contents/{id}', method: HTTPMethod.put, auth: true },
  swap   : { uri: 'v1/contents/{id}/swap', method: HTTPMethod.put, auth: true },
  destroy: { uri: 'v1/contents/{id}', method: HTTPMethod.delete, auth: true },
};

export const creatives = {
  store  : { uri: 'v1/creatives', method: HTTPMethod.post, auth: true },
  destroy: { uri: 'v1/creatives/{id}', method: HTTPMethod.delete, auth: true },
};

export const documents = {
  pop          : { uri: 'documents/pop', method: HTTPMethod.post, auth: true, responseType: 'arraybuffer' } as Route,
  traffic      : { uri: 'documents/traffic', method: HTTPMethod.post, auth: true, responseType: 'arraybuffer' },
  plannerExport: { uri: 'documents/planner-export', method: HTTPMethod.post, auth: true, responseType: 'arraybuffer' },
};

export const dynamics = {
  news   : {
    backgrounds: {
      store  : { uri: 'v1/dynamics/news/backgrounds', method: HTTPMethod.post, auth: true },
      destroy: { uri: 'v1/dynamics/news/backgrounds/{id}', method: HTTPMethod.delete, auth: true },
    },
  },
  weather: {
    backgrounds: {
      store  : { uri: 'v1/dynamics/weather/backgrounds', method: HTTPMethod.post, auth: true },
      destroy: { uri: 'v1/dynamics/weather/backgrounds/{id}', method: HTTPMethod.delete, auth: true },
    },
  },
};

export const displayTypes = {
  index: { uri: 'v1/display-types', method: HTTPMethod.get, auth: true },
};

export const formats = {
  store       : { uri: 'v1/formats', method: HTTPMethod.post, auth: true },
  index       : { uri: 'v1/formats', method: HTTPMethod.get, auth: true },
  show        : { uri: 'v1/formats/{id}', method: HTTPMethod.get, auth: true },
  update      : { uri: 'v1/formats/{id}', method: HTTPMethod.put, auth: true },
  displayTypes: {
    sync: { uri: 'v1/formats/{id}/display-types', method: HTTPMethod.put, auth: true },
  },
  frames      : {
    store  : { uri: 'v1/formats/{id}/frames', method: HTTPMethod.post, auth: true },
    update : { uri: 'v1/formats/{id}/frames/{frame}', method: HTTPMethod.put, auth: true },
    destroy: { uri: 'v1/formats/{id}/frames/{frame}', method: HTTPMethod.delete, auth: true },
  },
};

export const headlines = {
  index   : { uri: 'v1/headlines', method: HTTPMethod.get, auth: true },
  current : { uri: 'v1/headlines/_current', method: HTTPMethod.get, auth: true },
  show    : { uri: 'v1/headlines/{headline}', method: HTTPMethod.get, auth: true },
  store   : { uri: 'v1/headlines', method: HTTPMethod.post, auth: true },
  update  : { uri: 'v1/headlines/{headline}', method: HTTPMethod.put, auth: true },
  destroy : { uri: 'v1/headlines/{headline}', method: HTTPMethod.delete, auth: true },
  messages: {
    update: { uri: 'v1/headlines/{headline}/messages/{message}', method: HTTPMethod.put, auth: true },
  },
};

export const inventory = {
  index: { uri: 'v1/inventory', method: HTTPMethod.get, auth: true },
};

export const libraries = {
  index   : { uri: 'v1/libraries', method: HTTPMethod.get, auth: true },
  search  : { uri: 'v1/libraries/_query', method: HTTPMethod.get, auth: true },
  store   : { uri: 'v1/libraries', method: HTTPMethod.post, auth: true },
  show    : { uri: 'v1/libraries/{id}', method: HTTPMethod.get, auth: true },
  update  : { uri: 'v1/libraries/{id}', method: HTTPMethod.put, auth: true },
  destroy : { uri: 'v1/libraries/{id}', method: HTTPMethod.delete, auth: true },
  contents: { uri: 'v1/libraries/{id}/contents', method: HTTPMethod.get, auth: true },
  shares  : {
    index  : { uri: 'v1/libraries/{id}/shares', method: HTTPMethod.get, auth: true },
    store  : {
      uri       : 'v1/libraries/{id}/shares',
      method    : HTTPMethod.post,
      auth      : true,
      invalidate: [ 'v1/libraries/{id}', 'v1/libraries/{id}/shares' ],
    },
    destroy: {
      uri       : 'v1/libraries/{id}/shares',
      method    : HTTPMethod.delete,
      auth      : true,
      invalidate: [ 'v1/libraries/{id}', 'v1/libraries/{id}/shares' ],
    },
  },
};

export const markets = {
  store: { uri: 'v1/countries/{country}/provinces/{province}/markets', method: HTTPMethod.post, auth: true },
};

export const networks = {
  index         : { uri: 'v1/networks/', method: HTTPMethod.get, auth: true },
  store         : { uri: 'v1/networks/', method: HTTPMethod.post, auth: true },
  show          : { uri: 'v1/networks/{id}', method: HTTPMethod.get, auth: true },
  update        : { uri: 'v1/networks/{id}', method: HTTPMethod.put, auth: true },
  destroy       : { uri: 'v1/networks/{id}', method: HTTPMethod.delete, auth: true },
  refresh       : { uri: 'v1/networks/_refresh', method: HTTPMethod.post, auth: true },
  displayTypes  : { uri: 'v1/networks/{id}/display-types', method: HTTPMethod.get, auth: true },
  dumpProperties: { uri: 'v1/networks/{id}/_dump_properties', method: HTTPMethod.get, auth: true, responseType: 'arraybuffer' },
};

export const locations = {
  index       : { uri: 'v2/locations', method: HTTPMethod.get, auth: true },
  search      : { uri: 'v2/locations/_search', method: HTTPMethod.get, auth: true },
  show        : { uri: 'v2/locations/{id}', method: HTTPMethod.get, auth: true },
  update      : { uri: 'v2/locations/{id}', method: HTTPMethod.put, auth: true },
  forceRefresh: { uri: 'v2/locations/{id}/_force_refresh', method: HTTPMethod.put, auth: true },
};

export const parameters = {
  show  : { uri: 'v1/params/{_slug}', method: HTTPMethod.get, auth: true },
  update: { uri: 'v1/params/{_slug}', method: HTTPMethod.post, auth: true },
};

export const properties = {
  store       : { uri: 'v1/properties', method: HTTPMethod.post, auth: true },
  show        : { uri: 'v1/properties/{id}', method: HTTPMethod.get, auth: true },
  update      : { uri: 'v1/properties/{id}', method: HTTPMethod.put, auth: true },
  destroy     : { uri: 'v1/properties/{id}', method: HTTPMethod.delete, auth: true },
  export      : { uri: 'v1/properties/{id}/_dump', method: HTTPMethod.get, auth: true, responseType: 'arraybuffer' } as Route,
  traffic     : {
    get  : { uri: 'v1/properties/{id}/traffic', method: HTTPMethod.get, auth: true },
    store: { uri: 'v1/properties/{id}/traffic', method: HTTPMethod.post, auth: true },
  },
  statistics  : {
    get: { uri: 'v1/properties/{id}/statistics', method: HTTPMethod.get, auth: true },
  },
  address     : {
    update: { uri: 'v1/properties/{id}/address', method: HTTPMethod.put, auth: true },
  },
  pictures    : {
    store: { uri: 'v1/properties/{id}/pictures', method: HTTPMethod.post, auth: true },
  },
  fieldsValues: {
    remove: { uri: 'v1/properties/{id}/fields/{field}', method: HTTPMethod.delete, auth: true },
  },
  tenants     : {
    index : { uri: 'v1/properties/{id}/tenants', method: HTTPMethod.get, auth: true },
    sync  : { uri: 'v1/properties/{id}/tenants', method: HTTPMethod.post, auth: true },
    import: { uri: 'v1/properties/{id}/tenants', method: HTTPMethod.put, auth: true },
    remove: { uri: 'v1/properties/{id}/tenants/{brand}', method: HTTPMethod.delete, auth: true },
  },
};

export const products = {
  locations: {
    sync: { uri: 'v1/products/{id}/locations', method: HTTPMethod.put, auth: true },
  },
};

export const reports = {
  refresh: { uri: 'v1/reports/{id}/refresh', method: HTTPMethod.get, auth: true },
};

export const reviewTemplates = {
  index  : { uri: 'v1/review-templates', method: HTTPMethod.get, auth: true },
  store  : { uri: 'v1/review-templates', method: HTTPMethod.post, auth: true },
  update : { uri: 'v1/review-templates/{id}', method: HTTPMethod.put, auth: true },
  destroy: { uri: 'v1/review-templates/{id}', method: HTTPMethod.delete, auth: true },
};

export const roles = {
  index       : { uri: 'v1/roles', method: HTTPMethod.get, auth: true },
  store       : { uri: 'v1/roles/', method: HTTPMethod.post, auth: true },
  show        : { uri: 'v1/roles/{id}', method: HTTPMethod.get, auth: true },
  update      : { uri: 'v1/roles/{id}', method: HTTPMethod.put, auth: true },
  destroy     : { uri: 'v1/roles/{id}', method: HTTPMethod.delete, auth: true },
  actors      : {
    index  : { uri: 'v1/roles/{id}/actors', method: HTTPMethod.get, auth: true },
    store  : { uri: 'v1/roles/{id}/actors', method: HTTPMethod.post, auth: true },
    destroy: { uri: 'v1/roles/{id}/actors', method: HTTPMethod.delete, auth: true },
  },
  capabilities: {
    index  : { uri: 'v1/roles/{id}/capabilities', method: HTTPMethod.get, auth: true },
    store  : { uri: 'v1/roles/{id}/capabilities', method: HTTPMethod.post, auth: true },
    update : { uri: 'v1/roles/{id}/capabilities', method: HTTPMethod.put, auth: true },
    destroy: { uri: 'v1/roles/{id}/capabilities', method: HTTPMethod.delete, auth: true },
  },
};

export const schedules = {
  update : { uri: 'v1/schedules/{id}', method: HTTPMethod.put, auth: true },
  destroy: { uri: 'v1/schedules/{id}', method: HTTPMethod.delete, auth: true },
  review : { uri: 'v1/schedules/{id}/reviews', method: HTTPMethod.post, auth: true },
  pending: { uri: 'v1/schedules/pending', method: HTTPMethod.get, auth: true },
};

export const screenshots = {
  destroy: { uri: 'v1/screenshots/{id}', method: HTTPMethod.delete, auth: true },
};

export const stats = {
  index: { uri: 'v1/stats', method: HTTPMethod.get, auth: true },
};

export const traffic = {
  export: { uri: 'v1/traffic/_export', method: HTTPMethod.post, auth: true, responseType: 'arraybuffer' },
};

export const misc = {
  googlePlacesSearch: { uri: 'v1/google/places', method: HTTPMethod.get, auth: true },
};

export const odoo = {
  properties: {
    store  : {
      uri   : 'v1/contract/properties',
      method: HTTPMethod.post,
      auth  : true,
    },
    destroy: {
      uri   : 'v1/contract/properties/{id}',
      method: HTTPMethod.delete,
      auth  : true,
    },
  },
  contract  : {
    send: {
      uri   : 'v1/contract/contracts/{id}',
      method: HTTPMethod.post,
      auth  : true,
    },
  },
};

export const demographicValues = {
  store: { uri: 'v1/properties/{property}/demographic_values', method: HTTPMethod.post, auth: true },
};


const routes = {
  accessTokens,
  actors,
  auth,
  brands,
  broadcasterConnections,
  bursts,
  campaignPlanner,
  campaigns,
  capabilities,
  containers,
  contents,
  creatives,
  documents,
  dynamics,
  displayTypes,
  formats,
  headlines,
  inventory,
  libraries,
  locations,
  markets,
  misc,
  modules,
  networks,
  odoo,
  parameters,
  products,
  properties,
  reports,
  reviewTemplates,
  roles,
  schedules,
  screenshots,
  stats,
  traffic,
  demographicValues,
};

export default routes;
