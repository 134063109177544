/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - TranslationProvider.tsx
 */

import { Settings as DateTimeSettings }    from 'luxon';
import moment                              from 'moment';
import 'providers/TranslationProvider/i18n';
import React                               from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useSearchParams }                 from 'react-router-dom';

/**
 * The Translation provider takes care of initializing the localization system (i18n) and load locales
 */
const TranslationProvider = ({ children }: { children: React.ReactNode }) => {
    const { i18n }   = useTranslation();
    const [ params ] = useSearchParams();

    React.useEffect(() => {
        // On first load, if a locale url argument is provided, use it to set the URL
        if (params.has('locale')) {
            i18n.changeLanguage(params.get('locale')!);
        }
        // We keep this empty as this should only run once
        // eslint-disable-next-line
    }, []);

    // On locale change, update locale settings for Luxon(DateTime) and Moment
    React.useEffect(() => {
        DateTimeSettings.defaultLocale  = `${ i18n.language }-CA`;
        DateTimeSettings.throwOnInvalid = true;

        moment.locale(i18n.language);
        moment.updateLocale(i18n.language, {
            week: {
                dow: 1,
            },
        });
    }, [ i18n.language ]);

    return (
        <I18nextProvider i18n={ i18n }>
            { children }
        </I18nextProvider>
    );
};

export default TranslationProvider;
