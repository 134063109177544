/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ActorSecurity.ts
 */

import { DateTime }                                      from 'luxon';
import { Model }                                         from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor } from 'library/Model/types';

export interface IActorSecurity {
    actor_id: number,
    signup_token: {
        actor_id: number,
        token: string,
        created_at: DateTime,
    } | null,
    two_factor_token: {
        actor_id: number,
        token: string,
        validated: boolean,
        created_at: DateTime,
        validated_at: DateTime,
    } | null
}

class ActorSecurityModel extends Model<IActorSecurity> {
    _slug = 'actor-security';

    basePath = '/v1/actors/{id}/security';

    attributesTypes: { [attr in keyof IActorSecurity]?: (sourceAttr: any) => IActorSecurity[attr] } = {
        signup_token    : d => ({ ...d, created_at: DateTime.fromISO(d.created_at) }),
        two_factor_token: d => ({ ...d, created_at: DateTime.fromISO(d.created_at), validated_at: d.validated ? DateTime.fromISO(d.validated_at) : null }),
    };

    key = 'actor_id' as const;

    routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IActorSecurity> } = {
        create: [],
        save  : [],
    };
}

export class ActorSecurity extends ActorSecurityModel implements IActorSecurity {
    actor_id!: number;

    signup_token!: { actor_id: number; token: string; created_at: DateTime } | null;

    two_factor_token!: { actor_id: number; token: string; validated: boolean; created_at: DateTime; validated_at: DateTime } | null;

    constructor(attributes = {}) {
        super();
        this.setAttributes(attributes);
    }
}
