/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - DatapointCategory.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IDatapointCategory {
  id: number,
  dataset_version_id: number,
  label_en: string,
  label_fr: string,
}

class DatapointCategoryModel extends Model<IDatapointCategory> {
  _slug = 'datapoint-category';

  basePath = '/v1/demographics/datapoints-categories';

  attributesTypes: { [attr in keyof IDatapointCategory]?: (sourceAttr: any) => IDatapointCategory[attr] } = {};

  key: keyof IDatapointCategory = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IDatapointCategory | string)[] } = {
    create: [],
    save  : [],
  };
}

export class DatapointCategory extends DatapointCategoryModel implements IDatapointCategory {
  id!: number;

  dataset_version_id!: number;

  label_en!: string;

  label_fr!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
