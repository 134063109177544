/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - LoopConfiguration.ts
 */

import { Model }              from 'library/Model';
import { DateTime }           from 'luxon';
import { ModelPersistAction } from 'library/Model/types';

export interface ILoopConfiguration {
  id: number,
  name: string,
  loop_length_ms: number,
  spot_length_ms: number,
  reserved_spots: number,
  start_date: DateTime,
  end_date: DateTime,
  max_spots_count: number,
  free_spots_count: number,

  created_at: DateTime,
  updated_at: DateTime,
}

class LoopConfigurationModel extends Model<ILoopConfiguration> {
  _slug: string = 'loop-configuration';

  basePath: string = '/v2/loop-configurations';

  key: string = 'id';

  attributesTypes: { [attr in keyof ILoopConfiguration]?: (sourceAttr: any) => ILoopConfiguration[attr] } = {
    start_date: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    end_date  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [ 'name', 'loop_length_ms', 'spot_length_ms', 'reserved_spots', 'start_date', 'end_date' ],
    save  : [ 'name', 'loop_length_ms', 'spot_length_ms', 'reserved_spots', 'start_date', 'end_date' ],
  };
}

export class LoopConfiguration extends LoopConfigurationModel implements ILoopConfiguration {
  created_at!: DateTime;

  end_date!: DateTime;

  free_spots_count!: number;

  id!: number;

  loop_length_ms!: number;

  max_spots_count!: number;

  name!: string;

  reserved_spots!: number;

  spot_length_ms!: number;

  start_date!: DateTime;

  updated_at!: DateTime;

  constructor(attributes?: Partial<ILoopConfiguration>) {
    super();

    this.setAttributes(attributes ?? {});
  }

  getPeriodString() {
    let start, end;

    if (this.start_date.day === 1) {
      start = this.start_date.monthLong;
    } else {
      start = this.start_date.toFormat('MMMM dd');
    }
    start = start[0].toLocaleUpperCase() + start.substring(1);


    if (this.end_date.day === this.end_date.endOf('month').day) {
      end = this.end_date.monthLong;
    } else {
      end = this.end_date.toFormat('MMMM dd');
    }
    end = end[0].toLocaleUpperCase() + end.substring(1);

    return start + ' - ' + end;
  }
}
