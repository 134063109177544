/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - DatasetVersion.ts
 */

import { Model }                            from 'library/Model';
import { ModelPersistAction }               from 'library/Model/types';
import { DateTime }                         from 'luxon';
import { DatasetStructure }                 from './enums/DatasetStructure';
import { Dataset }                          from './index';
import { IExternalInventoryRepresentation } from './ExternalInventoryRepresentation';

export interface IDatasetVersion {
  id: number,
  dataset_id: number,
  name: string,
  provider: string,
  structure: DatasetStructure,
  order: number,
  is_primary: boolean,
  is_archived: boolean,

  created_at: DateTime,
  updated_at: DateTime,

  dataset: Dataset,
}

class DatasetVersionModel extends Model<IDatasetVersion> {
  _slug = 'dataset-version';

  basePath = '/v1/demographics/datasets-versions';

  attributesTypes: { [attr in keyof IDatasetVersion]?: (sourceAttr: any) => IDatasetVersion[attr] } = {};

  key: keyof IDatasetVersion = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IDatasetVersion | string)[] } = {
    create: [],
    save  : [ 'is_primary', 'is_archived', 'order' ],
  };
}

export class DatasetVersion extends DatasetVersionModel implements IDatasetVersion {
  id!: number;

  name!: string;

  dataset_id!: number;

  provider!: string;

  structure!: DatasetStructure;

  order!: number;

  is_primary!: boolean;

  is_archived!: boolean;

  created_at!: DateTime;

  updated_at!: DateTime;

  dataset!: Dataset;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onSaved(response: Partial<IExternalInventoryRepresentation>): Promise<this> {
    await super.onSaved(response);
    await (new Dataset({ id: this.dataset_id })).invalidate();
    await (new Dataset({ id: this.dataset_id })).invalidateAll();
    return this;
  }
}
