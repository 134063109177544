/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PropertyType.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { InventoryResource }  from './InventoryResource';

export interface IPropertyType {
  id: number,
  name_en: string,
  name_fr: string,

  inventory_resource: InventoryResource,

  created_at: DateTime,
  created_by: number,
  updated_at: DateTime,
  updated_by: number,
  deleted_at: DateTime | null,
  deleted_by: number | null,
}

class PropertyTypeModel extends Model<IPropertyType> {
  _slug = 'property-type';

  basePath = '/v1/property-types';

  attributesTypes: { [attr in keyof IPropertyType]?: (sourceAttr: any) => IPropertyType[attr] } = {
    created_at        : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at        : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at        : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    inventory_resource: Model.make(InventoryResource),
  };

  key: keyof IPropertyType = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IPropertyType | string)[] } = {
    create: [ 'name_en', 'name_fr' ],
    save  : [ 'name_en', 'name_fr' ],
  };
}


export class PropertyType extends PropertyTypeModel implements IPropertyType {
  created_at!: DateTime;

  created_by!: number;

  deleted_at!: DateTime | null;

  deleted_by!: number | null;

  id!: number;

  inventory_resource!: InventoryResource;

  name_en!: string;

  name_fr!: string;

  updated_at!: DateTime;

  updated_by!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
