/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryProvider.ts
 */

import { DateTime }                                               from 'luxon';
import { Model }                                                  from 'library/Model';
import InventoryType                                              from './enums/InventoryType';
import { InventoryResourceType }                                  from './enums/InventoryResourceType';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { makeRequest }                                            from 'library/Request/Request';
import ProductType                                                from './enums/ProductType';

interface InventoryProviderResourceCapabilities {
  create: boolean,
  associate: boolean,
  associateManually: boolean,
  remove: boolean,

  events: boolean,
  representations: boolean,
  settings: boolean,
}

interface InventoryProviderCapabilities {
  supportedProductTypes: ProductType[],
  products: InventoryProviderResourceCapabilities | null,
  properties: InventoryProviderResourceCapabilities | null,
  categories: InventoryProviderResourceCapabilities | null
}

export interface InventoryProviderSettings {
  api_url: string | null,
  api_key: string | null,
  api_username: string | null,

  // Odoo
  database: string | null,

  // Hivestack
  networks: { id: string, name: string }[] | null

  // Reach
  auth_url: string | null,
  publisher_id: string | null,
  client_id: string | null,

  // Place Exchange
  usd_cad_rate: number | null,
}

export interface IInventoryProvider {
  id: number,
  uuid: string,
  provider: string,

  name: string,
  is_active: boolean,
  allow_pull: boolean,
  auto_pull: boolean,
  allow_push: boolean,
  auto_push: boolean,
  settings: InventoryProviderSettings,
  last_pull_at: DateTime | null,
  last_push_at: DateTime | null,

  created_at: DateTime,
  created_by: number,
  updated_at: DateTime,
  updated_by: number,
  deleted_at: DateTime | null,
  deleted_by: number | null,
}

class InventoryProviderModel extends Model<IInventoryProvider> {
  _slug = 'inventory-provider';

  basePath = '/v1/inventories';

  attributesTypes: { [attr in keyof IInventoryProvider]?: (sourceAttr: any) => IInventoryProvider[attr] } = {
    'last_push_at': (d) => DateTime.fromISO(d),
    'last_pull_at': (d) => DateTime.fromISO(d),
    'created_at'  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at'  : (d) => DateTime.fromISO(d),
    'deleted_at'  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IInventoryProvider = 'id';

  routesAttributes = {
    create: {
      name        : 'name',
      provider    : 'provider',
      is_active   : 'is_active',
      auto_pull   : 'auto_pull',
      auto_push   : 'auto_push',
      api_url     : 'settings.api_url',
      api_key     : 'settings.api_key',
      api_username: 'settings.api_username',
      database    : 'settings.database',
      auth_url    : 'settings.auth_url',
      publisher_id: 'settings.publisher_id',
      client_id   : 'settings.client_id',
      venue_types : 'settings.venue_types',
      usd_cad_rate: 'settings.usd_cad_rate',
    },
    save  : {
      name        : 'name',
      is_active   : 'is_active',
      allow_pull  : 'allow_pull',
      auto_pull   : 'auto_pull',
      allow_push  : 'allow_push',
      auto_push   : 'auto_push',
      api_url     : 'settings.api_url',
      api_key     : 'settings.api_key',
      api_username: 'settings.api_username',
      database    : 'settings.database',
      networks    : 'settings.networks',
      mediatypes  : 'settings.mediatypes',
      auth_url    : 'settings.auth_url',
      publisher_id: 'settings.publisher_id',
      client_id   : 'settings.client_id',
      venue_types : 'settings.venue_types',
      usd_cad_rate: 'settings.usd_cad_rate',
    },
  };
}

export class InventoryProvider extends InventoryProviderModel implements IInventoryProvider {
  created_at!: DateTime;

  created_by!: number;

  deleted_at!: DateTime | null;

  deleted_by!: number | null;

  id!: number;

  is_active!: boolean;

  allow_pull!: boolean;

  auto_pull!: boolean;

  allow_push!: boolean;

  auto_push!: boolean;

  name!: string;

  provider!: string;

  settings!: InventoryProviderSettings;

  updated_at!: DateTime;

  updated_by!: number;

  last_pull_at!: DateTime | null;

  last_push_at!: DateTime | null;

  uuid!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  listAllCapabilities(): InventoryProviderCapabilities {
    switch (this.provider) {
      case InventoryType.Dummy:
        return {
          supportedProductTypes: [ ProductType.digital, ProductType.specialty, ProductType.static ],
          products             : {
            create           : false,
            associate        : false,
            associateManually: true,
            remove           : false,
            events           : false,
            representations  : true,
            settings         : false,
          }, properties        : {
            create           : false,
            associate        : false,
            associateManually: true,
            remove           : false,
            events           : false,
            representations  : true,
            settings         : false,
          },
          categories           : {
            create           : false,
            associate        : false,
            associateManually: true,
            remove           : false,
            events           : false,
            representations  : true,
            settings         : false,
          },
        };
      case InventoryType.Odoo:
        return {
          supportedProductTypes: [ ProductType.digital, ProductType.specialty, ProductType.static ],
          products             : {
            create           : false,
            associate        : true,
            associateManually: true,
            remove           : false,
            events           : true,
            representations  : true,
            settings         : true,
          }, properties        : {
            create           : false,
            associate        : true,
            associateManually: true,
            remove           : false,
            events           : false,
            representations  : true,
            settings         : true,
          },
          categories           : {
            create           : false,
            associate        : false,
            associateManually: false,
            remove           : false,
            events           : false,
            representations  : true,
            settings         : false,
          },
        };
      case InventoryType.Hivestack:
        return {
          supportedProductTypes: [ ProductType.digital ],
          products             : {
            create           : true,
            associate        : false,
            associateManually: false,
            remove           : true,
            events           : true,
            representations  : true,
            settings         : true,
          }, properties        : {
            create           : false,
            associate        : true,
            associateManually: true,
            remove           : false,
            events           : false,
            representations  : true,
            settings         : true,
          }, categories        : null,
        };
      case InventoryType.PlaceExchange:
        return {
          supportedProductTypes: [ ProductType.digital ],
          products             : {
            create           : true,
            associate        : false,
            associateManually: false,
            remove           : true,
            events           : true,
            representations  : true,
            settings         : true,
          }, properties        : {
            create           : false,
            associate        : false,
            associateManually: false,
            remove           : false,
            events           : false,
            representations  : false,
            settings         : true,
          }, categories        : null,
        };
      case InventoryType.Reach:
        return {
          supportedProductTypes: [ ProductType.digital ],
          products             : {
            create           : true,
            associate        : false,
            associateManually: false,
            remove           : true,
            events           : true,
            representations  : true,
            settings         : true,
          }, properties        : {
            create           : false,
            associate        : false,
            associateManually: false,
            remove           : false,
            events           : false,
            representations  : false,
            settings         : true,
          },
          categories           : null,
        };
      case InventoryType.Vistar:
        return {
          supportedProductTypes: [ ProductType.digital ],
          products             : {
            create           : true,
            associate        : false,
            associateManually: false,
            remove           : true,
            events           : true,
            representations  : true,
            settings         : true,
          }, properties        : {
            create           : false,
            associate        : false,
            associateManually: false,
            remove           : false,
            events           : false,
            representations  : false,
            settings         : true,
          },
          categories           : null,
        };
      default:
        return {
          supportedProductTypes: [],
          products             : null,
          properties           : null,
          categories           : null,
        };
    }
  }

  listCapabilities(resource: InventoryResourceType) {
    const allCapabilities = this.listAllCapabilities();

    switch (resource) {
      case InventoryResourceType.Product:
        return allCapabilities.products;
      case InventoryResourceType.Property:
        return allCapabilities.properties;
      case InventoryResourceType.ProductCategory:
        return allCapabilities.categories;
    }
  }

  sync(action: 'push' | 'pull') {
    const path   = preparePathForSingleModelAction(this.basePath, '/_sync');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest(route, params, {
      action: action,
    });
  }

  validateConfiguration() {
    const path   = preparePathForSingleModelAction(this.basePath, '/_validate_configuration');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest<{ status: true | string }>(route, params)
      .then(({ data }) => data);
  }
}
