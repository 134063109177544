/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - FieldsCategory.ts
 */

import { DateTime }                                               from 'luxon';
import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { makeRequest }                                            from 'library/Request/Request';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { Field }                                                  from 'models';
import Collection                                                 from 'library/Collection';

export interface IFieldsCategory {
  id: number,
  dataset_version_id: number,
  name_en: string,
  name_fr: string,

  fields: Collection<Field>

  created_at: DateTime,
  updated_at: DateTime,
}

class FieldsCategoryModel extends Model<IFieldsCategory> {
  _slug = 'field-category';

  basePath = '/v1/fields-categories';

  attributesTypes: { [attr in keyof IFieldsCategory]?: (sourceAttr: any) => IFieldsCategory[attr] } = {
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    fields      : Collection.ofType(Field).make,
  };

  key: keyof IFieldsCategory = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IFieldsCategory | string)[] } = {
    create: [ 'dataset_version_id', 'name_en', 'name_fr' ],
    save  : [ 'name_en', 'name_fr' ],
  };

}

export class FieldsCategory extends FieldsCategoryModel implements IFieldsCategory {
  created_at!: DateTime;

  id!: number;

  dataset_version_id!: number;

  fields!: Collection<Field>;

  name_en!: string;

  name_fr!: string;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  reorder(orderedIds: number[]) {
    const categoryPath = preparePathForSingleModelAction(this.basePath);
    const path         = categoryPath + '/_reorder';
    const params       = preparePathParameters(path, this);

    return makeRequest(makeRoute(path, HTTPMethod.put, [ (new Field()).basePath ]), params, {
      fields: orderedIds,
    });
  }
}
