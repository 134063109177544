/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Advertiser.ts
 */

import { DateTime }     from 'luxon';
import { Model }        from 'library/Model';
import Collection       from '../library/Collection';
import { BroadcastTag } from './BroadcastTag';

export interface IAdvertiser {
  id: number,
  odoo_id: number,
  name: string,
  created_at: DateTime,
  updated_at: DateTime,

  external_representations_count: number
  broadcast_tags: Collection<BroadcastTag>
}

class AdvertiserModel extends Model<IAdvertiser> {
  _slug = 'advertiser';

  basePath = '/v1/advertisers';

  key: keyof IAdvertiser = 'id' as const;

  routesAttributes = {
    create: {},
    save  : {
      name   : 'name',
      odoo_id: 'odoo_id',
      tags   : (attr: IAdvertiser) => attr.broadcast_tags.pluck('id'),
    },
  };

  attributesTypes: { [attr in keyof IAdvertiser]?: (sourceAttr: any) => IAdvertiser[attr] } = {
    broadcast_tags: Collection.ofType(BroadcastTag).make,
    created_at    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };
}

export class Advertiser extends AdvertiserModel implements IAdvertiser {
  created_at!: DateTime;

  deleted_at!: DateTime | null;

  odoo_id!: number;

  id!: number;

  name!: string;

  updated_at!: DateTime;

  external_representations_count!: number;

  broadcast_tags!: Collection<BroadcastTag>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
