/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Setup.tsx
 */

import React                       from 'react';
import { ErrorBoundary }           from './components/ErrorBoundary';
import Router                      from './Router';
import { Workbox }                 from 'workbox-window';
import OutdatedServiceWorkerRibbon from './components/misc/OutdatedServiceWorkerRibbon';

const Setup = () => {
  const [ isAppOutdated, setIsAppOutdated ] = React.useState(false);

  const wb = React.useMemo<Workbox | null>(() => {
    // @ts-ignore
    if (process.env.NODE_ENV !== 'production' || !('serviceWorker' in navigator) || process.env.NODE_ENV === 'local') {
      return null;
    }

    return new Workbox('/service-worker.js');
  }, []);


  // Register our service worker
  React.useEffect(() => {
    if (!wb) {
      return;
    }

    window.addEventListener('load', () => {
      // If the service worker is waiting to activate, we notify the user an update is available
      wb.addEventListener('waiting', () => {
        setIsAppOutdated(true);

        wb.addEventListener('controlling', () => {
          console.warn('WebWorker updated, reloading...');
          window.location.reload();
        });
      });

      wb.register();
    });
  }, [ wb ]);

  const handlePerformUpdate = React.useCallback(() => {
    wb?.messageSkipWaiting();
  }, [ wb ]);

  return (
    <ErrorBoundary>
      { isAppOutdated && (<OutdatedServiceWorkerRibbon onPerformUpdate={ handlePerformUpdate }/>) }
      <Router/>
    </ErrorBoundary>
  );
};

export default Setup;
