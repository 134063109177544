/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Frame.ts
 */

import { DateTime }     from 'luxon';
import { Model }        from 'library/Model';
import Collection       from 'library/Collection';
import { BroadcastTag } from './BroadcastTag';
import { Layout }       from './Layout';

export interface IFrame {
  id: number,
  layout_id: number,
  name: string,
  width: number,
  height: number,

  broadcast_tags: Collection<BroadcastTag>

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,
}

class FrameModel extends Model<IFrame> {
  _slug = 'frame';

  basePath = '/v2/layouts/{layout_id}/frames';

  attributesTypes: { [attr in keyof IFrame]?: (sourceAttr: any) => IFrame[attr] } = {
    created_at    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    broadcast_tags: Collection.ofType(BroadcastTag).make,
  };

  key: keyof IFrame = 'id';

  routesAttributes = {
    create: {
      layout_id: 'layout_id',
      name     : 'name',
      width    : 'width',
      height   : 'height',
      tags     : (attr: IFrame) => attr.broadcast_tags.pluck('id'),
    },
    save  : {
      name  : 'name',
      width : 'width',
      height: 'height',
      tags  : (attr: IFrame) => attr.broadcast_tags.pluck('id'),
    },
  };
}

export class Frame extends FrameModel implements IFrame {
  broadcast_tags!: Collection<BroadcastTag>;

  created_at!: DateTime;

  deleted_at!: DateTime;

  height!: number;

  id!: number;

  layout_id!: number;

  name!: string;

  updated_at!: DateTime;

  width!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getSizeString() {
    return `${ this.width } x ${ this.height }px`;
  }

  async onDeleted(response: Partial<IFrame>): Promise<this> {
    await (new Layout({ id: this.layout_id })).invalidate();
    return super.onDeleted(response);
  }
}
