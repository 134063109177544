/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Gate.tsx
 */

import React   from 'react';
import useAuth from 'library/hooks/useAuth';

interface GateProps {
    capabilities?: string | string[];
    condition?: boolean | (() => boolean);
    children: React.ReactNode | (() => React.ReactNode);
    reverse?: boolean;
    loggedIn?: boolean,
}

const Gate = ({
                  capabilities,
                  condition,
                  children,
                  reverse = false,
                  loggedIn = false,
              }: GateProps) => {
    const { userId, user } = useAuth();

    const allowed = React.useMemo(() => {
            let allowed = true;

            if (loggedIn) {
                allowed = allowed && userId !== 0;
            }

            if (capabilities) {
                allowed = allowed && user?.hasCapability(capabilities);
            }

            if (condition !== undefined) {
                allowed = allowed && (typeof condition === 'function' ? condition() : condition);
            }

            if (reverse) {
                allowed = !allowed;
            }

            return allowed;
        },
        [ capabilities, condition, loggedIn, reverse, user, userId ],
    );

    if (!allowed) {
        return null;
    }

    if (typeof children === 'function') {
        return children() as React.ReactElement;
    }
    return children as React.ReactElement;
};

export default Gate;
