/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Parameter.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IParam {
  slug: string,
  format: string,
  capability: string,
  value: any,
  created_at: DateTime,
  updated_at: DateTime,
}

class ParamModel extends Model<IParam> {
  _slug = 'parameter';

  basePath = '/v1/parameters';

  key: string = 'slug' as const;

  attributesTypes: { [attr in keyof IParam]?: (sourceAttr: any) => IParam[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: (keyof IParam | string)[] } = {
    create: [],
    save  : [ 'value' ],
  };

}

export class Parameter extends ParamModel implements IParam {
  created_at!: DateTime;

  format!: string;

  updated_at!: DateTime;

  value!: any;

  capability!: string;

  slug!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  /*
   |--------------------------------------------------------------------------
   | Actions
   |--------------------------------------------------------------------------
   */

  /**
   *
   * @param {string} slug
   * @param {any} value
   */
  static set(slug: string, value: any) {
    const p = new Parameter({ slug, value });
    return p.save();
  }
}
