/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - API.ts
 */

import { ModelAPIAllResponse, ModelAttributes, ModelConstructor } from './types';
import {
  BatchRequest,
  BatchResponse,
  CancelTokenHolder,
  HTTPMethod,
  QueryParameters,
  UploadListener,
}                                                                 from 'library/Request/Types';
import * as utils                                                 from './utils';
import { preparePathParameters }                                  from './utils';
import {
  makeRequest,
}                                                                 from 'library/Request/Request';
import {
  makeRoute,
}                                                                 from 'library/modelsUtils';
import { Request }                                                from 'library/Request';
import Collection                                                 from 'library/Collection';
import { Model }                                                  from './Model';
import { makeModel, makeModelCollection }                         from './factories';
import { queryClient }                                            from './QueryClient';
import { parseContentRangeHeader }                                from '../Request/utils';

interface APIGetAllOptions<Attributes extends ModelAttributes, T extends Model<Attributes>> {
  model: ModelConstructor<Attributes, T>,
  params?: QueryParameters,

  signal?: CancelTokenHolder | AbortSignal,
  auth?: boolean,
}

async function all<Attributes extends ModelAttributes, T extends Model<Attributes>>(
  {
    model: modelType,
    params = {},
    signal,
    auth: requireAuth = true,
  }: APIGetAllOptions<Attributes, T>): Promise<ModelAPIAllResponse<Attributes>> {
  const model = new modelType({});

  const pathParameters = utils.preparePathParameters(model.basePath, { getKey: () => '' }, params);

  if (signal && !(signal instanceof AbortSignal)) {
    signal.token = Request.makeCancelToken();
  }

  return makeRequest<Attributes[]>(
    makeRoute(model.basePath, HTTPMethod.get, [], requireAuth),
    pathParameters,
    params,
    {},
    undefined,
    signal instanceof AbortSignal ? signal : signal ? signal.token!.token : undefined,
  ).then(({ data, headers: responseHeaders }) => {
    const models  = makeModelCollection(modelType)(data, params['with'] ?? []) as Collection<Model<Attributes> & Attributes>;
    const headers = {
      ...responseHeaders,
      ...(('content-range' in responseHeaders) ? {
        'content-range': parseContentRangeHeader(responseHeaders['content-range']),
      } : {}),
    };
    return { models, headers };
  })
   .then(results => {
     return results;
   })
   .catch(e => {
     return {
       models : utils.handleBadRequest(e),
       headers: {},
     };
   });
}

async function get<Attributes extends ModelAttributes, T extends Model<Attributes>>(
  modelType: ModelConstructor<Attributes>,
  key: any,
  queryParameters: QueryParameters = {},
  signal?: CancelTokenHolder | AbortSignal,
): Promise<Model<Attributes> & Attributes> {
  const model = new modelType({});

  // First, check if the provided key is properly defined
  if (key === null || key === undefined || Number.isNaN(key)) {
    return Promise.reject({ message: '[Model:get] Cannot retrieve a model without a proper key' });
  }

  // Second, we need to build the correct path for the request
  const path           = utils.preparePathForSingleModelAction(model.basePath);
  const pathParameters = preparePathParameters(path, { getKey: () => key }, queryParameters);

  if (signal && !(signal instanceof AbortSignal)) {
    signal.token = Request.makeCancelToken();
  }

  // Finally, query the model
  // @ts-ignore
  return makeRequest<Attributes>(
    makeRoute(path, HTTPMethod.get),
    pathParameters,
    queryParameters,
    {},
    undefined,
    signal instanceof AbortSignal ? signal : signal ? signal.token!.token : undefined,
  ).then(({ data }) => {
    const relations = queryParameters['with'] ?? [];
    if (Array.isArray(data)) {
      return makeModelCollection(modelType)(data, relations) as Collection<T>;
    }

    return makeModel(modelType, data, relations);
  }).catch(utils.handleBadRequest);
}

async function byId<Attributes extends ModelAttributes, T extends Model<Attributes>>(
  modelType: ModelConstructor<Attributes>,
  keys: any[],
  queryParameters: QueryParameters = {},
  signal?: CancelTokenHolder | AbortSignal,
): Promise<Collection<T> | null> {
  const model          = new modelType({});
  const pathParameters = utils.preparePathParameters(model.basePath, model, queryParameters);

  if (signal && !(signal instanceof AbortSignal)) {
    signal.token = Request.makeCancelToken();
  }

  // First we want to check if any of the requested models is already in our cache
  const missingKeys = [];
  const models      = new Collection<T>();

  for (const key of keys) {
    const cachedModel = queryClient.getQueryData<T>([ model._slug, key, queryParameters ]);
    if (cachedModel === undefined) {
      missingKeys.push(key);
    } else {
      models.push(cachedModel);
    }
  }

  // If some models are missing, fetch them
  if (missingKeys.length > 0) {
    const response: Collection<T> | null = await makeRequest<Attributes[]>(
      makeRoute(model.basePath + '/_by_id', HTTPMethod.get),
      pathParameters,
      { ids: missingKeys, ...queryParameters },
      {},
      undefined,
      signal instanceof AbortSignal ? signal : signal ? signal.token!.token : undefined,
    ).then(({ data }) => {
      return makeModelCollection(modelType)(data, queryParameters['with'] ?? []) as Collection<T>;
    }).catch(utils.handleBadRequest);

    // If the response is not a collection, it is an error, we return that
    // noinspection SuspiciousTypeOfGuard Even though typecript says otherwise, we may receive something else than a Collection here.
    if (!(response instanceof Collection)) {
      return response;
    }

    response.forEach(model => queryClient.setQueryData([ model._slug, model.getKey(), queryParameters ], model as any));

    // Merge the received models with the cached ones
    models.push(...response);
  }

  return models;
}

export interface APICreateOptions<Attributes extends ModelAttributes, T extends Model<Attributes>> {
  model: T,
  params?: QueryParameters,
  body?: any,
  bodyExtension?: Record<string, any>,
  signal?: CancelTokenHolder | AbortSignal,
  uploadListener?: UploadListener
}

async function create<Attributes extends ModelAttributes, T extends Model<Attributes>>(
  {
    model,
    params = {},
    body,
    bodyExtension = {},
    signal,
    uploadListener,
  }: APICreateOptions<Attributes, T>,
): Promise<T> {
  const path           = model.basePath;
  const pathParameters = utils.preparePathParameters(path, model, params);
  const payload        = body ?? utils.preparePersistActionPayload('create', model, bodyExtension);

  if (signal && !(signal instanceof AbortSignal)) {
    signal.token = Request.makeCancelToken();
  }

  return makeRequest<Attributes>(
    makeRoute(path, HTTPMethod.post, model.invalidateRoutes),
    pathParameters,
    payload,
    {},
    uploadListener,
    signal instanceof AbortSignal ? signal : signal ? signal.token!.token : undefined,
  ).then(({ data }) => model.onCreated(data))
   .catch((response) => utils.handleBadRequest(response) ?? model);
}

async function save<Attributes extends ModelAttributes, T extends Model<Attributes>>(
  model: T,
  queryParameters: QueryParameters   = {},
  signal?: CancelTokenHolder | AbortSignal,
  bodyExtension: Record<string, any> = {},
): Promise<T> {
  const path                         = utils.preparePathForSingleModelAction(model.basePath);
  const pathParameters               = utils.preparePathParameters(path, model, queryParameters);
  const payload: Record<string, any> = utils.preparePersistActionPayload('save', model, bodyExtension);

  if (signal && !(signal instanceof AbortSignal)) {
    signal.token = Request.makeCancelToken();
  }

  return makeRequest<Attributes>(
    makeRoute(path, HTTPMethod.put, model.invalidateRoutes),
    pathParameters,
    payload,
    { with: model._loadedRelations, ...queryParameters },
    undefined,
    signal instanceof AbortSignal ? signal : signal ? signal.token!.token : undefined,
  ).then(({ data }) => model.onSaved(data))
   .catch((response) => utils.handleBadRequest(response) ?? model);
}

async function destroy<Attributes extends ModelAttributes, T extends Model<any>>(
  model: T,
  queryParameters: QueryParameters = {},
  signal?: CancelTokenHolder | AbortSignal,
): Promise<T> {
  const path           = utils.preparePathForSingleModelAction(model.basePath);
  const pathParameters = utils.preparePathParameters(path, model, queryParameters);

  if (signal && !(signal instanceof AbortSignal)) {
    signal.token = Request.makeCancelToken();
  }

  return makeRequest<Attributes>(
    makeRoute(path, HTTPMethod.delete, model.invalidateRoutes),
    pathParameters,
    queryParameters,
    {},
    undefined,
    signal instanceof AbortSignal ? signal : signal ? signal.token!.token : undefined,
  ).then(({ data }) => model.onDeleted(data))
   .catch((response) => utils.handleBadRequest(response) ?? model);
}

async function batch<Response>(
  requests: BatchRequest[],
  signal?: CancelTokenHolder | AbortSignal,
): Promise<BatchResponse<Response>[]> {
  const path = `/v1/batch`;

  if (signal && !(signal instanceof AbortSignal)) {
    signal.token = Request.makeCancelToken();
  }

  // @ts-ignore
  return makeRequest<Response>(
    makeRoute(path, HTTPMethod.post),
    null,
    { requests },
    {},
    undefined,
    signal instanceof AbortSignal ? signal : signal ? signal.token!.token : undefined,
  )
    .then(({ data }) => data)
    .catch((response) => utils.handleBadRequest(response) ?? []);
}

const API = {
  all,
  get,
  byId,
  create,
  save,
  destroy,
  batch,
};

export default API;
