/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - MobileProductBracket.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { DateTime }           from 'luxon';
import { MobileProduct }      from './MobileProduct';

export interface IMobileProductBracket {
  id: number,
  mobile_product_id: number,

  budget_min: number,
  budget_max: number | null,
  impressions_min: number | null,
  cpm: number,

  created_at: DateTime,
  updated_at: DateTime,
}

class MobileProductBracketModel extends Model<IMobileProductBracket> {
  _slug = 'mobile-product-bracket';

  basePath = '/v1/mobile-products/{mobile_product_id}/brackets';

  attributesTypes: { [attr in keyof IMobileProductBracket]?: (sourceAttr: any) => IMobileProductBracket[attr] } = {
    created_at: d => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: d => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IMobileProductBracket = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IMobileProductBracket | string)[] } = {
    create: [ 'budget_min', 'budget_max', 'impressions_min', 'cpm' ],
    save  : [ 'budget_min', 'budget_max', 'impressions_min', 'cpm' ],
  };
}

export class MobileProductBracket extends MobileProductBracketModel implements IMobileProductBracket {
  id!: number;

  budget_max!: number | null;

  budget_min!: number;

  cpm!: number;

  created_at!: DateTime;

  impressions_min!: number;

  mobile_product_id!: number;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onCreated(response: Partial<IMobileProductBracket>): Promise<this> {
    await (new MobileProduct({ id: this.mobile_product_id })).invalidate();
    return super.onCreated(response);
  }

  async onSaved(response: Partial<IMobileProductBracket>): Promise<this> {
    await (new MobileProduct({ id: this.mobile_product_id })).invalidate();
    return super.onSaved(response);
  }

  async onDeleted(response: Partial<IMobileProductBracket>): Promise<this> {
    await (new MobileProduct({ id: this.mobile_product_id })).invalidate();
    return super.onDeleted(response);
  }
}
