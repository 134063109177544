/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - usePropBoundState.ts
 */

import React, { Dispatch, SetStateAction } from 'react';

/**
 * This hook has a behaviour similar to useState, with the difference that it will get updated if the given default value changes
 * @param value
 */
export function usePropBoundState<S>(value: S): [ S, Dispatch<SetStateAction<S>> ] {
  const [ state, setState ] = React.useState(value);

  React.useEffect(() => {
    setState(value);
  }, [ value ]);

  return [ state, setState ];
}
