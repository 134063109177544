/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - GeographicReportTemplate.ts
 */

import { Model }                             from 'library/Model';
import { ModelPersistAction }                from 'library/Model/types';
import { DateTime }                          from 'luxon';
import Collection                            from 'library/Collection';
import { GeographicReportType }              from './enums/GeographicReportType';
import GeographicReportTemplateConfiguration from './interfaces/GeographicReportTemplateConfiguration';
import { GeographicReport }                  from './index';

export interface GeographicReportTemplateMetadata {
  executionTimeMs: number | null,
  error: Record<string, any> | null,
}

interface IGeographicReportTemplate {
  id: number,
  name: string,
  description: string,
  type: GeographicReportType,
  configuration: GeographicReportTemplateConfiguration[]

  created_at: DateTime,
  created_by: number | null,
  updated_at: DateTime,
  updated_by: number | null,
  deleted_at: DateTime | null,
  deleted_by: number | null,

  report: Collection<GeographicReport>,
}

class GeographicReportTemplateModel extends Model<IGeographicReportTemplate> {
  _slug = 'geographic-report-template';

  basePath = '/v1/demographics/geographic-reports-templates';

  attributesTypes: { [attr in keyof IGeographicReportTemplate]?: (sourceAttr: any) => IGeographicReportTemplate[attr] } = {};

  key: keyof IGeographicReportTemplate = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IGeographicReportTemplate | string)[] } = {
    create: [ 'name', 'description', 'type', 'configuration' ],
    save  : [ 'name', 'description', 'configuration' ],
  };
}

export class GeographicReportTemplate extends GeographicReportTemplateModel implements IGeographicReportTemplate {
  id!: number;

  name!: string;

  description!: string;

  type!: GeographicReportType;

  configuration!: GeographicReportTemplateConfiguration[];

  created_at!: DateTime<true>;

  created_by!: number | null;

  updated_at!: DateTime<true>;

  updated_by!: number | null;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  report!: Collection<GeographicReport>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
