/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ActorTrafficStatistics.ts
 */

import { Actor }                                         from './';
import { Model }                                         from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor } from 'library/Model/types';
import Collection                                        from 'library/Collection';

export interface MonthlyTrafficDatum {
  year: number,

  /**
   * Month of the year, zero indexed
   */
  month: number,

  /**
   * Traffic as provided by the property
   */
  traffic: number,

  /**
   * Traffic provided internally
   */
  temporary: number,

  /**
   * Traffic value to use depending on what's available. Property_BAK traffic (`traffic`) first, internal value (`temporary`) otherwise.
   */
  final_traffic: number,
}

export interface IActorTrafficStatistics {
  actor_id: Actor['id'],

  actor: Actor,

  traffic: Collection<MonthlyTrafficDatum>
}

class ActorTrafficStatisticsModel extends Model<IActorTrafficStatistics> {
  _slug = 'actor-traffic-statistics';

  attributesTypes: { [attr in keyof IActorTrafficStatistics]?: (sourceAttr: any) => IActorTrafficStatistics[attr] } = {
    actor  : Model.make(Actor),
    traffic: Collection.ofType(Object).make as (args: any[]) => Collection<MonthlyTrafficDatum>,
  };

  basePath: string = `/v1/actors/{actor}/traffic-statistics`;

  key = 'actor_id';

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IActorTrafficStatistics> } = {
    create: [],
    save  : [],
  };
}

export class ActorTrafficStatistics extends ActorTrafficStatisticsModel implements IActorTrafficStatistics {
  actor!: Actor;

  actor_id!: Actor['id'];

  traffic!: Collection<MonthlyTrafficDatum>;

  constructor(attributes?: Partial<IActorTrafficStatistics>) {
    super();
    this.setAttributes(attributes);
  }
}
