/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - MaintenanceProvider.tsx
 */

import React              from 'react';
import { useTranslation } from 'react-i18next';
import { makeRoute }      from 'library/modelsUtils';
import { HTTPMethod }     from 'library/Request';
import { makeRequest }    from 'library/Request/Request';

import './MaintenanceProvider.scss';
import { useQuery }       from '@tanstack/react-query';
import Auth               from 'library/Auth';

const MaintenanceProvider = ({ children }: { children: any }) => {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery({
    queryKey       : [ 'status' ],
    queryFn        : () => {
      const route   = makeRoute('/_status', HTTPMethod.get, null, false);
      route.headers = { 'Authorization': 'Bearer ' + Auth.getAuthCookie() };
      return makeRequest<{ maintenanceEnabled: boolean, maintenanceBypass: boolean }>(route).then(response => response.data);
    },
    retry          : false,
    refetchInterval: false,
  });

  if (isLoading) {
    return null;
  }

  if (isError || (data?.maintenanceEnabled && !data?.maintenanceBypass)) {
    return (
      <section className="maintenance-page">
        <div className="maintenance-page__image"/>
        <h1 className="maintenance-page__title">{ t('common:maintenance.title') }</h1>
        <h3 className="maintenance-page__caption">{ t('common:maintenance.caption') }</h3>
      </section>
    );
  }

  if (data?.maintenanceEnabled) {
    return (
      <>
        <div className="maintenance-overlay">MAINTENANCE MODE</div>
        { children }
      </>
    );
  }

  return children;
};

export default MaintenanceProvider;
