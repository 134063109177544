/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ImpressionsModel.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IImpressionsModel {
  id: number,
  parent_id: number,
  start_month: number,
  end_month: number,
  formula: string,
  variables: Record<string, any>,

  created_at: DateTime,
  updated_at: DateTime,
}

abstract class ImpressionsModelModel extends Model<IImpressionsModel> {
  _slug = 'impressions-model';

  attributesTypes: { [attr in keyof IImpressionsModel]?: (sourceAttr: any) => IImpressionsModel[attr] } = {
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IImpressionsModel = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IImpressionsModel | string)[] } = {
    create: [ 'start_month', 'end_month', 'formula', 'variables' ],
    save  : [ 'start_month', 'end_month', 'formula', 'variables' ],
  };
}

export abstract class ImpressionsModel extends ImpressionsModelModel implements IImpressionsModel {
  created_at!: DateTime;

  end_month!: number;

  formula!: string;

  id!: number;

  parent_id!: number;

  start_month!: number;

  updated_at!: DateTime;

  variables!: Record<string, any>;

  constructor(attributes: Partial<IImpressionsModel> = {}) {
    super();
    this.setAttributes(attributes);
  }
}

export class ProductImpressionsModel extends ImpressionsModel {
  basePath = '/v1/products/{parent_id}/impressions-models';
}

export class ProductCategoryImpressionsModel extends ImpressionsModel {
  basePath = '/v1/product-categories/{parent_id}/impressions-models';
}
