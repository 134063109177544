/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Header.tsx
 */

import Gate                      from 'components/Gate';
import useAuth                   from 'library/hooks/useAuth';
import Breadcrumb                from 'providers/BreadcrumbProvider/Breadcrumb';
import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink }               from 'react-router-dom';

import 'scenes/Shell/Header/Header.scss';
import TutorialModal             from 'scenes/Shell/Header/TutorialModal';
import MainLogo                  from '../MainLogo';

const Header = () => {
  const { i18n, t }           = useTranslation('header');
  const { user }              = useAuth();
  const [ locale, setLocale ] = React.useState(i18n.language);
  const [ modal, setModal ]   = React.useState('');

  const switchLng = () => {
    const newLocale = locale === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLocale).then(() => setLocale(newLocale));
  };

  const welcomeTransKey = React.useMemo(() => {
    const time = (new Date()).getHours();

    if (time < 12) {
      return 'header:greetings.morning';
    } else if (time < 18) {
      return 'header:greetings.afternoon';
    } else {
      return 'header:greetings.evening';
    }
  }, []);

  return (
    <header>
      <MainLogo/>
      <div className="header-content">
        <Breadcrumb/>
        <div className="language-switcher link" onClick={ switchLng }>
          { i18n.language === 'en' ? 'Fr' : 'En' }
        </div>
        <Gate condition={ () => user?.email?.endsWith('neo-ooh.com') || user?.email?.endsWith('neo-ooh.info') }>
          <a className="link"
             href="https://youtrack.neo-ooh.info/youtrack/newIssue?project=CONNECT&description=**What's%20the%20problem%20%3F**%0A*%5C--%20What%20happened%20%3F*%0A%0A%0A%0A**What's%20the%20expected%20result%20%3F**%0A*%5C--%20Explain%20what%20should%20have%20happened%20instead*%0A%0A%0A%0A**Where%20is%20this%20happening%20%3F**%0A*%5C--%20Share%20a%20URL%2C%20screenshot%2C%20etc.*%0A%0A%0A%0A**How%20can%20this%20be%20reproduced%20%3F**%0A*%5C--%20Explain%20the%20steps%20needed%20to%20reproduce%20this%20problem*%0A%0A%0A%0A**What%20else%20should%20we%20now%20%3F**%0A%0A*%5C--%20Anything%20else%20to%20take%20into%20account%20%3F*"
             target="_blank"
             rel="noreferrer">
            { 'Tickets' }
          </a>
        </Gate>
        <div className="tutorial link" onClick={ () => setModal('tutorial') }>
          { t('tutorial') }
        </div>
        <NavLink to="/me" className="user-profile link">
          <Trans i18nKey={ welcomeTransKey } values={ { name: user?.name } }>
            Greeting <span className="user-name">Jane Doe</span>
          </Trans>
        </NavLink>
      </div>
      { modal === 'tutorial' && <TutorialModal onClose={ () => setModal('') }/> }
    </header>
  );
};

export default Header;
