/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Attachment.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IAttachment {
  id: number,
  locale: string,
  name: string,
  filename: string,
  url: string,
  created_at: DateTime,
  updated_at: DateTime,
}

class AttachmentModel extends Model<Attachment> {
  _slug = 'attachment';

  basePath = '';

  attributesTypes: { [attr in keyof Attachment]?: (sourceAttr: any) => Attachment[attr] } = {
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof Attachment = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof Attachment)[] } = {
    create: [],
    save  : [ 'locale', 'name' ],
  };
}

export class Attachment extends AttachmentModel implements IAttachment {
  created_at!: DateTime;

  filename!: string;

  id!: number;

  locale!: string;

  name!: string;

  updated_at!: DateTime;

  url!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}

export class ProductAttachment extends Attachment {
  _slug = 'product-attachment';

  basePath = '/v1/products/{parent_id}/attachments';
}

export class ProductCategoryAttachment extends Attachment {
  _slug = 'product-category-attachment';

  basePath = '/v1/product-categories/{parent_id}/attachments';
}
