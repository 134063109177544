/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - GeographicReportValue.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

interface IGeographicReportValue {
  report_id: number,
  area_id: number,
  geography_weight: number,
  metadata: Record<string, any>,
}

class GeographicReportValueModel extends Model<IGeographicReportValue> {
  _slug = 'geographic-report-value';

  basePath = '/v1/demographics/geographic-reports-values';

  attributesTypes: { [attr in keyof IGeographicReportValue]?: (sourceAttr: any) => IGeographicReportValue[attr] } = {};

  key: keyof IGeographicReportValue = 'report_id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IGeographicReportValue | string)[] } = {
    create: [],
    save  : [],
  };
}

export class GeographicReportValue extends GeographicReportValueModel implements IGeographicReportValue {
  report_id!: number;

  area_id!: number;

  geography_weight!: number;

  metadata!: Record<string, any>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
