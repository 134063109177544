/*
 * Copyright 2020 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * neo-access - HeadlinesProvider.jsx
 */

import Collection                    from 'library/Collection';
import Request                       from 'library/Request';
import routes                        from 'library/routes';
import { Headline as HeadlineModel } from 'models';
import Headline                      from 'providers/HeadlinesProvider/Headline';
import React                         from 'react';

import './HeadlinesProvider.scss';

const HeadlinesProvider = ({ children }) => {
  const [ headlines, setHeadlines ] = React.useState(new Collection());

  React.useEffect(() => {
    Request.make(routes.headlines.current)
           .then(response => setHeadlines(Collection.ofType(HeadlineModel).make(response.data)));
  }, []);

  const handleCloseHeadline = React.useCallback((headlineId) => {
    headlines.findBy('id', headlineId).hidden = true;
    setHeadlines(new Collection(...headlines));
  }, [ headlines ]);

  return (
    <>
      { headlines.length > 0 && (
        <section id="headlines-wrapper">
          { headlines.map(headline => (
            <Headline key={ headline.id } headline={ headline } onClose={ handleCloseHeadline }/>
          )) }
        </section>
      ) }
      { children }
    </>
  );
};

export default HeadlinesProvider;
