/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - OutdatedServiceWorkerRibbon.tsx
 */

import React              from 'react';
import { useTranslation } from 'react-i18next';

import './OutdatedServiceWorkerRibbon.scss';

interface OutdatedServiceWorkerRibbonProps {
  onPerformUpdate: () => void;
}

const OutdatedServiceWorkerRibbon = ({ onPerformUpdate }: OutdatedServiceWorkerRibbonProps) => {
  const { t } = useTranslation('header');
  return (
    <div className="outdated-app-ribbon">
      { t('outdated-app.title') }
      <button className="flat-button primary success" onClick={ onPerformUpdate }>{ t('outdated-app.cta') }</button>
    </div>
  );
};

export default OutdatedServiceWorkerRibbon;
