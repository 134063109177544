/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - WeatherBundleBackground.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import WeatherCondition       from './enums/WeatherCondition';
import { WeatherBundle }      from './WeatherBundle';

interface IWeatherBundleBackground {
  id: number,
  bundle_id: number,
  weather: WeatherCondition | null,
  period: string | null,
  format_id: number,
  extension: string,

  created_at: DateTime,
  created_by: DateTime,
  updated_at: DateTime,
  updated_by: DateTime,

  url: string,
  thumbnail_url: string
}

class WeatherBundleBackgroundModel extends Model<IWeatherBundleBackground> {
  _slug: string = 'weather-background';

  basePath: string = '/v1/dynamics/weather/bundles/{bundle_id}/backgrounds';

  key: string = 'id';

  attributesTypes: { [attr in keyof IWeatherBundleBackground]?: (sourceAttr: any) => IWeatherBundleBackground[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [],
    save  : [],
  };
}

export class WeatherBundleBackground extends WeatherBundleBackgroundModel implements IWeatherBundleBackground {
  bundle_id!: number;

  created_at!: DateTime;

  created_by!: DateTime;

  extension!: string;

  format_id!: number;

  id!: number;

  period!: string | null;

  thumbnail_url!: string;

  updated_at!: DateTime;

  updated_by!: DateTime;

  url!: string;

  weather!: WeatherCondition | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onCreated(response: Partial<IWeatherBundleBackground>): Promise<this> {
    await super.onCreated(response);
    await (new WeatherBundle({ id: this.bundle_id })).invalidate(true, true);

    return this;
  }

  async onSaved(response: Partial<IWeatherBundleBackground>): Promise<this> {
    await super.onSaved(response);
    await (new WeatherBundle({ id: this.bundle_id })).invalidate(true, true);

    return this;
  }

  async onDeleted(response: Partial<IWeatherBundleBackground>): Promise<this> {
    await super.onSaved(response);
    await (new WeatherBundle({ id: this.bundle_id })).invalidate(true, true);

    return this;
  }
}
