/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Section.tsx
 */

import classNames               from 'classnames';
import Gate                     from 'components/Gate';
import React                    from 'react';
import { useTranslation }       from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

interface SectionProps {
  id?: string,
  label: string,
  path: string,

  capabilities: string[],

  highlight: string,
  icon?: string
}

const Section = ({ id, label, path, capabilities, highlight, icon }: SectionProps) => {
  const { t }    = useTranslation('header');
  const location = useLocation();

  const setHighlight = React.useCallback(() => {
    document.documentElement.style.setProperty('--highlight-color', `var(${ highlight })`);
    document.documentElement.style.setProperty('--highlight-color-rgb', `var(${ highlight }-rgb)`);
  }, [ highlight ]);

  React.useEffect(() => {
    if (location.pathname.startsWith(path)) {
      setHighlight();
    }
  }, [ location.pathname, path, setHighlight ]);

  return (
    <Gate capabilities={ capabilities }>
      { () => (
        <NavLink to={ path }
                 onClick={ setHighlight }
                 className={ classNames('sidebar__menu-item', id ?? label) }
                 style={ {
                   '--active-color'    : `var(${ highlight })`,
                   '--active-color-rgb': `var(${ highlight }-rgb)`,
                 } as any }>
          { icon && <span className={ classNames('sidebar__menu-item__icon', id ?? label) }
                          style={ {
                            maskImage      : `url(${ icon })`,
                            WebkitMaskImage: `url(${ icon })`,
                          } }
          /> }
          <span className="label">{ t(`sections.${ label }`) }</span>
        </NavLink>
      ) }
    </Gate>
  );
};

export default Section;
