/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Country.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface ICountry {
  id: number,
  name: string,
  slug: string,
}

class CountryModel extends Model<ICountry> {
  _slug = 'country';

  basePath = '/v1/countries/{country}';

  attributesTypes: { [attr in keyof ICountry]?: (sourceAttr: any) => ICountry[attr] } = {};

  key: keyof ICountry = 'slug';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ICountry | string)[] } = {
    create: [],
    save  : [],
  };
}

export class Country extends CountryModel implements ICountry {
  id!: number;

  name!: string;

  slug!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

}
