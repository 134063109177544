/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - CensusForwardSortationArea.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { MultiPolygon }       from '@turf/helpers';

export interface ICensusForwardSortationArea {
  id: string,
  census: number,
  province: string,
  landarea_sqkm: number,
  dissemination_uid: string,
  geometry: MultiPolygon,
}

class CensusForwardSortationAreaModel extends Model<ICensusForwardSortationArea> {
  _slug = 'census-fsa';

  basePath = '/v1/census-fsas';

  attributesTypes: { [attr in keyof ICensusForwardSortationArea]?: (sourceAttr: any) => ICensusForwardSortationArea[attr] } = {};

  key: keyof ICensusForwardSortationArea = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ICensusForwardSortationArea)[] } = {
    create: [],
    save  : [],
  };
}

export class CensusForwardSortationArea extends CensusForwardSortationAreaModel implements ICensusForwardSortationArea {
  census!: number;

  geometry!: MultiPolygon;

  id!: string;

  province!: string;

  dissemination_uid!: string;

  landarea_sqkm!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getName() {
    return this.id;
  }
}
