/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryResourceEvent.ts
 */

import { Model }                    from 'library/Model';
import { DateTime }                 from 'luxon';
import { Actor, InventoryResource } from 'models';

export interface IInventoryResourceEvent {
  id: number,
  resource_id: number,
  inventory_id: number,
  event_type: string,
  is_success: boolean,
  result: Record<string, any>,
  triggered_at: DateTime,
  triggered_by: number | null
  reviewed_at: DateTime,
  reviewed_by: number | null

  resource: InventoryResource,
  actor: Actor,
  reviewer: Actor,
}

class InventoryResourceEventModel extends Model<IInventoryResourceEvent> {
  _slug = 'inventories-resources-event';

  basePath = '/v1/inventories-events';

  attributesTypes: { [attr in keyof IInventoryResourceEvent]?: (sourceAttr: any) => IInventoryResourceEvent[attr] } = {
    triggered_at: (d) => DateTime.fromISO(d),
    resource    : Model.make(InventoryResource),
    actor       : Model.make(Actor),
    reviewer    : Model.make(Actor),
  };

  key: keyof IInventoryResourceEvent = 'id';

  routesAttributes = {
    create: [],
    save  : [],
  };
}

export class InventoryResourceEvent extends InventoryResourceEventModel implements IInventoryResourceEvent {
  event_type!: string;

  inventory_id!: number;

  is_success!: boolean;

  resource_id!: number;

  result!: Record<string, any>;

  triggered_at!: DateTime;

  triggered_by!: number | null;

  reviewed_at!: DateTime;

  reviewed_by!: number | null;

  resource!: InventoryResource;

  actor!: Actor;

  reviewer!: Actor;

  id!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getErrorMessage() {
    if (this.is_success) {
      return '';
    }

    return this.result?.message ?? this.result?.['\x00*\x00message'] ?? '';
  }
}
