/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - BroadcastTagType.ts
 */

enum BroadcastTagType {
  Targeting = 'criterion',
  Trigger   = 'trigger',
  Category  = 'category',
  Condition = 'condition',
}

export default BroadcastTagType;
