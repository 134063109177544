/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Field.ts
 */

import Collection                       from 'library/Collection';
import { DateTime }                     from 'luxon';
import { FieldsCategory, FieldSegment } from 'models';
import { Model }                        from 'library/Model';
import { ModelPersistAction }           from 'library/Model/types';
import { TFunction }                    from 'i18next';
import { queryClient }                  from 'library/Model/QueryClient';
import settings                         from 'library/settings';

export interface IField {
  id: number,
  dataset_version_id: number,
  category_id: number | null,
  order: number,
  name_en: string,
  name_fr: string,
  unit: string,
  visualization: string | null,

  category: FieldsCategory | null,
  segments: Collection<FieldSegment>

  created_at: DateTime,
  updated_at: DateTime,
}

class FieldModel extends Model<IField> {
  _slug = 'field';

  basePath = '/v1/fields';

  attributesTypes: { [attr in keyof IField]?: (sourceAttr: any) => IField[attr] } = {
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'segments'  : Collection.ofType(FieldSegment).make,
    'category'  : Model.make(FieldsCategory),
  };

  key: keyof IField = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IField | string)[] } = {
    create: [
      'dataset_version_id',
      'category_id',
      'name_en',
      'name_fr',
      'unit',
      'visualization',
    ],
    save  : [
      'category_id',
      'name_en',
      'name_fr',
      'unit',
      'visualization',
    ],
  };

}

export class Field extends FieldModel implements IField {
  created_at!: DateTime;

  id!: number;

  dataset_version_id!: number;

  category_id!: number | null;

  order!: number;

  name_en!: string;

  name_fr!: string;

  segments!: Collection<FieldSegment>;

  unit!: string;

  category!: FieldsCategory | null;

  updated_at!: DateTime;

  visualization!: string | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }


  getUnitLabel(t: TFunction) {
    switch (this.unit) {
      case 'cad':
        return '$';
      case 'minutes':
        return t('common:units.minutes');
      case 'percent':
        return t('common:units.percent');
      case 'square-feet':
        return t('common:units.square-feet');
      case 'times':
        return t('common:units.times');
      default:
        return '';
    }
  }

  async onCreated(response: Partial<IField>): Promise<this> {
    await super.onSaved(response);

    // On update, invalidate planner data
    await Promise.allSettled([
      (new FieldsCategory()).invalidateAll(),
      queryClient.invalidateQueries({ queryKey: settings['campaign-planner']['data-cache-key'] }),
    ]);

    return this;
  }

  async onSaved(response: Partial<IField>): Promise<this> {
    await super.onSaved(response);

    // On update, invalidate planner data
    await Promise.allSettled([
      (new FieldsCategory()).invalidateAll(),
      queryClient.invalidateQueries({ queryKey: settings['campaign-planner']['data-cache-key'] }),
    ]);

    return this;
  }

  async onDeleted(response: Partial<IField>): Promise<this> {
    await super.onSaved(response);

    // On delete, invalidate planner data
    await Promise.allSettled([
      (new FieldsCategory()).invalidateAll(),
      queryClient.invalidateQueries({ queryKey: settings['campaign-planner']['data-cache-key'] }),
    ]);

    return this;
  }
}
