/*
 * Copyright 2020 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * neo-access - Consumers.jsx
 */

import React from 'react';

// export interface ConsumersProps {
//   response?: Response
//   children: ((response: Response) => any) | React.ReactElement
// }

/**
 * @abstract
 * @class Consumers
 */
class Consumers extends React.Component {
  /**
   * @abstract
   *
   * @param response {Response}
   * @return boolean
   */
  shouldRenderChildren(response) {};

  render() {
    const response = this.props.response;

    if (!this.shouldRenderChildren(response)) {
      return null;
    }

    if (typeof this.props.children === 'function') {
      return this.props.children(response);
    }

    // Pass the response to all children
    return React.Children.map(this.props.children, children => children);
  }
}

/**
 * A `Placeholder` will only show its children if the encompassing Request has not yet resolved.
 * If React components are passed as children the top-most ones will receive the response in a `response` prop.
 * If a method is passed as children it will be called with the `response` object as its sole parameter.
 */
export class Placeholder extends Consumers {
  shouldRenderChildren(response) {
    return !response.loaded;
  }
}

/**
 * A `Success` will only show its children if the encompassing Request has resolved successfully
 * If React components are passed as children the top-most ones will receive the response in a `response` prop.
 * If a method is passed as children it will be called with the `response` object as its sole parameter.
 */
export class Success extends Consumers {
  shouldRenderChildren(response) {
    return response.loaded && response.success;
  }
}

/**
 * An `Error` will only show its children if the encompassing Request has resolved with an error
 * If React components are passed as children the top-most ones will receive the response in a `response` prop.
 * If a method is passed as children it will be called with the `response` object as its sole parameter.
 */
export class Error extends Consumers {
  shouldRenderChildren(response) {
    return response.loaded && !response.success;
  }
}
