/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - AccessToken.ts
 */

import Collection             from 'library/Collection';
import { DateTime }           from 'luxon';
import { Capability }         from './Capability';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IAccessToken {
  id: number,
  name: string,
  last_used_at: DateTime,
  created_at: DateTime,
  updated_at: DateTime

  token: string,

  capabilities: Collection<Capability>
}

class AccessTokenModel extends Model<IAccessToken> {
  _slug = 'access-token';

  basePath = '/v1/access-tokens';

  attributesTypes: { [attr in keyof IAccessToken]?: (sourceAttr: any) => IAccessToken[attr] } = {
    'capabilities': Collection.ofType(Capability).make,
    'last_used_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'created_at'  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at'  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IAccessToken)[] } = {
    create: [ 'name', 'capabilities' ],
    save  : [ 'name', 'capabilities' ],
  };
}

export class AccessToken extends AccessTokenModel implements IAccessToken {
  capabilities!: Collection<Capability>;

  created_at!: DateTime;

  id!: number;

  last_used_at!: DateTime;

  name!: string;

  token!: string;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
