/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - BroadcastResourceType.ts
 */

enum BroadcastResourceType {
  Creative   = 'creative',
  Content    = 'content',
  Schedule   = 'schedule',
  Campaign   = 'campaign',

  Advertiser = 'advertiser',
  Tag        = 'tag',
}

export default BroadcastResourceType;
