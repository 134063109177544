/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - BroadcastTag.ts
 */

import BroadcastTagType                                           from './enums/BroadcastTagType';
import Collection                                                 from 'library/Collection';
import BroadcastTagScope                                          from './enums/BroadcastTagScope';
import { Model }                                                  from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor }          from 'library/Model/types';
import { DateTime }                                               from 'luxon';
import { ExternalResource, IExternalResource }                    from './ExternalResource';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRequest }                                            from 'library/Request/Request';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { queryClient }                                            from 'library/Model/QueryClient';
import { LocalizedKey }                                           from 'library/types';
import { I18n }                                                   from '../providers/TranslationProvider/i18n';

export interface IBroadcastTag {
  id: number,
  type: BroadcastTagType,
  name_en: string,
  name_fr: string,
  scope: Collection<BroadcastTagScope>,
  is_primary: boolean,

  external_representations: Collection<ExternalResource>,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,
}

class BroadcastTagModel extends Model<IBroadcastTag> {
  _slug = 'broadcast-tag';

  basePath = '/v1/broadcast-tags';

  attributesTypes: { [attr in keyof IBroadcastTag]?: (sourceAttr: any) => IBroadcastTag[attr] } = {
    created_at              : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at              : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at              : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    scope                   : Collection.from,
    external_representations: Collection.ofType(ExternalResource).make,
  };

  key = 'id';

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IBroadcastTag> } = {
    create: [ 'type', 'name_en', 'name_fr', 'scope', 'is_primary' ],
    save  : [ 'name_en', 'name_fr', 'scope', 'is_primary' ],
  };
}

export class BroadcastTag extends BroadcastTagModel implements IBroadcastTag {
  created_at!: DateTime;

  deleted_at!: DateTime | null;

  external_representations!: Collection<ExternalResource>;

  id!: number;

  is_primary!: boolean;

  name_en!: string;

  name_fr!: string;

  scope!: Collection<BroadcastTagScope>;

  type!: BroadcastTagType;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getName(i18n: I18n) {
    return this[`name_${ i18n.language }` as LocalizedKey<'name'>];
  }

  syncRepresentations(representations: { broadcaster_id: number, external_id: string }[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/representations/_sync');
    const params = preparePathParameters(path, this);

    return makeRequest<IExternalResource[]>(makeRoute(path, HTTPMethod.put), params, {
      representations: representations,
    }).then(({ data }) => this.external_representations = Collection.ofType(ExternalResource).make(data))
      .then(() => {
        queryClient.setQueriesData<BroadcastTag>({ queryKey: [ this._slug, this.getKey() ] }, cached => {
          if (cached === undefined) {
            return undefined;
          }

          if (cached.external_representations === undefined) {
            return undefined;
          }

          cached.external_representations = this.external_representations;
          return cached;
        });
      });
  }
}
