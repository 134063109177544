/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ModalFooter.tsx
 */

import React from 'react';

interface ModalFooterProps {
  children: React.ReactNode,
}

const ModalFooter = ({ children }: ModalFooterProps) => {
  return (
    <div className="row form-actions">
      { children }
    </div>
  );
};

export default ModalFooter;