/*
 * Copyright 2022 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - useModelObserver.ts
 */

import React        from 'react';
import { Model }    from '../Model';
import { DateTime } from 'luxon';
import { v4 }       from 'uuid';

/**
 * Use this hook to trigger a component refresh after the given model has been created/updated/deleted
 *
 * @param model
 */
export function useModelObserver<T extends Model<any>>(model: T) {
  const id = React.useMemo(() => v4(), []);

  const [ lastUpdate, setLastUpdate ] = React.useState(DateTime.now());

  React.useEffect(() => {
    model.addObserver(id, () => setLastUpdate(DateTime.now));

    return () => model.removeObserver(id);
  });

  return lastUpdate;
}
