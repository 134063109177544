/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Dataset.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import Collection             from 'library/Collection';
import { DatasetVersion }     from 'models';

export interface IDataset {
  id: number,
  name: string,

  versions: Collection<DatasetVersion>
}

class DatasetModel extends Model<IDataset> {
  _slug = 'dataset';

  basePath = '/v1/demographics/datasets';

  attributesTypes: { [attr in keyof IDataset]?: (sourceAttr: any) => IDataset[attr] } = {
    versions: Collection.ofType(DatasetVersion).make,
  };

  key: keyof IDataset = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IDataset | string)[] } = {
    create: [],
    save  : [],
  };
}

export class Dataset extends DatasetModel implements IDataset {
  id!: number;

  name!: string;

  versions!: Collection<DatasetVersion>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
