/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - BroadcastPriority.ts
 */

import { Model }                                         from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor } from 'library/Model/types';
import { DateTime }                                      from 'luxon';
import { LocalizedKey }                                  from 'library/types';
import { I18n }                                          from '../providers/TranslationProvider/i18n';

export interface IBroadcastPriority {
  id: number,
  value: number,
  name_en: string,
  name_fr: string,

  created_at: DateTime,
  updated_at: DateTime,
}

class BroadcastPriorityModel extends Model<IBroadcastPriority> {
  _slug = 'broadcast-priorities';

  basePath = '/v1/broadcast-priorities';

  attributesTypes: { [attr in keyof IBroadcastPriority]?: (sourceAttr: any) => IBroadcastPriority[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'id';

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IBroadcastPriority> } = {
    create: [ 'value', 'name_en', 'name_fr' ],
    save  : [ 'value', 'name_en', 'name_fr' ],
  };
}

export class BroadcastPriority extends BroadcastPriorityModel implements IBroadcastPriority {
  created_at!: DateTime;

  id!: number;

  name_en!: string;

  name_fr!: string;

  value!: number;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getName(i18n: I18n) {
    return this[`name_${ i18n.language }` as LocalizedKey<'name'>];
  }
}
