/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Role.ts
 */

import { DateTime }                                               from 'luxon';
import Collection                                                 from 'library/Collection';
import { HTTPMethod }                                             from 'library/Request';
import { Actor, Capability, IActor, ICapability }                 from 'models';
import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { makeRequest }                                            from 'library/Request/Request';
import { queryClient }                                            from 'library/Model/QueryClient';

export interface IRole {
  id: number,
  name: string,
  desc: string,

  capabilities: Collection<Capability>
  actors: Collection<Actor>

  created_at: DateTime,
  updated_at: DateTime,
}

class RoleModel extends Model<IRole> {
  _slug = 'role';

  basePath = '/v1/roles';

  attributesTypes: { [attr in keyof IRole]?: (sourceAttr: any) => IRole[attr] } = {
    created_at  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    capabilities: Collection.ofType(Capability).make,
    actors      : Collection.ofType(Actor).make,
  };

  key: keyof IRole = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IRole | string)[] } = {
    create: [ 'name', 'desc', 'capabilities' ],
    save  : [ 'name', 'desc' ],
  };

}

export class Role extends RoleModel implements IRole {
  id!: number;

  name!: string;

  desc!: string;

  capabilities!: Collection<Capability>;

  actors!: Collection<Actor>;

  created_at!: DateTime;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  /*
   |--------------------------------------------------------------------------
   | Actions
   |--------------------------------------------------------------------------
   */

  syncCapabilities(capabilities: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/capabilities');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest<ICapability[]>(route, params, {
      capabilities: capabilities,
    }).then(({ data }) => {
      // @ts-ignore
      this.setAttributes({ capabilities: data });
      return queryClient.setQueriesData({ queryKey: [ this._slug, this.getKey() ] }, (role?: Role) => {
        // @ts-ignore
        role.setAttributes({ capabilities: data });
        return role;
      });
    });
  }

  /**
   * Give the role to the specified actor
   */
  async addActor(actor: Actor) {
    const path   = preparePathForSingleModelAction(this.basePath, '/actors');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    const response = await makeRequest<IActor[]>(route, params, {
      actor_id: actor.getKey(),
    });

    return this.setAttributes({ actors: Collection.ofType(Actor).make(response.data) });
  };

  /**
   * Remove the role from the given actor
   */
  async removeActor(actor: Actor) {
    const path   = preparePathForSingleModelAction(this.basePath, '/actors');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.delete);

    const response = await makeRequest<IActor[]>(route, params, {
      actor_id: actor.getKey(),
    });
    return this.setAttributes({ actors: Collection.ofType(Actor).make(response.data) });
  };
}
