/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - IndexSetTemplate.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { DateTime }           from 'luxon';
import Collection             from 'library/Collection';
import { IndexSet }           from './IndexSet';
import { DatasetVersion }     from './DatasetVersion';
import { ExtractTemplate }    from './ExtractTemplate';

export interface IIndexSetTemplate {
  id: number,
  name: string,
  description: string,
  dataset_version_id: number,
  primary_extract_template_id: number,
  reference_extract_template_id: number,

  created_at: DateTime,
  created_by: number | null,
  updated_at: DateTime,
  updated_by: number | null,
  deleted_at: DateTime | null,
  deleted_by: number | null,

  dataset_version: DatasetVersion,
  primary_extract_template: ExtractTemplate,
  reference_extract_template: ExtractTemplate,
  sets: Collection<IndexSet>,
}

class IndexSetTemplateModel extends Model<IIndexSetTemplate> {
  _slug = 'index-set-template';

  basePath = '/v1/demographics/index-sets-templates';

  attributesTypes: { [attr in keyof IIndexSetTemplate]?: (sourceAttr: any) => IIndexSetTemplate[attr] } = {
    dataset_version           : Model.make(DatasetVersion),
    primary_extract_template  : Model.make(ExtractTemplate),
    reference_extract_template: Model.make(ExtractTemplate),
    sets                      : Collection.ofType(IndexSet).make,
  };

  key: keyof IIndexSetTemplate = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IIndexSetTemplate | string)[] } = {
    create: [ 'name', 'description', 'dataset_version_id', 'primary_extract_template_id', 'reference_extract_template_id' ],
    save  : [ 'name', 'description' ],
  };
}

export class IndexSetTemplate extends IndexSetTemplateModel implements IIndexSetTemplate {
  id!: number;

  name!: string;

  description!: string;

  dataset_version_id!: number;

  primary_extract_template_id!: number;

  reference_extract_template_id!: number;

  created_at!: DateTime<true>;

  created_by!: number | null;

  updated_at!: DateTime<true>;

  updated_by!: number | null;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  sets!: Collection<IndexSet>;

  dataset_version!: DatasetVersion;

  primary_extract_template!: ExtractTemplate;

  reference_extract_template!: ExtractTemplate;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
