/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Tag.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface ITag {
  id: number,
  name: string,
  color: string,
  created_at: DateTime,
  updated_at: DateTime,
}

class TagModel extends Model<ITag> {
  _slug = 'tag';

  basePath = '/v1/tags';

  attributesTypes: { [attr in keyof ITag]?: (sourceAttr: any) => ITag[attr] } = {
    created_at: (d) => DateTime.fromISO(d),
    updated_at: (d) => DateTime.fromISO(d),
  };

  key: keyof ITag = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ITag | string)[] } = {
    create: [ 'name' ],
    save  : [ 'name', 'color' ],
  };
}

export class Tag extends TagModel implements ITag {
  color!: string;

  created_at!: DateTime;

  id!: number;

  name!: string;

  updated_at!: DateTime;

  public constructor(attributes: Partial<ITag>) {
    super();
    this.setAttributes(attributes);
  }
}
