/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - AuthProvider.tsx
 */

import Auth, { AuthLevel }       from 'library/Auth';
import Request                   from 'library/Request';
import routes                    from 'library/routes';
import { AuthContext, IAuthCtx } from 'providers/AuthProvider/AuthContext';

import 'providers/AuthProvider/AuthProvider.scss';
import AuthRouter                from 'providers/AuthProvider/AuthRouter';
import ImpersonatingRibbon       from 'providers/AuthProvider/ImpersonatingRibbon';
import React                     from 'react';
import { useNavigate }           from 'react-router-dom';
import { Actor }                 from 'models';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [ isInit, setIsInit ]               = React.useState(false);
  const [ authLevel, setAuthLevel ]         = React.useState(AuthLevel.GUEST);
  const [ currentUserId, setCurrentUserId ] = React.useState<number | null>(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      // Init authentication
      const authLevel = await Auth.init();
      setAuthLevel(authLevel);

      // Init the request object
      Request.setToken(Auth.getToken());

      // User is a guest ? Handle it now
      if (authLevel === AuthLevel.GUEST) {
        if (window.location.pathname === '/two-factor-auth' || window.location.pathname === '/review-tos') {
          window.location.href = '/';
        } else {
          Auth.clearAuthCookie();
        }

        setIsInit(true);
        return;
      }

      // User is not a guest, request an updated version of the token
      Request.make(routes.actors.newToken).then(async response => {
        const newLevel = await Auth.setToken(response.data.token);

        if ((authLevel === AuthLevel.FULL || authLevel === AuthLevel.TWOFA) && newLevel === AuthLevel.LOGIN) {
          // User 2FA expired, log it out
          Auth.logout();
        }

        setAuthLevel(newLevel);

        if (newLevel === AuthLevel.FULL) {
          Auth.loadUserData().then(() => {
            setCurrentUserId(Auth.getUserID());
            setIsInit(true);
          });
        } else {
          setIsInit(true);
        }
      });
    })();
  }, []);


  const startImpersonating = React.useCallback(async (actorId: number) => {
    // To impersonate someone, we need an impersonating token tied to our current user
    const impersonatingToken = await Request.make(routes.actors.impersonate, { id: actorId })
                                            .then(response => response.data.token)
                                            .catch(() => null);

    if (!impersonatingToken) {
      return;
    }

    const success = await Auth.startImpersonating(impersonatingToken);
    if (!success) {
      return;
    }

    Request.setToken(Auth.getToken());
    setCurrentUserId(actorId);

    navigate('/');

  }, [ navigate ]);

  const stopImpersonating = React.useCallback(() => {
    const impersonatedUser = Auth.getUserID();
    Auth.stopImpersonating();

    Request.setToken(Auth.getToken());

    setCurrentUserId(Auth.getUserID());
    navigate(`/actors/${ impersonatedUser }`);
    // window.location = `/users/${ impersonatedUser }`
  }, [ navigate ]);

  const authCtx: IAuthCtx = React.useMemo(() => ({
    userId         : currentUserId ?? 0,
    user           : Auth.getUser() ?? new Actor(),
    authLevel,
    startImpersonating,
    stopImpersonating,
    isImpersonating: Auth.isImpersonating(),
  }), [ currentUserId, authLevel, startImpersonating, stopImpersonating ]);

  // Until auth initialization is done, show nothing
  if (!isInit) {
    return null;
  }

  if (authLevel === AuthLevel.FULL && [ '/review-tos', 'two-factor-auth' ].includes(window.location.pathname)) {
    window.location.href = '/';
  }

  // When in intermediate auth level, force the url
  if (authLevel === AuthLevel.LOGIN && window.location.pathname !== '/two-factor-auth') {
    window.location.href = '/two-factor-auth';
  }

  if (authLevel === AuthLevel.TWOFA && window.location.pathname !== '/review-tos') {
    window.location.href = '/review-tos';
  }

  return (
    <AuthContext.Provider value={ authCtx }>
      { authCtx.isImpersonating && <ImpersonatingRibbon/> }
      {/* Show the app */ }
      { authLevel === AuthLevel.FULL && children }

      {/* Show the landing */ }
      { authLevel !== AuthLevel.FULL && <AuthRouter/> }
    </AuthContext.Provider>
  );
};

export default AuthProvider;
