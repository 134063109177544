/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PricelistProductsCategory.ts
 */

import { Model }              from 'library/Model';
import { DateTime }           from 'luxon';
import { ModelPersistAction } from 'library/Model/types';
import { PricingType }        from './enums/PricingType';
import { Pricelist }          from 'models';

interface IPricelistProductsCategory {
  pricelist_id: number,
  products_category_id: number,
  pricing: PricingType,
  value: number,
  min: number,
  max: number,

  created_at: DateTime,
  updated_at: DateTime,
}

class PricelistProductCategoryModel extends Model<IPricelistProductsCategory> {
  _slug = 'pricelist-category';

  basePath = '/v1/pricelists/{pricelist_id}/product-categories';

  key = 'products_category_id';

  attributesTypes: { [attr in keyof IPricelistProductsCategory]?: (sourceAttr: any) => IPricelistProductsCategory[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [ 'products_category_id', 'pricing', 'value', 'min', 'max' ],
    save  : [ 'pricing', 'value', 'min', 'max' ],
  };
}

export class PricelistProductsCategory extends PricelistProductCategoryModel implements IPricelistProductsCategory {
  created_at!: DateTime;

  max!: number;

  min!: number;

  pricelist_id!: number;

  pricing!: PricingType;

  products_category_id!: number;

  updated_at!: DateTime;

  value!: number;

  constructor(attributes?: Partial<IPricelistProductsCategory>) {
    super();
    this.setAttributes(attributes ?? {});
  }

  async onCreated(response: Partial<IPricelistProductsCategory>): Promise<this> {
    await super.onCreated(response);

    await Promise.allSettled([
      (new Pricelist({ id: this.pricelist_id })).invalidate(),
      (new Pricelist()).invalidateAll(),
    ]);

    return this;
  }

  async onSaved(response: Partial<IPricelistProductsCategory>): Promise<this> {
    await super.onSaved(response);

    await Promise.allSettled([
      (new Pricelist({ id: this.pricelist_id })).invalidate(),
      (new Pricelist()).invalidateAll(),
    ]);

    return this;
  }

  async onDeleted(response: Partial<IPricelistProductsCategory>): Promise<this> {
    await super.onDeleted(response);

    await Promise.allSettled([
      (new Pricelist({ id: this.pricelist_id })).invalidate(),
      (new Pricelist()).invalidateAll(),
    ]);

    return this;
  }
}
