/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - GuestsAccessProvider.jsx
 */

import React                          from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const StaticPlannerWrapper = React.lazy(() => import('./StaticPlanner/StaticPlannerWrapper'));
const Public               = React.lazy(() => import('scenes/Public'));

const GuestsAccessProvider = ({ children }) => {
  const path = useLocation();

  if (!path.pathname.startsWith('/_guest') && !path.pathname.startsWith('/public')) {
    return children;
  }

  return (
    <Routes>
      <Route path="/public/*" element={ <Public/> }/>
      <Route path="/_guests/campaigns/:save" element={ <StaticPlannerWrapper/> }/>
    </Routes>
  );
};

export default GuestsAccessProvider;
