/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - DisplayType.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import Collection             from 'library/Collection';
import { DisplayTypeFrame }   from './DisplayTypeFrame';

export interface IDisplayType {
  id: number,
  name: string,
  internal_name: string,
  width_px: number,
  height_px: number,
  connection_id: number,
  external_id: string,

  created_at: DateTime,
  updated_at: DateTime,

  frames: Collection<DisplayTypeFrame>
}

class DisplayTypeModel extends Model<IDisplayType> {
  _slug = 'display-type';

  basePath = '/v2/display-types';

  attributesTypes: { [attr in keyof IDisplayType]?: (sourceAttr: any) => IDisplayType[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    frames    : Collection.ofType(DisplayTypeFrame).make,
  };

  key: keyof IDisplayType = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IDisplayType | string)[] } = {
    create: [],
    save  : [ 'name' ],
  };
}


export class DisplayType extends DisplayTypeModel implements IDisplayType {
  connection_id!: number;

  created_at!: DateTime;

  external_id!: string;

  height_px!: number;

  width_px!: number;

  id!: number;

  internal_name!: string;

  name!: string;

  updated_at!: DateTime;

  frames!: Collection<DisplayTypeFrame>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
