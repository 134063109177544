/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - MobileProduct.ts
 */

import { Model }                from 'library/Model';
import { ModelPersistAction }   from 'library/Model/types';
import { MobileProductBracket } from 'models';
import { DateTime }             from 'luxon';
import Collection               from 'library/Collection';

export interface IMobileProduct {
  id: number,
  name_en: string,
  name_fr: string,

  brackets: Collection<MobileProductBracket>;

  created_at: DateTime,
  updated_at: DateTime,
}

class MobileProductModel extends Model<IMobileProduct> {
  _slug = 'mobile-product';

  basePath = '/v1/mobile-products';

  attributesTypes: { [attr in keyof IMobileProduct]?: (sourceAttr: any) => IMobileProduct[attr] } = {
    brackets  : Collection.ofType(MobileProductBracket).make,
    created_at: d => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: d => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IMobileProduct = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IMobileProduct | string)[] } = {
    create: [ 'name_en', 'name_fr' ],
    save  : [ 'name_en', 'name_fr' ],
  };
}

export class MobileProduct extends MobileProductModel implements IMobileProduct {
  id!: number;

  brackets!: Collection<MobileProductBracket>;

  created_at!: DateTime;

  name_en!: string;

  name_fr!: string;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
