/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Pricelist.ts
 */

import { Model }                                                                                              from 'library/Model';
import { DateTime }                                                                                           from 'luxon';
import {
  ModelPersistAction,
}                                                                                                             from 'library/Model/types';
import Collection
                                                                                                              from 'library/Collection';
import { PricelistProduct, PricelistProductsCategory, PricelistProperty, Product, ProductCategory, Property } from 'models';
import {
  makeRequest,
}                                                                                                             from 'library/Request/Request';
import {
  makeRoute,
}                                                                                                             from 'library/modelsUtils';
import {
  HTTPMethod,
}                                                                                                             from 'library/Request';

export interface IPricelist {
  id: number,
  name: string,
  description: string | null,

  created_at: DateTime,
  updated_at: DateTime,

  categories: Collection<ProductCategory>,
  categories_pricings: Collection<PricelistProductsCategory>

  products: Collection<Product>,
  products_pricings: Collection<PricelistProduct>

}

class PricelistModel extends Model<IPricelist> {
  _slug = 'pricelist';

  basePath = '/v1/pricelists';

  key = 'id';

  attributesTypes: { [attr in keyof IPricelist]?: (sourceAttr: any) => IPricelist[attr] } = {
    categories         : Collection.ofType(ProductCategory).make,
    categories_pricings: Collection.ofType(PricelistProductsCategory).make,
    products           : Collection.ofType(Product).make,
    products_pricings  : Collection.ofType(PricelistProduct).make,
    created_at         : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at         : (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [ 'name', 'description' ],
    save  : [ 'name', 'description' ],
  };
}

export class Pricelist extends PricelistModel implements IPricelist {
  created_at!: DateTime;

  description!: string | null;

  id!: number;

  name!: string;

  updated_at!: DateTime;

  categories!: Collection<ProductCategory>;

  categories_pricings!: Collection<PricelistProductsCategory>;

  products!: Collection<Product>;

  products_pricings!: Collection<PricelistProduct>;

  constructor(attributes?: Partial<IPricelist>) {
    super();
    this.setAttributes(attributes ?? {});
  }

  syncProperties(propertiesIds: number[]) {
    return makeRequest(makeRoute((new PricelistProperty()).basePath, HTTPMethod.put), {
      pricelist_id: this.getKey(),
    }, {
      ids: propertiesIds,
    }).then(async () => {
      await this.invalidate();
      await (new Property()).invalidateAll();

      propertiesIds.forEach(id => {
        (new Property({ actor_id: id })).invalidate();
      });
    });
  }
}
