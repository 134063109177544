/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Property.ts
 */

import { DateTime }                                               from 'luxon';
import {
  Actor,
  Brand,
  InventoryPicture,
  InventoryResourceRestriction,
  OpeningHours,
  Pricelist,
  Product,
  ProductCategory,
  PropertyContact,
  PropertyNetwork,
  PropertyTrafficSettings,
  PropertyTranslation,
  PropertyType,
  Unavailability,
}                                                                 from 'models';
import Collection                                                 from 'library/Collection';
import Model                                                      from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import Request, { makeRequest }                                   from 'library/Request/Request';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { IProperty, PropertyWarnings }                            from './interfaces/IProperty';
import { InventoryResource }                                      from './InventoryResource';
import { handleDownload }                                         from 'library/utils';
import { Address }                                                from './Address';
import { InventoryResourceModel }                                 from './interfaces/InventoryResourceModel';

class PropertyModel extends Model<IProperty> {
  _slug = 'property';

  basePath = '/v1/properties';

  key = 'actor_id' as const;

  attributesTypes: { [attr in keyof IProperty]?: (sourceAttr: any) => IProperty[attr] } = {
    'actor'              : Model.make(Actor),
    'address'            : Model.make(Address),
    'contacts'           : Collection.ofType(PropertyContact).make,
    cover_picture        : Model.make(InventoryPicture),
    created_at           : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'has_tenants'        : v => !!v,
    'inventory_resource' : Model.make(InventoryResource),
    last_review_at       : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'network'            : Model.make(PropertyNetwork),
    'opening_hours'      : Collection.ofType(OpeningHours).make,
    'pictures'           : Collection.ofType(InventoryPicture).make,
    'pricelist'          : Model.make(Pricelist),
    'products'           : Collection.ofType(Product).make,
    'products_categories': Collection.ofType(ProductCategory).make,
    'tenants'            : Collection.ofType(Brand).make,
    'traffic'            : Model.make(PropertyTrafficSettings),
    'translations'       : Collection.ofType(PropertyTranslation).make,
    'type'               : Model.make(PropertyType),
    'unavailabilities'   : Collection.ofType(Unavailability).make,
    updated_at           : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    restrictions         : Collection.ofType(InventoryResourceRestriction).make,
  };

  routesAttributes: { [attr in ModelPersistAction]: (keyof IProperty)[] } = {
    create: [ 'actor_id', 'network_id' ],
    save  : [
      'network_id',
      'has_tenants',
      'pricelist_id',
      'mobile_impressions_per_week',
      'type_id',
      'is_sellable',
      'website',
      'cover_picture_id',
      'notes',
    ],
  };
}

export class Property extends PropertyModel implements IProperty, InventoryResourceModel {
  actor!: Actor;

  actor_id!: number;

  name!: string;

  inventory_resource_id!: number;

  is_sellable!: boolean;

  address!: Address;

  address_id!: number;

  type_id!: number | null;

  type!: PropertyType | null;

  contacts!: Collection<PropertyContact>;

  created_at!: DateTime;

  inventory_resource!: InventoryResource;

  opening_hours!: Collection<OpeningHours>;

  has_tenants!: boolean;

  mobile_impressions_per_week!: number;

  network!: PropertyNetwork;

  network_id!: number;

  pictures!: Collection<InventoryPicture>;

  pricelist_id!: number | null;

  products!: Collection<Product>;

  products_categories!: Collection<ProductCategory>;

  rolling_weekly_traffic!: number[];

  tenants!: Collection<Brand>;

  restrictions!: Collection<InventoryResourceRestriction>;

  last_review_at!: DateTime;

  traffic!: PropertyTrafficSettings;

  updated_at!: DateTime;

  warnings!: PropertyWarnings;

  pricelist!: Pricelist | null;

  website!: string;

  translations!: Collection<PropertyTranslation>;

  unavailabilities!: Collection<Unavailability>;

  notes!: string;

  cover_picture!: InventoryPicture | null;

  cover_picture_id!: number | null;

  pictures_count!: number;

  mockups_count!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  static async needAttention() {
    const path           = (new Property()).basePath + '/_need_attention';
    const pathParameters = preparePathParameters(path, { getKey: () => null });

    return makeRequest<Record<string, any>[]>(makeRoute(path, HTTPMethod.get), pathParameters)
      .then(response => Collection.ofType(Property).make(response.data));
  }

  getName() {
    return this.name;
  }

  getTranslation(language: string): PropertyTranslation | undefined {
    return this.translations.findBy('locale', `${ language }-CA`);
  }

  importTenants(brands: string[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/tenants');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return Request.make(route, params, { tenants: brands })
                  .then(() => {
                    this.invalidate(false, true);
                    return this.invalidate(true, false);
                  });
  }

  syncTenants(brandsIds: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/tenants');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return Request.make(route, params, { tenants: brandsIds })
                  .then(() => {
                    this.invalidateAll();
                    return this.invalidate(true, false);
                  });
  }

  export(ids?: number[], level?: string, name?: string) {
    const path         = this.basePath + '/_export';
    const route        = makeRoute(path, HTTPMethod.get);
    route.responseType = 'arraybuffer';

    return makeRequest(route, {}, {
      ids  : ids ?? [ this.getKey() ],
      level: level,
    })
      .then(handleDownload(`${ name ?? this.getName() }.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ));
  }
}

export class PricelistProperty extends Property {
  basePath = '/v1/pricelists/{pricelist_id}/properties';
}

export class PublicProperty extends Property {
  basePath = '/public/v1/properties';
}
