/*
 * Copyright 2022 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - modelsUtils.ts
 */

import { HTTPMethod, Route } from './Request';

export function makeRoute(
  path: string,
  method: HTTPMethod,
  invalidatePaths: string[] | null = null,
  auth: boolean                    = true,
): Route {
  return {
    uri       : path,
    method    : method,
    auth      : auth,
    headers   : {},
    invalidate: invalidatePaths ? invalidatePaths : method === HTTPMethod.get ? [] : [ path ],
    cache     : false,
  };
}
