/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - SubSection.tsx
 */

import React              from 'react';
import { useTranslation } from 'react-i18next';
import Capability         from 'library/Capability';
import * as HoverCard     from '@radix-ui/react-hover-card';
import Gate               from 'components/Gate';

interface SubSectionProps {
  capabilities: Capability[],
  highlight: string,

  children: React.ReactNode,
}

const SubSection = ({ capabilities, highlight, children }: SubSectionProps) => {
  const { t } = useTranslation();
  return (
    <Gate capabilities={ capabilities }>
      { () => (
        <HoverCard.Root openDelay={ 0 }>
          <HoverCard.Trigger className="sidebar__subsection" tabIndex={ 0 }
                             style={ {
                               '--active-color'    : `var(${ highlight })`,
                               '--active-color-rgb': `var(${ highlight }-rgb)`,
                             } as any }>
            { '···' }
          </HoverCard.Trigger>
          <HoverCard.Portal>
            <HoverCard.Content side="right"
                               className="sidebar__subsection__popup">
              { children }
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      ) }
    </Gate>
  );
};

export default SubSection;
