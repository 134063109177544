/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ExternalResource.ts
 */

import { Model }                                         from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor } from 'library/Model/types';
import { DateTime }                                      from 'luxon';
import ExternalResourceData                              from './interfaces/ExternalResourceData';
import ExternalResourceType                              from './enums/ExternalResourceType';
import { BroadcastResource }                             from './BroadcastResource';

export interface IExternalResource {
  id: number,
  type: ExternalResourceType,
  resource_id: number,
  broadcaster_id: number,
  data: ExternalResourceData

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,
}

class ExternalResourceModel extends Model<IExternalResource> {
  _slug = 'external-resource';

  basePath = '/v1/external-resources';

  attributesTypes: { [attr in keyof IExternalResource]?: (sourceAttr: any) => IExternalResource[attr] } = {
    created_at: (d) => DateTime.fromISO(d),
    updated_at: (d) => DateTime.fromISO(d),
    deleted_at: (d) => DateTime.fromISO(d),
  };

  key = 'id';

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IExternalResource> } = {
    create: [ 'type', 'resource_id', 'broadcaster_id', 'data' ],
    save  : [],
  };
}

export class ExternalResource extends ExternalResourceModel implements IExternalResource {
  broadcaster_id!: number;

  created_at!: DateTime;

  data!: ExternalResourceData;

  deleted_at!: DateTime | null;

  id!: number;

  resource_id!: number;

  type!: ExternalResourceType;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onDeleted(response: Partial<IExternalResource>): Promise<this> {
    await super.onDeleted(response);
    await (new BroadcastResource({ id: this.resource_id })).invalidate();
    return this;
  }

  getSerialized() {
    return {
      ...this,
      created_at: this.created_at.toISO(),
      updated_at: this.updated_at.toISO(),
      deleted_at: this.deleted_at?.toISO(),
    };
  }
}
