/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Layout.ts
 */

import Collection           from 'library/Collection';
import { DateTime }         from 'luxon';
import { Format, Frame }    from 'models';
import { Model }            from 'library/Model';
import { BroadcastTag }     from './BroadcastTag';
import FormatLayoutSettings from './interfaces/FormatLayoutSettings';
import BroadcastTagType     from './enums/BroadcastTagType';
import { I18n }             from '../providers/TranslationProvider/i18n';
import { LocalizedName }    from 'library/types';

export interface ILayout {
  id: number,
  name_en: string,
  name_fr: string,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime,

  frames: Collection<Frame>
  formats: Collection<Format>
  broadcast_tags: Collection<BroadcastTag>

  settings: FormatLayoutSettings
}

class LayoutModel extends Model<ILayout> {
  _slug = 'format-layout';

  basePath = '/v2/layouts';

  attributesTypes: { [attr in keyof ILayout]?: (sourceAttr: any) => ILayout[attr] } = {
    'created_at'  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at'  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'deleted_at'  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    frames        : Collection.ofType(Frame).make,
    formats       : Collection.ofType(Format).make,
    broadcast_tags: Collection.ofType(BroadcastTag).make,
  };

  key: keyof ILayout = 'id';

  routesAttributes = {
    create: {
      name_en: 'name_en',
      name_fr: 'name_fr',
      tags   : (layout: ILayout) => layout.broadcast_tags.pluck('id'),
    },
    save  : {
      name_en: 'name_en',
      name_fr: 'name_fr',
      tags   : (layout: ILayout) => layout.broadcast_tags.pluck('id'),
    },
  };
}

export class Layout extends LayoutModel implements ILayout {
  broadcast_tags!: Collection<BroadcastTag>;

  created_at!: DateTime;

  deleted_at!: DateTime;

  formats!: Collection<Format>;

  frames!: Collection<Frame>;

  id!: number;

  name_en!: string;

  name_fr!: string;

  settings!: FormatLayoutSettings;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onCreated(response: Partial<ILayout>): Promise<this> {
    await super.onCreated(response);
    await Promise.allSettled(this.formats?.map(format => format.invalidate()));

    return this;
  }

  async onSaved(response: Partial<ILayout>): Promise<this> {
    await super.onCreated(response);
    await Promise.allSettled(this.formats?.map(format => format.invalidate()));

    return this;
  }

  async onDeleted(response: Partial<ILayout>): Promise<this> {
    await super.onDeleted(response);
    await Promise.allSettled(this.formats?.map(format => format.invalidate()));

    return this;
  }

  /*
   |--------------------------------------------------------------------------
   | Getters
   |--------------------------------------------------------------------------
   */

  getName(i18n: I18n): string {
    return this[`name_${ i18n.language }` as LocalizedName];
  }

  static getTagTypes(): BroadcastTagType[] {
    return [ BroadcastTagType.Category, BroadcastTagType.Targeting, BroadcastTagType.Trigger ];
  }

  /**
   * For the given maximum width and height, calculate the scale factor to apply to the frame to make them fit the bounds side by side
   * @param maxWidth
   * @param maxHeight
   */
  getScaleFactorForBounds(maxWidth: number, maxHeight: number) {
    const totalWidth  = this.frames.reduce((a, b) => a + b.width, 0);
    const totalHeight = this.frames.max('height')!.height;

    // Calculating the scaling necessary.
    // Firstly with the width. If this stills exceeds the maximum height, apply a second step of scaling on the height
    let scaleFactor = maxWidth / totalWidth;

    if (totalHeight * scaleFactor > maxHeight) {
      scaleFactor = scaleFactor * (maxHeight / (totalHeight * scaleFactor));
    }

    return scaleFactor;
  }
}
