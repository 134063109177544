/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PropertyTranslation.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export type Locale = 'fr-CA' | 'en-CA'

interface IPropertyTranslation {
  property_id: number,
  locale: Locale,

  description: string,

  created_at: DateTime
  created_by: number,
  updated_at: DateTime
  updated_by: number,
}

class PropertyTranslationModel extends Model<IPropertyTranslation> {
  _slug = 'property-translation';

  basePath = '/v1/properties/{property_id}/translations';

  attributesTypes: { [attr in keyof IPropertyTranslation]?: (sourceAttr: any) => IPropertyTranslation[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IPropertyTranslation = 'locale';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IPropertyTranslation | string)[] } = {
    create: [],
    save  : [ 'description' ],
  };
}

export class PropertyTranslation extends PropertyTranslationModel implements IPropertyTranslation {

  created_at!: DateTime;

  created_by!: number;

  description!: string;

  locale!: Locale;

  property_id!: number;

  updated_at!: DateTime;

  updated_by!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
