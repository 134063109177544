/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - HeadlineMessage.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { Headline }           from 'models';

export interface IHeadlineMessage {
  id: number,
  headline_id: number,
  locale: string,
  message: string,

  created_at: DateTime,
  updated_at: DateTime,
}

class HeadlineMessageModel extends Model<IHeadlineMessage> {
  _slug = 'headline-message';

  basePath = '/v1/headlines/{headline_id}/messages';

  attributesTypes: { [attr in keyof IHeadlineMessage]?: (sourceAttr: any) => IHeadlineMessage[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IHeadlineMessage = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IHeadlineMessage | string)[] } = {
    create: [],
    save  : [ 'message' ],
  };
}

export class HeadlineMessage extends HeadlineMessageModel implements IHeadlineMessage {
  created_at!: DateTime;

  headline_id!: number;

  id!: number;

  locale!: string;

  message!: string;

  updated_at!: DateTime;

  constructor(attributes?: Partial<IHeadlineMessage>) {
    super();
    this.setAttributes(attributes ?? {});
  }

  async onCreated(response: Partial<IHeadlineMessage>): Promise<this> {
    await super.onCreated(response);
    await (new Headline({ id: this.headline_id })).invalidate();
    return this;
  }

  async onSaved(response: Partial<IHeadlineMessage>): Promise<this> {
    await super.onSaved(response);
    await (new Headline({ id: this.headline_id })).invalidate();
    return this;
  }

  async onDeleted(response: Partial<IHeadlineMessage>): Promise<this> {
    await super.onDeleted(response);
    await (new Headline({ id: this.headline_id })).invalidate();
    return this;
  }
}
