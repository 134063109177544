/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Library.ts
 */

import Collection                                                  from 'library/Collection';
import Request, { CancelTokenHolder, HTTPMethod, QueryParameters } from 'library/Request';
import routes                                                      from 'library/routes';
import { DateTime }                                                from 'luxon';
import { Actor, Advertiser, Content, Format, Layout }              from 'models';
import { Model }                                                   from 'library/Model';
import { preparePathForSingleModelAction, preparePathParameters }  from 'library/Model/utils';
import { makeRoute }                                               from 'library/modelsUtils';
import { makeRequest }                                             from 'library/Request/Request';

export interface ILibrary {
  id: number,
  owner_id: number,
  advertiser_id: number | null,

  name: string,
  content_limit: number,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  owner: Actor,
  advertiser: Advertiser | null,

  contents: Collection<Content>,
  contents_count: number,

  formats: Collection<Format>,

  /**
   * Layouts of formats attached to the library
   */
  layouts: Collection<Layout>,

  /**
   * Layouts of formats attached to the library and of contents in the library.
   * This is used in situation where a format is removed from a library, but there is still content
   * matching it in the library.
   */
  contents_layouts: Collection<Layout>,
}

class LibraryModel extends Model<ILibrary> {
  _slug = 'library';

  basePath = '/v2/libraries';

  attributesTypes: { [attr in keyof ILibrary]?: (sourceAttr: any) => ILibrary[attr] } = {
    created_at      : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at      : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at      : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    owner           : Model.make(Actor),
    advertiser      : Model.make(Advertiser),
    contents        : Collection.ofType(Content).make,
    formats         : Collection.ofType(Format).make,
    layouts         : Collection.ofType(Layout).make,
    contents_layouts: Collection.ofType(Layout).make,
  };

  key: keyof ILibrary = 'id';

  routesAttributes = {
    create: {
      name         : 'name',
      owner_id     : 'owner_id',
      advertiser_id: 'advertiser_id',
      content_limit: 'content_limit',
      formats      : (attr: ILibrary) => attr.formats.pluck('id'),
    },
    save  : {
      name         : 'name',
      owner_id     : 'owner_id',
      advertiser_id: 'advertiser_id',
      content_limit: 'content_limit',
      formats      : (attr: ILibrary) => attr.formats.pluck('id'),
    },
  };
}


export class Library extends LibraryModel implements ILibrary {
  id!: number;

  owner_id!: number;

  advertiser_id!: number | null;

  advertiser!: Advertiser | null;

  name!: string;

  content_limit!: number;

  created_at!: DateTime;

  updated_at!: DateTime;

  deleted_at!: DateTime;

  owner!: Actor;

  contents!: Collection<Content>;

  contents_count!: number;

  formats!: Collection<Format>;

  layouts!: Collection<Layout>;

  contents_layouts!: Collection<Layout>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  /*
   |--------------------------------------------------------------------------
   | Accessors
   |--------------------------------------------------------------------------
   */

  static search(query: string, params: QueryParameters, cancelTokenHolder: CancelTokenHolder = {}) {
    if (query.length < 3) {
      return Promise.resolve(new Collection());
    }

    cancelTokenHolder.token = Request.makeCancelToken();

    return Request.make<ILibrary[]>(routes.libraries.search, null, {
      q: query,
      ...params,
    }, {}, undefined, cancelTokenHolder.token!.token)
                  .then(({ data }) => Collection.ofType(Library).make(data))
                  .catch(() => new Collection());
  }

  moveContents(contents: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/_move');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest(route, params, { contents: contents })
      .then(() => this.invalidate());
  }
}
