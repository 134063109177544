/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PlayerMonitoring.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { Player }             from './Player';
import { AssetType }          from './enums/AssetType';
import { makeRoute }          from '../library/modelsUtils';
import { HTTPMethod }         from '../library/Request';
import { makeRequest }        from '../library/Request/Request';

export interface IPlayerMonitoring {
  id: number,
  player: Player,
  location_name: string,
  assets: {
    id: number,
    name: string,
    type: AssetType | string,
    provider: string,
  }[],
  most_recent_ping: DateTime | null,
  online: boolean,
  most_recent_screenshot: {
    url: string,
    date: DateTime
  }[],
}

class PlayerMonitoringModel extends Model<IPlayerMonitoring> {
  _slug = 'player-monitoring';

  basePath = '/v1/players-monitoring';

  attributesTypes: { [attr in keyof IPlayerMonitoring]?: (sourceAttr: any) => IPlayerMonitoring[attr] } = {
    player                : Model.make(Player),
    most_recent_ping      : (d) => DateTime.fromISO(d),
    most_recent_screenshot: (s) => s.map((s: any) => ({ ...s, date: DateTime.fromISO(s.date) })),
  };

  key: keyof IPlayerMonitoring = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IPlayerMonitoring | string)[] } = {
    create: [],
    save  : [],
  };
}


export class PlayerMonitoring extends PlayerMonitoringModel implements IPlayerMonitoring {
  id!: number;

  player!: Player;

  location_name!: string;

  assets!: {
    id: number,
    name: string,
    type: AssetType | string,
    provider: string,
  }[];

  most_recent_ping!: DateTime | null;

  online!: boolean;

  most_recent_screenshot!: { url: string; date: DateTime<true>; }[];

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  static refresh(playerIds: number[]) {
    const path  = (new PlayerMonitoring()).basePath + '/_refresh';
    const route = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, null, {
      player_ids: playerIds,
    });
  }

  static pullScreenshot(playerIds: number[]) {
    const path  = (new PlayerMonitoring()).basePath + '/_pull_screenshot';
    const route = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, null, {
      player_ids: playerIds,
    });
  }
}
