/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Main.tsx
 */

import classnames from 'classnames';
import useAuth    from 'library/hooks/useAuth';
import Providers  from 'providers';
import React      from 'react';
import { Outlet } from 'react-router-dom';
import Header     from 'scenes/Shell/Header';
import Sidebar    from 'scenes/Shell/Sidebar';
import Loader     from './components/Loader/Loader';

const Main = () => {
  const { isImpersonating } = useAuth();

  return (
    <Providers>
      <Header/>
      <section id="page-wrapper" className={ classnames({ 'space-top': isImpersonating }) }>
        <Sidebar/>
        <React.Suspense fallback={ <Loader/> }>
          {/* The app */ }
          <Outlet/>
        </React.Suspense>
      </section>
    </Providers>
  );
};

export default Main;
