/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - DatasetDatapoint.ts
 */

import { Model }                                    from 'library/Model';
import { ModelPersistAction }                       from 'library/Model/types';
import { DatasetDatapointCategory, DatasetVersion } from 'models';

export interface IDatasetDatapoint {
  id: number,
  dataset_version_id: number,
  code: string,
  dataset_datapoint_category_id: number | null,
  label_en: string,
  label_fr: string,
  type: string,
  reference_datapoint_id: number | null

  category: DatasetDatapointCategory | null
  dataset_version: DatasetVersion
}

class DatasetDatapointModel extends Model<IDatasetDatapoint> {
  _slug = 'dataset-datapoint';

  basePath = '/v1/demographics/datasets-datapoints';

  attributesTypes: { [attr in keyof IDatasetDatapoint]?: (sourceAttr: any) => IDatasetDatapoint[attr] } = {
    dataset_version: Model.make(DatasetVersion),
    category       : Model.make(DatasetDatapointCategory),
  };

  key: keyof IDatasetDatapoint = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IDatasetDatapoint | string)[] } = {
    create: [],
    save  : [ 'label_en', 'label_fr' ],
  };
}

export class DatasetDatapoint extends DatasetDatapointModel implements IDatasetDatapoint {
  id!: number;

  dataset_version_id!: number;

  code!: string;

  label_en!: string;

  label_fr!: string;

  dataset_datapoint_category_id!: number | null;

  reference_datapoint_id!: number | null;

  type!: string;

  dataset_version!: DatasetVersion;

  category!: DatasetDatapointCategory | null;


  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
