/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Auth.ts
 */

import * as Sentry               from '@sentry/react';
import Cookies                   from 'js-cookie';
import { queryClient }           from 'library/Model/QueryClient';
import Request                   from 'library/Request';
import { Actor }                 from 'models';
import { importSPKI, jwtVerify } from 'jose';

const userCookieName = 'neo-user-token';

/**
 * The auth level describes the status of the current user regarding the different authentication steps.
 * There is no step with the value #1 as this level on the API correspond to a user logged in but whose account may be
 * locked. This is not supported on the UI and is thus ignored. A user is either logged in with an active account, or
 * not logged at all.
 */
export enum AuthLevel {
  GUEST = 0,
  LOGIN = 2,
  TWOFA = 3,
  FULL  = 4,
}

/**
 * @class Auth
 * Handles all actions and verifications related to authentication.
 * The class si exposed as a Singleton through its export, and needs to be inited before being used.
 *
 */
class Auth {
  /**
   * Stored the public key used to decrypt JWT tokens.
   */
  #publicKey: string = process.env.CONNECT_JWT_PUBLIC_KEY;

  /**
   * JWT for the current user
   */
  #token: string | null = null;

  /**
   * ID of the current user
   */
  #userID: number | null = null;

  /**
   * LegacyModel representing the current user
   */
  #user: Actor | null = null;

  /**
   * Tell if we are currently impersonating or not
   */
  #isImpersonating: boolean = false;

  /**
   * Object with the impersonator data
   */
  #impersonator: { user: Actor, token: string } | null = null;

  /**
   * Name of the current user
   */
  #userName: string | null = null;

  /**
   * Has the user validated its two-factor auth ?
   */
  #twoFA: boolean = false;

  /**
   * Has the user accepted our terms of use ?
   */
  #tosAccepted: boolean = false;

  /**
   * Give the ID. If the user is not authenticated, returns null
   * @returns The current user's ID
   */
  getUserID = (): number | null => this.#userID;

  /**
   * Give the LegacyModel representing the current user. If the user is not authenticated, returns null
   * @returns The current user
   */
  getUser = (): Actor | null => this.#user;

  /**
   * Tell if the user has successfully completed the two-factor auth. If the user is not authenticated, returns null
   */
  twoFAAccepted = (): boolean => this.#twoFA;

  /**
   * Tell if the user has accepted the TOS. If the user is not authenticated, returns null
   */
  tosAccepted = (): boolean => this.#tosAccepted;

  /**
   * Give the authentication token. If the user is not authenticated, return null
   * @returns The authentication token (JWT) for the current user
   */
  getToken = (): string | null => this.#token;

  /**
   * Returns tthe content of the authentication cookie
   */
  getAuthCookie = (): string | undefined => Cookies.get(userCookieName);

  clearAuthCookie = () => {
    Cookies.remove(userCookieName, { secure: true });
    Cookies.remove(userCookieName, { secure: false });

    // Legacy cookie format
    Cookies.remove(userCookieName, {
      domain: '.' + process.env.CONNECT_PARENT_DOMAIN,
      secure: false,
    });
  };

  /**
   * Initialize the auth object. checking if the current user is already authenticated or not
   * @return {number} The AuthLevel of the current user
   */
  init = async (): Promise<AuthLevel> => {
    // Check if a valid token is available
    const userToken = this.getAuthCookie();

    if (userToken === undefined) {
      return AuthLevel.GUEST;
    }

    return this.setToken(userToken);
  };

  /**
   * Set and store the authentication token. The token is validated before being stored.
   * The corresponding Authentication level is returned after reading the token
   * @param userToken {string} Authentication token to set
   * @returns {number} The AuthLevel of the current user
   */
  setToken = async (userToken: string): Promise<AuthLevel> => {
    // Start by validating token
    // We make sure it is coming from our own auth server and has not been tampered with.
    // We also check the token exp.
    try {
      const publicKey                 = await importSPKI(this.#publicKey, 'RS256');
      const { payload: decodedToken } = await jwtVerify(userToken, publicKey, {
        algorithms    : [ 'RS256' ],
        requiredClaims: [
          'uid', 'name', '2fa', 'tos',
        ],
      });

      // The token is valid.

      // Store its information
      this.#userID      = decodedToken.uid as number;
      this.#userName    = decodedToken.name as string;
      this.#twoFA       = decodedToken['2fa'] as boolean;
      this.#tosAccepted = decodedToken.tos as boolean;

      Sentry.setUser({
        id      : this.#userID,
        username: this.#userName,
        ip      : '{{auto}}',
      });

      // Store the token for future reuse
      this.#token = userToken;

      // And store the token in a cookie
      Cookies.set(userCookieName, userToken, {
        expires: 14,       // 14 days
        secure : true,
      });

      return this.#validateToken();
    } catch (err) {
      console.error('Invalid authentication token', err);
      this.logout(false);
      return AuthLevel.GUEST;
    }
  };

  startImpersonating = async (impersonatingToken: string) => {
    try {
      const publicKey                 = await importSPKI(this.#publicKey, 'RS256');
      const { payload: decodedToken } = await jwtVerify(impersonatingToken, publicKey, {
        algorithms    : [ 'RS256' ],
        requiredClaims: [
          'uid', 'name', '2fa', 'tos', 'imp', 'iid',
        ],
      });

      // This token is for impersonation. It can only be used alongside another basic token
      if (decodedToken.iid !== this.getUserID()) {
        // The two tokens don't match, cancel the process
        return false;
      }

      // We can impersonate using the provided token. Save the current user and token
      this.#isImpersonating = true;
      this.#impersonator    = { user: this.#user!, token: this.#token! };

      // Now store the impersonating token
      this.#userID      = decodedToken.uid as number;
      this.#userName    = decodedToken.name as string;
      this.#twoFA       = decodedToken['2fa'] as boolean;
      this.#tosAccepted = decodedToken.tos as boolean;

      // Store the token for future reuse
      this.#token = impersonatingToken;
      Request.setToken(impersonatingToken);
      Request.setImpersonatorToken(this.#impersonator.token);

      // Clear the request cache has we want all request to be loaded in the impersonated user's context
      queryClient.clear();

      return await this.loadUserData();
    } catch (err) {
      return false;
    }
  };

  isImpersonating() { return this.#isImpersonating; }

  stopImpersonating() {
    if (!this.isImpersonating()) {
      this.#isImpersonating = false;
      this.#impersonator    = null;
      return;
    }

    Request.setToken(this.#impersonator!.token);
    Request.setImpersonatorToken(null);

    this.#userID      = this.#impersonator!.user.id;
    this.#userName    = this.#impersonator!.user.name;
    this.#twoFA       = true;
    this.#tosAccepted = true;

    this.#token = this.#impersonator!.token;
    this.#user  = this.#impersonator!.user;

    this.#isImpersonating = false;
    this.#impersonator    = null;

    // Clear the request cache has we want all request to be loaded in the impersonated user's context
    queryClient.clear();
  }

  /**
   * Check the stored token and determine the current authentication level of the user.
   * This method assumes the token has already been loaded and its products extracted.
   */
  #validateToken = (): AuthLevel => {
    if (!this.#twoFA) {
      // User has not validated its authentication second step
      return AuthLevel.LOGIN;
    }

    if (!this.#tosAccepted) {
      // User has not accepted the Terms of Service
      return AuthLevel.TWOFA;
    }

    // User is good
    return AuthLevel.FULL;
  };


  /**
   * Loads current user Demographic using the UserID stored in its Authentication token
   * @return A promise that resolve to true if the data have been loaded successfully.
   */
  async loadUserData(): Promise<boolean> {
    // Load user details
    try {
      this.#user = await Actor.get(this.#userID!, { with: [ 'capabilities', 'logo' ] });
      return true;
    } catch (e) {
      // Bad Token
      this.logout();
      return false;
    }
  }

  /**
   * Tell if the current user is authenticated and has validated all the required security steps
   */
  isSecured = (): boolean => this.isAuthenticated() && this.twoFAAccepted() && this.tosAccepted();

  /**
   * Logs the user out, removing its token, and redirecting it to the auth portal
   */
  logout = (redirect = true) => {
    // Make sure no cookie is left
    this.clearAuthCookie();

    Sentry.setUser(null);

    if (redirect) {
      // Redirect user to auth interface
      console.warn('Logged out, navigate to homepage');
      window.location.href = '/';
    }
  };

  /**
   * Tell is the current user is authenticated, has a valid auth token.
   *
   * @notice An authenticated user does not mean it has
   * completed the two step auth, only that it has a valid authentication token.
   * @returns {boolean}
   */
  isAuthenticated = (): boolean => this.#token !== null;

  // private:
}

const auth = new Auth();
export default auth;
