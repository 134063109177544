/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Location.ts
 */

import Collection                                       from 'library/Collection';
import routes                                           from 'library/routes';
import { DateTime }                                     from 'luxon';
import { Actor, DisplayType, Network, Player, Product } from 'models';
import { Model }                                        from 'library/Model';
import { ModelPersistAction }                           from 'library/Model/types';
import { QueryParameters }                              from 'library/Request';
import { makeRequest }                                  from 'library/Request/Request';

export interface ILocation {
  id: number,
  network_id: number,
  display_type_id: string,
  name: string,
  container_id: string | null,

  city: string,
  province: string,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  network: Network,
  display_type: DisplayType,
  players: Collection<Player>
  product_ids: number[],
  products: Collection<Product>
  actors: Collection<Actor>
}

class LocationModel extends Model<ILocation> {
  _slug = 'location';

  basePath = '/v2/locations';

  attributesTypes: { [attr in keyof ILocation]?: (sourceAttr: any) => ILocation[attr] } = {
    created_at  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    network     : Model.make(Network),
    display_type: Model.make(DisplayType),
    actors      : Collection.ofType(Actor).make,
    players     : Collection.ofType(Player).make,
    products    : Collection.ofType(Product).make,
  };

  key: keyof ILocation = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ILocation | string)[] } = {
    create: [],
    save  : [ 'name', 'scheduled_sleep', 'sleep_start', 'sleep_end' ],
  };

}

export class Location extends LocationModel implements ILocation {
  actors!: Collection<Actor>;

  container_id!: string | null;

  created_at!: DateTime;

  deleted_at!: DateTime | null;

  display_type_id!: string;

  display_type!: DisplayType;

  id!: number;

  name!: string;

  network!: Network;

  network_id!: number;

  players!: Collection<Player>;

  product_ids!: number[];

  products!: Collection<Product>;

  updated_at!: DateTime;

  city!: string;

  province!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  /*
   |--------------------------------------------------------------------------
   | Getters
   |--------------------------------------------------------------------------
   */

  static search(query: string, params: QueryParameters) {
    if (query.length < 3 && !('actor' in params)) {
      return Promise.resolve(new Collection());
    }

    return makeRequest<ILocation[]>(routes.locations.search, null, {
      q: query,
      ...params,
    }).then(({ data }) => Collection.ofType(Location).make(data)).catch(() => new Collection());
  }
}

export class CampaignLocation extends Location {
  _slug = 'campaign-location';

  basePath = '/v2/campaigns/{campaign_id}/locations';

  pivot!: { format_id: number };

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
