/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ModalWrapper.tsx
 */

import React    from 'react';
import ReactDOM from 'react-dom';

/**
 * @param children
 * @return {React.ReactPortal}
 * @constructor
 */
const ModalWrapper = ({ children }: { children: React.ReactNode }) => {
  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={ e => e.stopPropagation() }>
      { children }
    </div>,
    document.getElementById('modals-wrapper')!,
  );
};

export default ModalWrapper;
