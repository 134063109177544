// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*!
 * Copyright 2022 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Loader.scss
 */.loader{display:flex;width:100%;height:100%;align-items:center;justify-content:center}.loader img{width:100px;-webkit-user-select:none;user-select:none;pointer-events:none;margin-bottom:5rem;mix-blend-mode:darken}.loader.medium img{width:80px}.loader.small img{width:30px}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.scss"],"names":[],"mappings":"AAAA;;;;;;;EAAA,CASA,QACE,YAAA,CACA,UAAA,CACA,WAAA,CAEA,kBAAA,CACA,sBAAA,CAEA,YACE,WAAA,CACA,wBAAA,CAAA,gBAAA,CACA,mBAAA,CAEA,kBAAA,CACA,qBAAA,CAIA,mBACE,UAAA,CAKF,kBACE,UAAA","sourcesContent":["/*!\n * Copyright 2022 (c) Neo-OOH - All Rights Reserved\n * Unauthorized copying of this file, via any medium is strictly prohibited\n * Proprietary and confidential\n * Written by Valentin Dufois <vdufois@neo-ooh.com>\n *\n * @neo/connect - Loader.scss\n */.loader{display:flex;width:100%;height:100%;align-items:center;justify-content:center}.loader img{width:100px;user-select:none;pointer-events:none;margin-bottom:5rem;mix-blend-mode:darken}.loader.medium img{width:80px}.loader.small img{width:30px}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
