/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - settings.ts
 */

const settings = {
        'contents'        : {
          'cardSize': {
            'small' : [ 255, 135 ] as [ number, number ],
            'medium': [ 350, 200 ] as [ number, number ],
          },
        },
        'creatives'       : {
          'supported-types': {
            'image/jpeg': [ '.jpeg', '.jpg' ],
            'image/png' : [ '.png' ],
            'video/mp4' : [ '.mp4', '.mov' ],
          },
        },
        'networks'        : {
          'export-properties': 'networks.export-properties',
        },
        'fields'          : {
          'visualizations': [
            'bar-chart-horizontal',
            'bar-chart-vertical',
            'pie-chart',
            'radial-chart',
            'radar-chart',
          ],
        },
        'models'          : {
          'default-stale-time'  : 0, // 300_000, // 300 Seconds (5 Minutes)
          'auto-refetch-time-ms': 0,
        },
        'campaign-planner': {
          'data-cache-key': [ 'campaign-planner-data' ],
        },
        'file-types'      : {
          'images' : {
            'image/jpeg': [ '.jpeg', '.jpg' ],
            'image/png' : [ '.png' ],
          },
          'tabular': {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [ '.xlsx', '.xls' ],
            'application/vnd.ms-excel'                                         : [ '.xlsx', '.xls' ],
            'text/csv'                                                         : [ '.csv', '.txt' ],
          },
        },
        'schedules'       : {
          'keep-expired-in-campaign-days': 2,
          'all-day-duration-ms'          : 86_340_000,
          'length-validation-delta-sec'  : .1,
        },
        maintenance       : {
          refetchIntervalMs: 10_000,
        },
        dynamics          : {
          weather: {
            dynamicUrl: 'https://dynamics.neo-ooh.info/weather-dynamic',
          },
          news   : {
            dynamicUrl: 'https://dynamics.neo-ooh.info/news-dynamic',
          },
        },
      }
;

export default settings;
