/*
 * Copyright 2022 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PropertyContact.ts
 */

import { Actor } from './index';

export class PropertyContact extends Actor {
  _slug = 'property_contact';

  basePath = '/v1/properties/{contact.property_id}/contacts';

  contact!: {
    property_id: number,
    actor_id: number,
    role: string,
  };

  routesAttributes = {
    create: { 'property_id': 'contact.property_id', 'actor_id': 'id', 'role': 'contact.role' },
    save  : { 'property_id': 'contact.property_id', 'actor_id': 'id', 'role': 'contact.role' },
  };

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
