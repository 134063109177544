/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - TrafficSource.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

interface ITrafficSource {
  id: number,
  type: string,
  name: string,

  created_at: DateTime,
  updated_at: DateTime,

  settings: Record<string, any>

  pivot: { uid: string }
}

class TrafficSourceModel extends Model<ITrafficSource> {
  _slug = 'traffic-source';

  basePath = '/v1/traffic-sources';

  attributesTypes: { [attr in keyof ITrafficSource]?: (sourceAttr: any) => ITrafficSource[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof ITrafficSource = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ITrafficSource | string)[] } = {
    create: [ 'type', 'name', 'api_key' ],
    save  : [ 'type', 'name' ],
  };
}

export class TrafficSource extends TrafficSourceModel implements ITrafficSource {
  created_at!: DateTime;

  id!: number;

  name!: string;

  settings!: Record<string, any>;

  type!: string;

  pivot!: { uid: string };

  updated_at!: DateTime;

  constructor(attributes: Partial<ITrafficSource> = {}) {
    super();
    this.setAttributes(attributes);
  }

}
