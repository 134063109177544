/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - BreadcrumbProvider.tsx
 */

import React from 'react';

export interface Crumb {
  label: string,
  onClick?: () => void,
}

export interface IBreadcrumbContext {
  tokens: Record<string, string>,
  addToken: (key: string, value: string) => void,
  removeToken: (key: string) => void
}

export const BreadcrumbCtx = React.createContext<IBreadcrumbContext>({
  tokens     : {},
  addToken   : () => {},
  removeToken: () => {},
});

const BreadcrumbProvider = ({ children }: { children: React.ReactNode }) => {
  const [ tokens, setTokens ] = React.useState<Record<string, string>>({});

  const addToken = React.useCallback((key: string, value: string) => {
    setTokens(tokens => ({ ...tokens, [key]: value }));
  }, []);

  const removeToken = React.useCallback((key: string) => {
    setTokens(tokens => {
      const updatedTokens = { ...tokens };
      delete updatedTokens[key];

      return updatedTokens;
    });
  }, []);

  const ctx: IBreadcrumbContext = React.useMemo(() => ({
    tokens,
    addToken,
    removeToken,
  }), [ addToken, removeToken, tokens ]);

  return (
    <BreadcrumbCtx.Provider value={ ctx }>
      { children }
    </BreadcrumbCtx.Provider>
  );
};

export default BreadcrumbProvider;
