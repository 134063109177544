/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryResourceSettings.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { InventoryResource }  from './InventoryResource';

export interface IInventoryResourceSettings {
  resource_id: number,
  inventory_id: number,
  is_enabled: boolean,
  pull_enabled: boolean,
  push_enabled: boolean,
  auto_import_products: boolean,
  settings: Record<string, any>

  created_at: DateTime,
  created_by: number,
  updated_at: DateTime,
  updated_by: number,
}

class InventoryResourceSettingsModel extends Model<IInventoryResourceSettings> {
  _slug = 'resource-inventory-settings';

  basePath = '/v1/inventories-resources/{resource_id}/settings';

  attributesTypes: { [attr in keyof IInventoryResourceSettings]?: (sourceAttr: any) => IInventoryResourceSettings[attr] } = {
    created_at: (d) => DateTime.fromISO(d),
    updated_at: (d) => DateTime.fromISO(d),
  };

  key = 'inventory_id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IInventoryResourceSettings)[] } = {
    create: [],
    save  : [ 'is_enabled', 'pull_enabled', 'push_enabled', 'auto_import_products', 'settings' ],
  };

}

export class InventoryResourceSettings extends InventoryResourceSettingsModel implements IInventoryResourceSettings {
  settings!: Record<string, any>;

  created_at!: DateTime;

  created_by!: number;

  inventory_id!: number;

  is_enabled!: boolean;

  pull_enabled!: boolean;

  push_enabled!: boolean;

  auto_import_products!: boolean;

  resource_id!: number;

  updated_at!: DateTime;

  updated_by!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onSaved(response: Partial<IInventoryResourceSettings>): Promise<this> {
    await super.onSaved(response);
    await (new InventoryResource({ id: this.resource_id })).invalidate();
    return this;
  }

  async onDeleted(response: Partial<IInventoryResourceSettings>): Promise<this> {
    await super.onDeleted(response);
    await (new InventoryResource({ id: this.resource_id })).invalidate();
    return this;
  }
}
