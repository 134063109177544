/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - HardwareProvider.ts
 */

import { DateTime }             from 'luxon';
import { Model }                from 'library/Model';
import { ModelPersistAction }   from 'library/Model/types';
import { HardwareProviderType } from './enums/HardwareProviderType';

export interface IHardwareProvider {
  id: number,
  uuid: string,
  provider: HardwareProviderType,
  name: string,
  settings: Record<string, any>,

  created_at: DateTime,
  created_by: number,
  updated_at: DateTime
  updated_by: number,
  deleted_at: DateTime | null
  deleted_by: number | null,

  supported_types: string[],
}

class HardwareProviderModel extends Model<IHardwareProvider> {
  _slug = 'hardware-provider';

  basePath = '/v1/hardware-providers';

  attributesTypes: { [attr in keyof IHardwareProvider]?: (sourceAttr: any) => IHardwareProvider[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IHardwareProvider)[] } = {
    create: [ 'provider', 'name', 'settings' ],
    save  : [ 'name', 'settings' ],
  };
}

export class HardwareProvider extends HardwareProviderModel implements IHardwareProvider {

  id!: number;

  uuid!: string;

  provider!: HardwareProviderType;

  name!: string;

  settings!: Record<string, any>;

  created_at!: DateTime<true>;

  created_by!: number;

  updated_at!: DateTime<true>;

  updated_by!: number;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  supported_types!: string[];

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
