/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - utils.ts
 */

import { Method }                  from 'axios';
import { HTTPMethod, RequestBody } from './Types';

/**
 * Replace uri tokens delimited by braces with the provided params
 * @param uri The uri with tokens
 * @param params The tokens values
 * @returns The uri with its token replace with their values
 * @throws Error Thrown when a value could not be found for a token
 */
export function fillURITokens(uri: string, params: Record<string, any>) {
  // For each token in the uri marked with braces with look for a param with the same name.
  // If no param is found, we throw an error
  return uri.replace(/{([a-z_.]+)}/ig, /**
   * @param {string} match
   */
  (match) => {
    const key   = match.substring(1, match.length - 1);
    const param = params[key];

    if (param !== undefined && param !== null) {
      return param;
    }

    throw new Error(`Missing parameter ${ key } for route ${ uri }`);
  });
}

export function sanitizeBody(body: RequestBody, method: Method) {
  if (body === null) {
    return {};
  }

  if (body instanceof FormData) {
    return body;
  }


  const bodyObject = body ? { ...body } : {};

  // Boolean values in body needs to be cast to 1 or 0 on GET queries
  if (method === HTTPMethod.get) {
    Object.entries(bodyObject)
          .forEach(([ key, value ]) => {
            if (typeof value === 'boolean') {
              bodyObject[key] = value ? 1 : 0;
            }
          });
  }

  return bodyObject;
}

export interface Paging {
  from: number,
  to: number,
  total: number,
  type: string,
  step: number,
  page: number
}

/**
 * Extract components of `content-range` HTTP header to an Object
 * @param contentRange
 */
export function parseContentRangeHeader(contentRange: string): Paging {
  // @ts-ignore
  const matches = contentRange.match(/(^[a-zA-Z][\w]*)\s(\d+)-(\d+)\/(\d+)/) as Array;

  const from  = Number(matches[2]),
        to    = Number(matches[3]),
        total = Number(matches[4]),
        type  = matches[1];

  const step = to - from;

  return {
    from,
    to,
    total,
    type,
    step,
    page: Math.floor(to / step),
  };
}

export function makeEmptyPaging() {
  return {
    from : 0,
    to   : 0,
    total: 0,
    type : 0,
    step : 0,
    page : 0,
  };
}
