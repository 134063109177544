/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - FlatButton.tsx
 */

import React, { AriaRole } from 'react';
import classnames          from 'classnames';
import Loader              from 'react-loaders';
import * as Tooltip        from '@radix-ui/react-tooltip';

import 'loaders.css';
import './FlatButton.scss';

export type FlatButtonStatus =
  'default'
  | 'main'
  | 'secondary'
  | 'highlight'
  | 'light-blue'
  | 'success'
  | 'warning'
  | 'danger'
  | 'headsup'
  | 'purple';

export interface FlatButtonProps {
  children?: React.ReactNode
  icon?: string,

  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,

  className?: string,
  style?: React.CSSProperties

  plain?: boolean
  inverted?: boolean,
  primary?: boolean,
  status?: FlatButtonStatus,
  static?: boolean

  disabled?: boolean
  loading?: boolean

  tooltip?: React.ReactNode

  type?: 'button' | 'submit' | 'reset' | undefined
  role?: AriaRole
}

export const FlatButton = React.forwardRef(function FlatButton({
                                                                 children,
                                                                 icon,
                                                                 onClick,
                                                                 className,
                                                                 style,
                                                                 plain = false,
                                                                 inverted = false,
                                                                 primary = false,
                                                                 status  : aStatus,
                                                                 static  : isStatic = false,
                                                                 disabled: isDisabled = false,
                                                                 loading : isLoading = false,
                                                                 tooltip,
                                                                 type,
                                                                 role = 'button',
                                                               }: FlatButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) {
  const status = aStatus ?? (primary ? 'main' : 'default');

  return (
    <Tooltip.Root>
      <Tooltip.Trigger
        className={ classnames('flat-button',
          className,
          status,
          { inverted },
          { plain },
          { primary },
          { static: isStatic },
          { icon: !!icon },
        ) }
        style={ style }
        onClick={ (isDisabled || isLoading) ? undefined : onClick }
        ref={ ref }
        type={ type }
        role={ role }
        autoFocus={ primary }
        disabled={ isDisabled }>
        { isLoading && <Loader type="ball-pulse" active/> }
        { !isLoading && children }
        { !isLoading && icon && (
          <div className="flat-button__icon" style={ { maskImage: `url(${ icon })`, WebkitMaskImage: `url(${ icon })` } }/>
        ) }
      </Tooltip.Trigger>
      { tooltip && <Tooltip.Portal>
        <Tooltip.Content className="flat-button__tooltip">
          { tooltip }
        </Tooltip.Content>
      </Tooltip.Portal> }
    </Tooltip.Root>
  );
});
