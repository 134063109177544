/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ContractsContext.tsx
 */

import React          from 'react';
import { useUIState } from 'library/hooks';
import { Contract }   from 'models';

interface IContractContext {
  workingContracts: {
    id: number,
    label: string
  }[],
  setWorkingContracts: (contractIds: Contract[], label?: (contract: Contract) => string) => void,
}

export const ContractCtx = React.createContext<IContractContext>({
  workingContracts   : [],
  setWorkingContracts: () => {
  },
});

interface ContractContextProps {
  children: React.ReactNode;
}

const ContractsContext = ({ children }: ContractContextProps) => {
  const [ workingContracts, setWorkingContracts ] = useUIState<{
    id: number,
    label: string
  }[]>('contracts.selected-contracts',
    [],
  );

  const handleSetWorkingContracts = React.useCallback((contracts: Contract[], label?: (contract: Contract) => string) => {
    setWorkingContracts(contracts.map(contract => ({
      id   : contract.getKey(),
      label: label ? label(contract) : contract.contract_id,
    })));
  }, [ setWorkingContracts ]);

  return (
    <ContractCtx.Provider value={ {
      workingContracts,
      setWorkingContracts: handleSetWorkingContracts,
    } }>
      { children }
    </ContractCtx.Provider>
  );
};

export default ContractsContext;
