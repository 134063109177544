/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Capability.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import CapabilitySlug         from 'library/Capability';

export interface ICapability {
  id: number,
  slug: CapabilitySlug,
  service: string,
  default: string,
  standalone: boolean,

  created_at: DateTime,
  updated_at: DateTime,
}

class CapabilityModel extends Model<ICapability> {
  _slug = 'capability';

  basePath = '/v1/capabilities';

  attributesTypes: { [attr in keyof ICapability]?: (sourceAttr: any) => ICapability[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof ICapability = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ICapability | string)[] } = {
    create: [],
    save  : [],
  };
}

export class Capability extends CapabilityModel implements ICapability {
  created_at!: DateTime;

  default!: string;

  id!: number;

  service!: string;

  slug!: CapabilitySlug;

  standalone!: boolean;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
