/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - OpeningHours.ts
 */

import { DateTime, WeekdayNumbers } from 'luxon';
import { Model }                    from 'library/Model';
import { formatTime }               from 'library/utils/dateTime';

interface IOpeningHours {
  id: number,

  /**
   * 1-indexed day of the week
   */
  weekday: WeekdayNumbers,
  is_closed: boolean,
  open_at: DateTime,
  close_at: DateTime,

  created_at: DateTime,
  updated_at: DateTime,
}

class OpeningHoursModel extends Model<IOpeningHours> {
  _slug = 'opening-hours';

  basePath = '/v1/opening-hours';

  attributesTypes: { [attr in keyof IOpeningHours]?: (sourceAttr: any) => IOpeningHours[attr] } = {
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    open_at     : (d) => DateTime.fromFormat(d, 'HH:mm', { zone: 'utc' }),
    close_at    : (d) => DateTime.fromFormat(d, 'HH:mm', { zone: 'utc' }),
  };

  key = 'id' as const;

  routesAttributes = {
    create: {
      weekday  : 'weekday',
      is_closed: 'is_closed',
      open_at  : (attr: IOpeningHours) => formatTime(attr.open_at),
      close_at : (attr: IOpeningHours) => formatTime(attr.close_at),
    },
    save  : {
      is_closed: 'is_closed',
      open_at  : (attr: IOpeningHours) => formatTime(attr.open_at),
      close_at : (attr: IOpeningHours) => formatTime(attr.close_at),
    },
  };
}

export class OpeningHours extends OpeningHoursModel implements IOpeningHours {
  id!: number;

  is_closed!: boolean;

  close_at!: DateTime;

  created_at!: DateTime;

  open_at!: DateTime;

  property_id!: number;

  updated_at!: DateTime;

  weekday!: WeekdayNumbers;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
