/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Screenshot.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { Product }            from './Product';
import { Location }           from './Location';
import { Player }             from './Player';

export interface IScreenshot {
  id: number,
  product_id: number | null,
  location_id: number | null,
  player_id: number | null,
  request_id: number,
  received_at: DateTime,

  url: string,

  product: Product | null
  location: Location | null
  player: Player | null

  pivot: { flight_id: number | null }
}

class ScreenshotModel extends Model<IScreenshot> {
  _slug = 'contract-screenshot';

  basePath = '/v1/screenshots';

  attributesTypes: { [attr in keyof IScreenshot]?: (sourceAttr: any) => IScreenshot[attr] } = {
    received_at: (d) => DateTime.fromISO(d),
    product    : Model.make(Product),
    location   : Model.make(Location),
    player     : Model.make(Player),
  };

  key: keyof IScreenshot = 'id';

  isPaginated = true;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IScreenshot | string)[] } = {
    create: [],
    save  : [],
  };
}

export class Screenshot extends ScreenshotModel implements IScreenshot {
  id!: number;

  location_id!: number | null;

  player_id!: number | null;

  product_id!: number | null;

  received_at!: DateTime;

  request_id!: number;

  product!: Product | null;

  location!: Location | null;

  player!: Player | null;

  url!: string;

  pivot!: { flight_id: number | null };

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
