/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - AuthRouter.tsx
 */

import classnames                  from 'classnames';
import AuthPanel                   from 'providers/AuthProvider/components/AuthPanel';
import React                       from 'react';
import { useTranslation }          from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

const TwoFactorAuth = React.lazy(() => import('./scenes/SecondFactorAuth'));
const ResetPassword = React.lazy(() => import('./scenes/ResetPassword'));
const LostPassword  = React.lazy(() => import('./scenes/LostPassword'));
const ReviewTos     = React.lazy(() => import('./scenes/ReviewTos'));
const Register      = React.lazy(() => import('./scenes/Register'));
const Home          = React.lazy(() => import('./scenes/Home'));

const AuthRouter = () => {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    // Set language based on user's browser local
    // noinspection JSIgnoredPromiseFromCall
    // i18next.changeLanguage(navigator.language.substring(0, 2).toLocaleLowerCase());

    // Set the page title
    window.document.title = 'Connect • Neo-Out of Settings';
  }, []);

  return (
    <section className={ classnames('auth-wrapper', i18n.language) }>
      <AuthPanel>
        <Routes>
          <Route path="/two-factor-auth" element={ <TwoFactorAuth/> }/>
          <Route path="/reset-password" element={ <ResetPassword/> }/>
          <Route path="/lost-password" element={ <LostPassword/> }/>
          <Route path="/review-tos" element={ <ReviewTos/> }/>
          <Route path="/welcome" element={ <Register/> }/>
          <Route path="/" element={ <Home/> }/>
          <Route path="*" element={ <Navigate to="/"/> }/>
        </Routes>
      </AuthPanel>
    </section>
  );
};

export default AuthRouter;
