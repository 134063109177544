/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ParametersProvider.tsx
 */

import React                   from 'react';
import { Parameter, useModel } from 'models';
import Loader                  from 'components/Loader/Loader';
import Collection              from 'library/Collection';

export const ParametersContext = React.createContext<Collection<Parameter>>(new Collection());

export const ParametersProvider = ({ children }: { children: React.ReactNode }) => {
  const { model: parameters, isLoading } = useModel('all', Parameter, {});

  if (isLoading) {
    return <Loader/>;
  }

  return (
    <ParametersContext.Provider value={ parameters }>
      { children }
    </ParametersContext.Provider>
  );
};
