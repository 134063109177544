/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - FlatLink.tsx
 */

import React, { AnchorHTMLAttributes } from 'react';

import './FlatButton.scss';
import { FlatButton, FlatButtonProps } from './FlatButton';
import { Link, To }                    from 'react-router-dom';

interface FlatLinkProps extends Omit<FlatButtonProps, 'onClick'> {
    to: To;
    target?: AnchorHTMLAttributes<any>['target'];
    rel?: AnchorHTMLAttributes<any>['rel'];
    download?: AnchorHTMLAttributes<any>['download'];
}

export const FlatLink = React.forwardRef(function FlatLink(
    { to, target, children, rel, download, ...props }: FlatLinkProps,
    ref: React.ForwardedRef<HTMLAnchorElement>,
) {
    return (
        <Link to={ to }
              target={ target }
              rel={ rel }
              download={ download }
              className="flat-button__link" ref={ ref }>
            <FlatButton { ...props } role="link">
                { children }
            </FlatButton>
        </Link>
    );
});
