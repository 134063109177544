/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ScreenshotRequest.ts
 */

import Collection                                from 'library/Collection';
import { DateTime }                              from 'luxon';
import { Location, Player, Product, Screenshot } from 'models';
import { Model }                                 from 'library/Model';
import { ModelPersistAction }                    from 'library/Model/types';

export interface IScreenshotRequest {
  id: number,

  product_id: number | null,
  location_id: number | null,
  player_id: number | null,

  send_at: DateTime,
  sent: boolean,

  scale_percent: number,
  duration_ms: number,
  frequency_ms: number,

  created_at: DateTime,
  created_by: number | null,
  updated_at: DateTime,
  updated_by: number | null,

  product: Product | null
  location: Location | null
  player: Player | null

  screenshots: Collection<Screenshot>
  screenshots_count: number;
}

class ScreenshotRequestModel extends Model<IScreenshotRequest> {
  _slug = 'screenshots-request';

  basePath = '/v1/screenshots-requests';

  attributesTypes: { [attr in keyof IScreenshotRequest]?: (sourceAttr: any) => IScreenshotRequest[attr] } = {
    send_at    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at : (d) => DateTime.fromISO(d),
    updated_at : (d) => DateTime.fromISO(d),
    product    : Model.make(Product),
    location   : Model.make(Location),
    player     : Model.make(Player),
    screenshots: Collection.ofType(Screenshot).make,
  };

  key: keyof IScreenshotRequest = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IScreenshotRequest | string)[] } = {
    create: [ 'product_id', 'location_id', 'player_id', 'send_at', 'scale_percent', 'duration_ms', 'frequency_ms' ],
    save  : [],
  };

}


export class ScreenshotRequest extends ScreenshotRequestModel implements IScreenshotRequest {
  created_at!: DateTime;

  created_by!: number | null;

  duration_ms!: number;

  frequency_ms!: number;

  id!: number;

  location!: Location | null;

  location_id!: number | null;

  player!: Player | null;

  player_id!: number | null;

  product!: Product | null;

  product_id!: number | null;

  scale_percent!: number;

  screenshots!: Collection<Screenshot>;

  screenshots_count!: number;

  send_at!: DateTime;

  sent!: boolean;

  updated_at!: DateTime;

  updated_by!: number | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
