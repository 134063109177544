/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - DisplayTypeFrame.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IDisplayTypeFrame {
  id: number,
  display_type_id: number,
  name: string,

  pos_x: number,
  pos_y: number,
  width: number,
  height: number,

  created_at: DateTime,
  updated_at: DateTime,
}

class DisplayTypeFrameModel extends Model<IDisplayTypeFrame> {
  _slug = 'display-type-frame';

  basePath = '/v2/display-types/{display_type_id}/frames';

  attributesTypes: { [attr in keyof IDisplayTypeFrame]?: (sourceAttr: any) => IDisplayTypeFrame[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IDisplayTypeFrame = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IDisplayTypeFrame | string)[] } = {
    create: [ 'name', 'pos_x', 'pos_y', 'width', 'height' ],
    save  : [ 'name', 'pos_x', 'pos_y', 'width', 'height' ],
  };
}

export class DisplayTypeFrame extends DisplayTypeFrameModel implements IDisplayTypeFrame {
  display_type_id!: number;

  height!: number;

  pos_x!: number;

  pos_y!: number;

  width!: number;

  created_at!: DateTime;

  id!: number;

  name!: string;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
