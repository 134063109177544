/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Unavailability.ts
 */

import { DateTime }                  from 'luxon';
import { Model }                     from 'library/Model';
import { ModelPersistAction }        from 'library/Model/types';
import Collection                    from 'library/Collection';
import { UnavailabilityTranslation } from './UnavailabilityTranslation';
import { Product }                   from './';

export interface IUnavailability {
  id: number,
  start_date: DateTime | null,
  end_date: DateTime | null,

  created_at: DateTime,
  created_by: number,
  updated_at: DateTime,
  updated_by: number,
  deleted_at: DateTime | null,
  deleted_by: number | null,

  translations: Collection<UnavailabilityTranslation>
  products: Collection<Product>
}

class UnavailabilityModel extends Model<IUnavailability> {
  _slug = 'unavailability';

  basePath = '/v1/unavailabilities';

  attributesTypes: { [attr in keyof IUnavailability]?: (sourceAttr: any) => IUnavailability[attr] } = {
    start_date  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    end_date    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at  : (d) => DateTime.fromISO(d),
    updated_at  : (d) => DateTime.fromISO(d),
    deleted_at  : (d) => DateTime.fromISO(d),
    translations: Collection.ofType(UnavailabilityTranslation).make,
    products    : Collection.ofType(Product).make,
  };

  key = 'id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IUnavailability)[] } = {
    create: [ 'start_date', 'end_date' ],
    save  : [ 'start_date', 'end_date' ],
  };
}

export class Unavailability extends UnavailabilityModel implements IUnavailability {
  created_at!: DateTime;

  created_by!: number;

  deleted_at!: DateTime | null;

  deleted_by!: number | null;

  end_date!: DateTime | null;

  id!: number;

  start_date!: DateTime | null;

  translations!: Collection<UnavailabilityTranslation>;

  updated_at!: DateTime;

  updated_by!: number;

  products!: Collection<Product>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getTranslation(language: string): UnavailabilityTranslation | undefined {
    return this.translations?.findBy('locale', `${ language }-CA`);
  }

  isPassed() {
    if (!this.end_date) {
      return false;
    }

    return this.end_date < DateTime.now();
  }
}
