/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - IndexSetValue.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { IndexSet }           from './IndexSet';
import { DatasetDatapoint }   from './DatasetDatapoint';

export interface IIndexSetValue {
  set_id: number,
  datapoint_id: number,
  primary_value: number,
  reference_value: number,
  index: number,

  /**
   * Only returned when queried from the index_sets_values endpoint
   */
  property_id: number,

  set: IndexSet,
  datapoint: DatasetDatapoint
}

class IndexSetValueModel extends Model<IIndexSetValue> {
  _slug = 'index-set-value';

  basePath = '/v1/demographics/index-sets-values';

  attributesTypes: { [attr in keyof IIndexSetValue]?: (sourceAttr: any) => IIndexSetValue[attr] } = {
    set      : Model.make(IndexSet),
    datapoint: Model.make(DatasetDatapoint),
  };

  key: keyof IIndexSetValue = 'set_id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IIndexSetValue | string)[] } = {
    create: [],
    save  : [],
  };
}

export class IndexSetValue extends IndexSetValueModel implements IIndexSetValue {
  set_id!: number;

  datapoint_id!: number;

  primary_value!: number;

  reference_value!: number;

  index!: number;

  property_id!: number;

  set!: IndexSet;

  datapoint!: DatasetDatapoint;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
