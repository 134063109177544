/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Player.ts
 */

import { DateTime }                from 'luxon';
import { Model }                   from 'library/Model';
import { ModelPersistAction }      from 'library/Model/types';
import { PlayerScreenshotsSource } from './enums/PlayerScreenshotsSource';
import Collection                  from '../library/Collection';
import { Asset }                   from './Asset';

export interface IPlayer {
  id: number,
  network_id: number,
  location_id: number,
  name: string,

  screen_count: number,

  screenshots_source: PlayerScreenshotsSource
  screenshots_source_id: number | null
  dynamics_debug: boolean,

  assets: Collection<Asset>,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,
}

class PlayerModel extends Model<IPlayer> {
  _slug = 'player';

  basePath = '/v2/players';

  attributesTypes: { [attr in keyof IPlayer]?: (sourceAttr: any) => IPlayer[attr] } = {
    assets    : Collection.ofType(Asset).make,
    created_at: (d) => DateTime.fromISO(d),
    updated_at: (d) => DateTime.fromISO(d),
    deleted_at: (d) => DateTime.fromISO(d),
  };

  key: keyof IPlayer = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IPlayer | string)[] } = {
    create: [],
    save  : [ 'dynamics_debug', 'screenshots_source', 'screenshots_source_id' ],
  };
}


export class Player extends PlayerModel implements IPlayer {
  created_at!: DateTime;

  deleted_at!: DateTime | null;

  id!: number;

  name!: string;

  screen_count!: number;

  dynamics_debug!: boolean;

  network_id!: number;

  location_id!: number;

  updated_at!: DateTime;

  assets!: Collection<Asset>;

  screenshots_source!: PlayerScreenshotsSource;

  screenshots_source_id!: number | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
