/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - useModelLegacy.ts
 */

import { QueryParameters }                                                 from 'library/Request';
import { Model as ModelRedux }                                             from 'library/Model';
import Collection                                                          from 'library/Collection';
import API                                                                 from 'library/Model/API';
import { QueryFunctionContext, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import React                                                               from 'react';

type ModelQueryMethods = 'all' | 'get' | 'ids' | 'custom';

interface UseModelCommonOptions extends Omit<UseQueryOptions<any>, 'enabled' | 'queryKey' | 'queryFn'> {
  deffer?: boolean,
}

export interface UseModelAllOptions extends UseModelCommonOptions {
  params?: QueryParameters,
  auth?: boolean,
}

export interface UseModelGetOptions extends UseModelCommonOptions {
  key: string | number,
  params?: QueryParameters,
}

export interface UseModelByIdsOptions extends UseModelCommonOptions {
  keys: (string | number)[],
  params?: QueryParameters,
}

export interface UseModelCustomOptions extends UseModelCommonOptions {
  method: string,
  args?: any[]
}

type UseModelOptions<Method extends ModelQueryMethods> = Method extends 'all' ? UseModelAllOptions :
                                                         Method extends 'get' ? UseModelGetOptions :
                                                         Method extends 'ids' ? UseModelByIdsOptions :
                                                         UseModelCustomOptions;

type UseModelModelType<Method extends ModelQueryMethods, Model extends ModelRedux<{}>> =
  Method extends 'all' ? Collection<Model> :
  Method extends 'get' ? Model :
  Method extends 'ids' ? Collection<Model> :
  Collection<Model> | Model;

interface UseModelReturnType<ModelType> extends Omit<UseQueryResult, 'data'> {
  model: ModelType;
}

/**
 * @param method
 * @param modelType
 * @param rawOptions
 *
 * @deprecated Use `useModel`, `useModels` or `useAllModels` accordingly instead of this one
 */
function useModelLegacy<Method extends ModelQueryMethods, Model extends ModelRedux<any>, ReturnType = UseModelModelType<Method, Model>>(
  method: Method,
  modelType: new (...args: any) => Model,
  rawOptions: UseModelOptions<Method>,
): UseModelReturnType<ReturnType> {
  let pullMethod;
  let key;

  const modelInstance = new modelType({});
  let useQueryOptions: {};

  if (method === 'all') {
    const { params = {}, auth = true, ...qOptions } = rawOptions as UseModelAllOptions;
    useQueryOptions                                 = qOptions;
    key                                             = [ modelInstance._slug, 'all', params ];
    pullMethod                                      = ({ signal }: QueryFunctionContext) => {
      return API.all({
        model : modelType,
        params: params,
        signal: signal,
        auth  : auth,
      });
    };
  } else if (method === 'get') {
    const { key: rawKey, params = {}, ...qOptions } = rawOptions as UseModelGetOptions;
    useQueryOptions                                 = qOptions;
    key                                             = [ modelInstance._slug, rawKey, params ];
    pullMethod                                      = ({ signal }: QueryFunctionContext) => API.get(modelType,
      rawKey,
      params,
      signal,
    );
  } else if (method === 'ids') {
    const { keys: rawKeys, params = {}, ...qOptions } = rawOptions as UseModelByIdsOptions;
    useQueryOptions                                   = qOptions;
    key                                               = [ modelInstance._slug, 'ids', rawKeys, params ];
    pullMethod                                        = ({ signal }: QueryFunctionContext) => API.byId(modelType,
      rawKeys,
      params,
      signal,
    );
  } else /* if(method === 'custom') */ {
    const { method, args = [], ...qOptions } = rawOptions as UseModelCustomOptions;
    useQueryOptions                          = qOptions;
    key                                      = [ modelInstance._slug, method, ...args ];

    // @ts-ignore
    pullMethod = ({ signal }: QueryFunctionContext) => modelType[method](...args, signal);
  }

  const { data, ...queryState } = useQuery({
    queryKey       : key,
    queryFn        : pullMethod,
    enabled        : !rawOptions.deffer,
    placeholderData: rawOptions.placeholderData,
    ...useQueryOptions,
  });

  // It appears that react-query sometimes transform `Collection`s back to `Array`s, therefor, if a query returns an array,
  // we transform it to a Collection automatically
  const formattedData = React.useMemo(() => {
    if (!queryState.isSuccess) {
      return data;
    }

    const models = method === 'all' ? data.models : data;

    if (models instanceof Array && !(models instanceof Collection)) {
      return Collection.from(models);
    }

    return models;
  }, [ data, method, queryState.isSuccess ]);

  return { model: formattedData, ...queryState };
}

export default useModelLegacy;
