/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ExternalInventoryRepresentation.ts
 */

import { DateTime }              from 'luxon';
import { Model }                 from 'library/Model';
import { ModelPersistAction }    from 'library/Model/types';
import { InventoryResource }     from './InventoryResource';
import { InventoryResourceType } from './enums/InventoryResourceType';

export interface ExternalInventoryRepresentationContext {
  // Odoo product
  variant_id?: number,

  // Odoo production product
  production_product_id?: number,

  // Hivestack|Vistar|Place Exchange products networks
  network_id?: string,

  // Hivestack units
  units?: Record<string, { id: string, name: string }>

  // Reach screens
  screens?: Record<string, { id: string, name: string }>

  // Vistar venue ids
  venues?: Record<string, { id: string, name: string }>,

  // Place Exchange asset category ID
  category_id?: number | null,
}

export interface IExternalInventoryRepresentation {
  id: number,
  resource_id: number,
  inventory_id: number,
  type: InventoryResourceType
  external_id: string,
  context: ExternalInventoryRepresentationContext

  created_at: DateTime,
  created_by: number,
  updated_at: DateTime,
  updated_by: number,
  deleted_at: DateTime | null,
  deleted_by: number | null,
}

class ExternalInventoryRepresentationModel extends Model<IExternalInventoryRepresentation> {
  _slug = 'external-inventory-representation';

  basePath = '/v1/inventories-resources/{resource_id}/external-representations';

  attributesTypes: { [attr in keyof IExternalInventoryRepresentation]?: (sourceAttr: any) => IExternalInventoryRepresentation[attr] } = {
    created_at: (d) => DateTime.fromISO(d),
    updated_at: (d) => DateTime.fromISO(d),
    deleted_at: (d) => DateTime.fromISO(d),
  };

  key = 'id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IExternalInventoryRepresentation)[] } = {
    create: [ 'inventory_id', 'type', 'external_id', 'context' ],
    save  : [ 'external_id', 'context' ],
  };

}

export class ExternalInventoryRepresentation extends ExternalInventoryRepresentationModel implements IExternalInventoryRepresentation {
  context!: ExternalInventoryRepresentationContext;

  created_at!: DateTime;

  created_by!: number;

  deleted_at!: DateTime | null;

  deleted_by!: number | null;

  external_id!: string;

  id!: number;

  inventory_id!: number;

  resource_id!: number;

  type!: InventoryResourceType;

  updated_at!: DateTime;

  updated_by!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onSaved(response: Partial<IExternalInventoryRepresentation>): Promise<this> {
    await super.onSaved(response);
    await (new InventoryResource({ id: this.resource_id })).invalidate();
    return this;
  }

  async onDeleted(response: Partial<IExternalInventoryRepresentation>): Promise<this> {
    await super.onDeleted(response);
    await (new InventoryResource({ id: this.resource_id })).invalidate();
    return this;
  }
}
