/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryType.ts
 */

enum BroadcasterType {
  Odoo          = 'odoo',
  Hivestack     = 'hivestack',
  Reach         = 'reach',
  Vistar        = 'vistar',
  PlaceExchange = 'place-exchange',

  Dummy         = 'dummy',
}

export default BroadcasterType;
