/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ActorLogo.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IActorLogo {
  id: number;
  actor_id: number;
  file_path: string;
  original_name: string;
  created_at: DateTime;
  updated_at: DateTime;
}

class ActorLogoModel extends Model<IActorLogo> {
  _slug = 'actor-logo';

  basePath = '/v1/actors/{actor_id}/logo';

  invalidateRoutes = [ '/v1/actors', '/v1/actors/{id}' ];

  attributesTypes: { [attr in keyof IActorLogo]?: (sourceAttr: any) => IActorLogo[attr] } = {
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IActorLogo)[] } = {
    create: [],
    save  : [],
  };
}

export class ActorLogo extends ActorLogoModel implements IActorLogo {
  id!: number;

  actor_id!: number;

  file_path!: string;

  original_name!: string;

  created_at!: DateTime;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
