/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Request.jsx
 */

import axios                           from 'axios';
import React                           from 'react';
import shallowequal                    from 'shallowequal';
import { Error, Placeholder, Success } from './Consumers';
import requestImpl                     from './RequestImpl';

/**
 * Request Component props
 */
// export interface RequestProps {
//   route: Route,
//   with?,
//   body?
// }
//
// export * from './Types';


/**
 * The Request Component takes a route information, loads it, and provides a context and consumers to easily handle
 * the Request state and outcomes
 */
class Request extends React.Component {

  /**
   * Context Consumer: Will only display its children if the Request has failed
   * @type {Error}
   */
  static Error = Error;

  /**
   * Context Consumer : will only display its children when the Request hasn't finished
   * @type {Placeholder}
   */
  static Placeholder = Placeholder;

  /**
   * Context Consumer: Will only display its children when the Request has succeeded
   * @type {Success}
   */
  static Success = Success;

  /**
   * @type {{with: {}, route: null, body: {}}}
   */
  static defaultProps = {
    route: null,
    with : {},
    body : {},
  };

  /**
   * Accessor to the Request implementation init method
   * @method
   * @param baseURL {string}
   * @param token {string | null}
   *
   * @see RequestImpl:RequestImpl.init
   *
   * @return void
   */
  static init = requestImpl.init;

  /**
   * Makes a HTTP Request and returns the response.
   * @param {Route} route route Object describing the endpoint to Request
   * @param {Object} params? Parameters and their value to replace in the route URL
   * @param {Object} data? Demographic to send
   * @param {function(arg: any): void} uploadListener? A callback for upload progress events
   * @returns {Promise<Object|{error:string}>}
   */
  static make = requestImpl.make;

  /**
   * Accessor to the Request implementation setToken method
   * @type {function(token: (string)): void}
   */
  static setToken             = requestImpl.setToken;
  static setImpersonatorToken = requestImpl.setImpersonatorToken;

  static makeCancelToken = () => axios.CancelToken.source();

  /**
   * Accessor to the Request higher order component
   */
  static wrap = (route, Placeholder = null, Error = null) => (WrappedComponent) => {
    return class WithRequestHOC extends React.Component {
      static displayName = `WithRequest(${ WrappedComponent.name })`;

      render() {
        // we parse parameters values
        let params = {};

        if (route.params !== undefined) {
          for (const [ key, value ] of Object.entries(route.params)) {
            if (value === undefined) {
              console.error('Parameter ' + key + ' value is undefined...', this.props.match);
              continue;
            }

            if (typeof value === 'function') {
              params[key] = value();
              continue;
            }

            if (typeof value === 'string' && value.startsWith(':')) {
              params[key] = this.props.match.params[value.substring(1)];
              continue;
            }

            params[key] = value;
          }
        }

        return (
          <Request route={ route.route } with={ params } body={ route.body }>
            { resp => {
              if (resp.loaded && resp.success) {
                return (
                  <Request.Success response={ resp } children={ (response) => (
                    <WrappedComponent response={ response } data={ response.data } { ...(this.props) }/>
                  ) }/>
                );
              }

              if (resp.loaded && !resp.success && Error) {
                return (
                  <Error response={ resp } data={ resp.data } { ...(this.props) }/>
                );
              }

              if (!resp.loaded && Placeholder) {
                return (
                  <Request.Placeholder response={ resp }>
                    { Placeholder }
                  </Request.Placeholder>
                );
              }

              return null;
            } }
          </Request>
        );
      }
    };
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (!shallowequal(prevProps.route, this.props.route) ||
      !shallowequal(prevProps.with, this.props.with) ||
      !shallowequal(prevProps.body, this.props.body)) {
      this.load();
    }
  }

  load() {
    this.setState({
      data    : {},
      loaded  : false,
      progress: 0,
      status  : 0,
      success : false,
    });

    Request.make(this.props.route, this.props.with, this.props.body)
           .then(response => this.setState({
             loaded  : true,
             progress: 1,
             success : true,
             status  : response.status,
             data    : response.data,
           }))
           .catch(error => this.setState({
             loaded  : true,
             progress: 1,
             success : false,
             status  : error.status,
             data    : error,
           }));
  }

  render() {

    if (typeof this.props.children === 'function') {
      return this.props.children(this.state);
    }

    // Pass the response to all children
    return React.Children.map(this.props.children, children => {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, { response: this.state });
      }

      return children;
    });
  }

  state = {
    data    : {},
    loaded  : false,
    progress: 0,
    status  : 0,
    success : false,
  };
}

export const { make: makeRequest } = Request;
export default Request;
