/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Types.ts
 */

import { AxiosError, CancelTokenSource, Method, ResponseType } from 'axios';

export type CancelTokenHolder = {
  token?: CancelTokenSource
}

export type QueryParameters = Record<string, any>

export enum HTTPMethod {
  get    = 'get',
  post   = 'post',
  put    = 'put',
  patch  = 'patch',
  delete = 'delete',
}

/**
 * Describe an endpoint, how it should be queried, and with which parameters.
 */
export interface Route {
  /**
   * URL or URI to a resource that will be queried
   */
  uri: string,

  /**
   * HTTP method to be used when sending the Request. e.g. get, post, delete etc.
   */
  method: 'get' | 'post' | 'put' | 'patch' | 'delete'

  /**
   * Specify if the Request should be made using authentication. If no user is logged in with the `auth` class, a Request requiring authentication will not be executed and an error will be raised.
   */
  auth: boolean,

  /**
   * List of headers to include with the Request.
   */
  headers?: {
    [header: string]: string
  } | null

  /**
   * List of uri that should be invalidated as well when performing a request on this route
   */
  invalidate?: string[] | null

  /**
   * The format of the response that is expected. This is used by the RequestImpl to parse the received response
   */
  responseType?: ResponseType

  /**
   * Tell if the request should be cached, does not apply to POST/PUT/etc. queries.
   * Default to true
   */
  cache?: boolean,

  /**
   * In development mode, used to trigger xdebug
   */
  debug?: boolean
}

export type UploadListener = ((progressEvent: any) => void);

export type RequestBody = Record<string, any> | FormData | null;

export type DefaultResponse = Record<string, any>

export interface RequestErrorResponse<T = Record<string, any>> {
  status: number,
  code: string,
  message: string,
  response?: AxiosError<T>,
  data?: T
  fields?: Record<string, any>
}

export interface BatchRequest {
  id: any,
  uri: string,
  method: Method,
  payload: any
}

export interface BatchResponse<T = Record<string, any>> {
  id: any,
  status: number,
  headers: Record<string, any>,
  response: T
}

/**
 * The Response object is returned by the Request Component. It holds the actual response body as well as relevant
 * information about the Request and its response.
 *
 * @typedef {Object} Response
 * @property {boolean} loaded
 * @property {number} progress
 * @property {boolean} success
 * @property {number|null} status
 * @property {Object} data
 */
