/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Market.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { Polygon }            from '@turf/helpers';

interface IMarket {
  id: number,
  name_en: string,
  name_fr: string,
  province_id: number,
  area: Polygon | null
}

class MarketModel extends Model<IMarket> {
  _slug = 'market';

  basePath = '/v1/markets';

  attributesTypes: { [attr in keyof IMarket]?: (sourceAttr: any) => IMarket[attr] } = {};

  key: keyof IMarket = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IMarket | string)[] } = {
    create: [ 'name_en', 'name_fr', 'province_id', 'area' ],
    save  : [ 'name_en', 'name_fr', 'area' ],
  };
}

export class Market extends MarketModel implements IMarket {
  id!: number;

  name_en!: string;

  name_fr!: string;

  province_id!: number;

  area!: Polygon | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
