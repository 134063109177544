/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - i18n.ts
 */

import locales              from 'assets/locales';
import i18n                 from 'i18next';
import LanguageDetector     from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Initialize i18n
i18n
  // .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV !== 'production',

    fallbackLng  : 'en',
    supportedLngs: [ 'en', 'fr' ],
    resources    : locales,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnNull   : false,

    defaultNS : 'common',
    ns        : [ 'common', 'campaigns' ],
    fallbackNS: 'common',

    react: {
      transSupportBasicHtmlNodes: true,
    },

    parseMissingKeyHandler     : (key, defaultValue) => defaultValue === undefined ? `[${ key }]` : defaultValue,
    appendNamespaceToMissingKey: true,
  });

export type I18n = typeof i18n;

export default i18n;
