/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PointOfInterest.ts
 */

import { Point }              from '@turf/helpers';
import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface ExternalPOI {
  name: string,
  address: string,
  query?: string,
  external_id: string,
  position: Point,
}

export interface IPOI extends Omit<ExternalPOI, 'query'> {
  id: number,
  brand_id: number,

  created_at: DateTime,
  updated_at: DateTime,
}

class POIModel extends Model<IPOI> {
  _slug = 'point-of-interest';

  basePath = '/v1/brands/{brand_id}/pois';

  key = 'id';

  attributesTypes: { [attr in keyof IPOI]?: (sourceAttr: any) => IPOI[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [ 'name', 'address', 'external_id', 'position' ],
    save  : [ 'name', 'address', 'external_id', 'position' ],
  };
}

export class PointOfInterest extends POIModel implements IPOI {
  address!: string;

  brand_id!: number;

  created_at!: DateTime;

  external_id!: string;

  id!: number;

  name!: string;

  position!: Point;

  updated_at!: DateTime;

  constructor(attributes?: Partial<IPOI>) {
    super();
    this.setAttributes(attributes ?? {});
  }
}
