/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryResource.ts
 */

import { Model }                                                  from 'library/Model';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { makeRequest }                                            from 'library/Request/Request';
import Collection                                                 from 'library/Collection';
import { InventoryResourceSettings }                              from './InventoryResourceSettings';
import { ExternalInventoryRepresentation }                        from './ExternalInventoryRepresentation';
import { InventoryConnectionStatus }                              from './enums/InventoryConnectionStatus';
import { InventoryResourceEvent }                                 from './InventoryResourceEvent';
import { InventoryResourceType }                                  from './enums/InventoryResourceType';
import { Product }                                                from './Product';

export interface IInventoryResource {
  id: number,
  type: InventoryResourceType

  inventories_settings: Collection<InventoryResourceSettings>
  external_representations: Collection<ExternalInventoryRepresentation>
  events: Collection<InventoryResourceEvent>

  product: Product
}

class InventoryResourceModel extends Model<IInventoryResource> {
  _slug = 'inventories-resources';

  basePath = '/v1/inventories-resources';

  attributesTypes: { [attr in keyof IInventoryResource]?: (sourceAttr: any) => IInventoryResource[attr] } = {
    inventories_settings    : Collection.ofType(InventoryResourceSettings).make,
    external_representations: Collection.ofType(ExternalInventoryRepresentation).make,
    events                  : Collection.ofType(InventoryResourceEvent).make,
    product                 : Model.make(Product),
  };

  key: keyof IInventoryResource = 'id';

  routesAttributes = {
    create: [],
    save  : [],
  };
}

export class InventoryResource extends InventoryResourceModel implements IInventoryResource {
  id!: number;

  type!: InventoryResourceType;

  events!: Collection<InventoryResourceEvent>;

  inventories_settings!: Collection<InventoryResourceSettings>;

  external_representations!: Collection<ExternalInventoryRepresentation>;

  product!: Product;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getSettings(inventoryId: number) {
    return this.inventories_settings?.findBy('inventory_id', inventoryId);
  }

  getStatus(inventoryId: number) {
    const inventorySettings        = this.getSettings(inventoryId);
    const inventoryRepresentations = this.external_representations
                                         ?.filterBy('deleted_at', '===', null)
                                         ?.filterBy('inventory_id', '===', inventoryId) ??
      new Collection<ExternalInventoryRepresentation>();

    if (!inventorySettings) {
      return inventoryRepresentations.length > 0 ? InventoryConnectionStatus.Associated : InventoryConnectionStatus.NotAssociated;
    }

    if (!inventorySettings.is_enabled) {
      return inventoryRepresentations.length > 0
             ? InventoryConnectionStatus.DisabledAssociated
             : InventoryConnectionStatus.Disabled;
    }

    return inventoryRepresentations.length > 0
           ? InventoryConnectionStatus.EnabledAssociated
           : InventoryConnectionStatus.Enabled;
  }

  performAction(action: 'push' | 'pull' | 'import_product' | 'create' | 'delete', params: Record<string, any>) {
    const path       = preparePathForSingleModelAction(this.basePath, `/_${ action }`);
    const pathParams = preparePathParameters(path, this);
    const route      = makeRoute(path, HTTPMethod.post);

    return makeRequest<{ success: boolean }>(route, pathParams, params);
  }
}
