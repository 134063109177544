/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - City.ts
 */

import { Point }              from '@turf/helpers';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { Market, Province }   from 'models';

export interface ICity {
  id: number,
  name: string,
  province_id: number,
  market_id: number | null
  geolocation: Point | null

  market: Market | null;
  province: Province;
}

class CityModel extends Model<ICity> {
  _slug = 'city';

  basePath = '/v1/cities';

  attributesTypes: { [attr in keyof ICity]?: (sourceAttr: any) => ICity[attr] } = {
    market  : Model.make(Market),
    province: Model.make(Province),
  };

  key: keyof ICity = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ICity | string)[] } = {
    create: [ 'name', 'market_id', 'province_id' ],
    save  : [ 'name', 'market_id', 'province_id' ],
  };
}

export class City extends CityModel implements ICity {
  id!: number;

  name!: string;

  province_id!: number;

  province!: Province;

  market_id!: number | null;

  geolocation!: Point | null;

  market!: Market | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
