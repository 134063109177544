/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Headline.jsx
 */

import classNames         from 'classnames';
import React              from 'react';
import { useTranslation } from 'react-i18next';

const Headline = ({ headline, onClose }) => {
  const { i18n } = useTranslation();

  if (headline.hidden) {
    return null;
  }

  return (
    <div className={ classNames('headline', headline.style) }
         onClick={ () => onClose(headline.id) }>
      <span className="message">{ headline.messages.findBy('locale', i18n.language)?.message }</span>
      <div className="close-btn">✕</div>
    </div>
  );
};

export default Headline;
