/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - queryModels.ts
 */

import { Model as ModelRedux }                                          from './Model';
import { getQueryModelsKey }                                            from './queryKeys';
import { getQueryModelsFn }                                             from './queryFunctions';
import { CommonQueryOptions }                                           from './types';
import { QueryObserverOptions, useQuery, UseQueryResult, WithRequired } from '@tanstack/react-query';
import { queryClient }                                                  from './QueryClient';
import Collection                                                       from '../Collection';

export interface GetModelsOptions extends CommonQueryOptions {
}

function getQueryModelsArguments<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  keys: (number | string)[],
  options: GetModelsOptions = {},
): WithRequired<QueryObserverOptions<Collection<Model> | null>, 'queryKey'> {
  const {
          params    = {},
          relations = [],
          auth      = true,
          ...queryOptions
        }            = options;
  // Assemble params
  const mergedParams = { keys: keys, with: relations, ...params };

  return {
    ...queryOptions as QueryObserverOptions<Collection<Model> | null>,
    queryKey: getQueryModelsKey(modelType, keys, mergedParams),
    queryFn : getQueryModelsFn(modelType, keys, mergedParams, { auth: options.auth }),
  };
}

export function useModels<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  keys: (number | string)[],
  options: GetModelsOptions = {},
): Omit<UseQueryResult, 'data'> & { models: Collection<Model> } {
  const { data, ...queryState } = useQuery(getQueryModelsArguments(modelType, keys, options));
  return { models: data ? Collection.from(data) : new Collection<Model>(), ...queryState };
}

export async function getModels<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  keys: (number | string)[],
  options: GetModelsOptions = {},
) {
  return queryClient.fetchQuery(getQueryModelsArguments(modelType, keys, options));
}
