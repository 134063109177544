/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Format.ts
 */

import Collection                                                 from 'library/Collection';
import { DateTime }                                               from 'luxon';
import {
  BroadcastTag,
  DisplayType,
  FormatCropFrame,
  IDisplayType,
  ILayout,
  ILoopConfiguration,
  Layout,
  LoopConfiguration,
  Network,
}                                                                 from 'models';
import { Model }                                                  from 'library/Model';
import { makeRequest }                                            from 'library/Request/Request';
import { makeRoute }                                              from 'library/modelsUtils';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { HTTPMethod }                                             from 'library/Request';

export interface IFormat {
  id: number,
  slug: string,
  network_id: number,
  name: string,
  content_length: number,
  main_layout_id: number | null,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  network: Network
  layouts: Collection<Layout>
  main_layout: Layout | null,
  display_types: Collection<DisplayType>
  broadcast_tags: Collection<BroadcastTag>
  loop_configurations: Collection<LoopConfiguration>
  crop_frames: Collection<FormatCropFrame>
}

class FormatModel extends Model<IFormat> {
  _slug = 'format';

  basePath = '/v2/formats';

  attributesTypes: { [attr in keyof IFormat]?: (sourceAttr: any) => IFormat[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),

    network            : Model.make(Network),
    layouts            : Collection.ofType(Layout).make,
    main_layout        : Model.make(Layout),
    display_types      : Collection.ofType(DisplayType).make,
    broadcast_tags     : Collection.ofType(BroadcastTag).make,
    loop_configurations: Collection.ofType(LoopConfiguration).make,
    crop_frames        : Collection.ofType(FormatCropFrame).make,
  };

  key: keyof IFormat = 'id';

  routesAttributes = {
    create: {
      network_id    : 'network_id',
      name          : 'name',
      tags          : (attr: IFormat) => attr.broadcast_tags.pluck('id'),
      content_length: 'content_length',
    },
    save  : {
      name          : 'name',
      slug          : 'slug',
      tags          : (attr: IFormat) => attr.broadcast_tags.pluck('id'),
      content_length: 'content_length',
      main_layout_id: 'main_layout_id',
    },
  };

}

export class Format extends FormatModel implements IFormat {
  broadcast_tags!: Collection<BroadcastTag>;

  created_at!: DateTime;

  deleted_at!: DateTime;

  display_types!: Collection<DisplayType>;

  id!: number;

  /**
   * Seconds
   */
  content_length!: number;

  main_layout_id!: number | null;

  layouts!: Collection<Layout>;

  loop_configurations!: Collection<LoopConfiguration>;

  main_layout!: Layout | null;

  name!: string;

  network!: Network;

  network_id!: number;

  updated_at!: DateTime;

  slug!: string;

  crop_frames!: Collection<FormatCropFrame>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  syncDisplayTypes(displayTypesIds: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/display-types/_sync');
    const params = preparePathParameters(path, this);

    return makeRequest<IDisplayType[]>(makeRoute(path, HTTPMethod.put), params, {
      display_types: displayTypesIds,
    }).then(({ data }) => this.display_types = Collection.ofType(DisplayType).make(data));
  }

  syncLayouts(layouts: { layout_id: number, is_fullscreen: boolean }[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/layouts/_sync');
    const params = preparePathParameters(path, this);

    return makeRequest<ILayout[]>(makeRoute(path, HTTPMethod.put), params, {
      layouts: layouts,
    }).then(({ data }) => this.layouts = Collection.ofType(Layout).make(data));
  }

  syncLoopConfigurations(loopConfigurationsIds: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/loop-configurations/_sync');
    const params = preparePathParameters(path, this);

    return makeRequest<ILoopConfiguration[]>(makeRoute(path, HTTPMethod.put), params, {
      loop_configurations: loopConfigurationsIds,
    }).then(({ data }) => {
      this.loop_configurations = Collection.ofType(LoopConfiguration).make(data);
      return this.invalidate();
    });
  }

  duplicate(name: string, networkId: number) {
    const path   = preparePathForSingleModelAction(this.basePath, '/_clone');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest<IFormat>(route, params, {
      name      : name,
      network_id: networkId,
    }).then(async ({ data }) => {
      await this.invalidate();
      return new Format(data);
    });
  }
}
