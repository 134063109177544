/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - TextButton.tsx
 */

import React                            from 'react';
import './TextButton.scss';
import classnames                       from 'classnames';
import { Link, LinkProps, resolvePath } from 'react-router-dom';

interface TextButtonProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  children: React.ReactNode

  disabled?: boolean,

  className?: string,
  style?: React.CSSProperties,
}

export const TextButton = ({ children, onClick, disabled, className, style }: TextButtonProps) => {
  return (
    <div role="button" tabIndex={ 0 }
         className={ classnames('text-button', className, { disabled }) }
         style={ style }
         onClick={ disabled ? undefined : onClick }>
      { children }
    </div>
  );
};

interface TextLinkProps extends LinkProps {
  disabled?: boolean;
}

export const TextLink = ({ disabled, className, children, ...linkProps }: TextLinkProps) => {
  return (
    <Link title={ resolvePath(linkProps.to).pathname.substring(1) }
          { ...linkProps }
          className={ classnames('text-button', { disabled }, className) }>
      { children }
    </Link>
  );
};
