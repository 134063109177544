/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ExtractValue.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { DatasetDatapoint }   from './DatasetDatapoint';

interface IExtractValue {
  extract_id: number,
  datapoint_id: number,
  value: number,

  datapoint: DatasetDatapoint
}

class ExtractValueModel extends Model<IExtractValue> {
  _slug = 'extract-type';

  basePath = '/v1/demographics/extracts-values';

  attributesTypes: { [attr in keyof IExtractValue]?: (sourceAttr: any) => IExtractValue[attr] } = {
    datapoint: Model.make(DatasetDatapoint),
  };

  key: keyof IExtractValue = 'extract_id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IExtractValue | string)[] } = {
    create: [],
    save  : [],
  };
}

export class ExtractValue extends ExtractValueModel implements IExtractValue {
  extract_id!: number;

  datapoint_id!: number;

  value!: number;

  datapoint!: DatasetDatapoint;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
