/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - AreaType.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

interface IAreaType {
  id: number,
  code: string,
}

class AreaTypeModel extends Model<IAreaType> {
  _slug = 'area-type';

  basePath = '/v1/demographics/areas-types';

  attributesTypes: { [attr in keyof IAreaType]?: (sourceAttr: any) => IAreaType[attr] } = {};

  key: keyof IAreaType = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IAreaType | string)[] } = {
    create: [],
    save  : [],
  };
}

export class AreaType extends AreaTypeModel implements IAreaType {
  id!: number;

  code!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
