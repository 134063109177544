/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ResourcePerformance.ts
 */

import { DateTime } from 'luxon';
import { Model }    from 'library/Model';

export interface ResourcePerformanceData {
  network_id: number,
  formats_id: number[],
}

export interface IResourcePerformance {
  resource_id: number,

  recorded_at: DateTime,
  repetitions: number,
  impressions: number,
  data: ResourcePerformanceData,

  created_at: DateTime,
  updated_at: DateTime,
}

class ResourcePerformanceModel extends Model<IResourcePerformance> {
  _slug = 'resource-performance';

  basePath = '/v1/broadcast-resources/{resource_id}/performances';

  attributesTypes: { [attr in keyof IResourcePerformance]?: (sourceAttr: any) => IResourcePerformance[attr] } = {
    recorded_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),

    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'resource_id';

  routesAttributes = {
    create: [],
    save  : [],
  };
}

export class ResourcePerformance extends ResourcePerformanceModel implements IResourcePerformance {
  created_at!: DateTime;

  data!: ResourcePerformanceData;

  impressions!: number;

  recorded_at!: DateTime;

  repetitions!: number;

  resource_id!: number;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}

export class CampaignPerformance extends ResourcePerformance {
  _slug = 'campaign-performance';

  basePath = '/v2/campaigns/{campaign_id}/performances';
}
