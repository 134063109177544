/*
 * Copyright 2020 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * neo-access - NetworkError.js
 */

class NetworkError extends Error {
  constructor(code, message) {
    super(message);

    this.code = code;
  };
}

export default NetworkError;
