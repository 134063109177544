/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - BroadcastJob.ts
 */

import { Model }                                                  from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor }          from 'library/Model/types';
import { DateTime }                                               from 'luxon';
import BroadcastJobType                                           from './enums/BroadcastJobType';
import BroadcastJobStatus                                         from './enums/BroadcastJobStatus';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { makeRequest }                                            from 'library/Request/Request';
import { queryClient }                                            from 'library/Model/QueryClient';
import { BroadcastResource }                                      from './BroadcastResource';
import { Actor }                                                  from './Actor';

export interface IBroadcastJob {
  id: number,
  resource_id: number,
  type: BroadcastJobType,
  created_at: DateTime,
  created_by: number | null,
  scheduled_at: DateTime,
  attempts: number,
  last_attempt_at: DateTime | null,
  status: BroadcastJobStatus,
  payload: object | null,
  last_attempt_result: object | null,
  updated_by: number | null,

  resource: BroadcastResource,
  creator: Actor | null,
  updator: Actor | null,
}

class BroadcastJobModel extends Model<IBroadcastJob> {
  _slug = 'broadcast-job';

  basePath = '/v1/broadcast-jobs';

  attributesTypes: { [attr in keyof IBroadcastJob]?: (sourceAttr: any) => IBroadcastJob[attr] } = {
    created_at     : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    scheduled_at   : (d) => DateTime.fromISO(d),
    last_attempt_at: (d) => DateTime.fromISO(d),
    resource       : Model.make(BroadcastResource),
    creator        : Model.make(Actor),
    updator        : Model.make(Actor),
  };

  key = 'id';

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IBroadcastJob> } = {
    create: [],
    save  : [],
  };
}

export class BroadcastJob extends BroadcastJobModel implements IBroadcastJob {
  attempts!: number;

  created_at!: DateTime;

  created_by!: number | null;

  scheduled_at!: DateTime;

  id!: number;

  last_attempt_at!: DateTime | null;

  payload!: object | null;

  resource_id!: number;

  last_attempt_result!: Record<string, any> | null;

  status!: BroadcastJobStatus;

  type!: BroadcastJobType;

  updated_by!: number | null;

  resource!: BroadcastResource;

  creator!: Actor | null;

  updator!: Actor | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  cancel() {
    const path   = preparePathForSingleModelAction(this.basePath, '/_cancel');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest(route, params)
      .then(response => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({ queryKey: [ (new BroadcastResource())._slug, this.resource_id ] });
        return response;
      });
  }

  retry() {
    const path   = preparePathForSingleModelAction(this.basePath, '/_retry');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest(route, params)
      .then(response => {
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries({ queryKey: [ (new BroadcastResource())._slug, this.resource_id ] });
        return response;
      });
  }

  getSerialized() {
    return {
      ...this,
      created_at     : this.created_at.toISO(),
      scheduled_at   : this.scheduled_at?.toISO(),
      last_attempt_at: this.last_attempt_at?.toISO(),
    };
  }
}
