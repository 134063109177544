/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - TutorialModal.jsx
 */

import { FlatButton }     from 'components/Button';
import Modal              from 'components/Modal';
import React              from 'react';
import { useTranslation } from 'react-i18next';

const TutorialModal = ({ onClose }) => {
  const { i18n, t } = useTranslation('header');

  const videoId = i18n.language === 'en' ? 515422647 : 508531450;

  return (
    <Modal title={ t('tutorial') }>
      <p>
        <iframe src={ `https://player.vimeo.com/video/${ videoId }` }
                width="615"
                height="400"
                title="Tutorial/Tutoriel"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
      </p>
      <div className="row form-actions">
        <FlatButton onClick={ onClose }>
          { t('common:misc.close') }
        </FlatButton>
      </div>
    </Modal>
  );
};

export default TutorialModal;
