/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - MainLogo.tsx
 */

import React    from 'react';
import { Link } from 'react-router-dom';

import './MainLogo.scss';

const MainLogo = () => {
  return (
    <div className="shell-main-logo">
      <Link to="/" className="shell-main-logo__logo"/>
    </div>
  );
};

export default MainLogo;
