/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PropertyMonthlyTrafficDatum.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { QueryParameters }    from 'library/Request';

interface IPropertyMonthlyTrafficDatum {
  property_id: number,
  year: number,

  /**
   * Month of the year, zero indexed
   */
  month: number,

  /**
   * Traffic as provided by the property
   */
  traffic: number | null,

  /**
   * Traffic provided internally
   */
  temporary: number | null,

  /**
   * Traffic value to use depending on what's available. Property_BAK traffic (`traffic`) first, internal value (`temporary`) otherwise.
   */
  final_traffic: number,

  created_at: DateTime,
  updated_at: DateTime
}

class PropertyMonthlyTrafficDatumModel extends Model<IPropertyMonthlyTrafficDatum> {
  _slug = 'monthly-traffic-datum';

  basePath = '/v1/properties/{property_id}/monthly_traffic';

  attributesTypes: { [attr in keyof IPropertyMonthlyTrafficDatum]?: (sourceAttr: any) => IPropertyMonthlyTrafficDatum[attr] } = {
    'created_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
    'updated_at': (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'property_id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IPropertyMonthlyTrafficDatum)[] } = {
    create: [ 'year', 'month', 'traffic', 'temporary' ],
    save  : [],
  };

}

export class PropertyMonthlyTrafficDatum extends PropertyMonthlyTrafficDatumModel implements IPropertyMonthlyTrafficDatum {
  created_at!: DateTime;

  final_traffic!: number;

  month!: number;

  property_id!: number;

  temporary!: number | null;

  traffic!: number | null;

  updated_at!: DateTime;

  year!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async save(additionalParameters: QueryParameters = {}, signal?: AbortSignal): Promise<this> {
    return super.create({
      bodyExtension: additionalParameters,
      signal       : signal,
    });
  }
}
