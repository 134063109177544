/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - DatasetDatapointCategory.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { DatasetVersion }     from 'models';

interface IDatasetDatapointCategory {
  id: number,
  dataset_version_id: number,
  label_en: string,
  label_fr: string,

  dataset_version: DatasetVersion
}

class DatasetDatapointCategoryModel extends Model<IDatasetDatapointCategory> {
  _slug = 'dataset-datapoint-category';

  basePath = '/v1/demographics/dataset-datapoint-category';

  attributesTypes: { [attr in keyof IDatasetDatapointCategory]?: (sourceAttr: any) => IDatasetDatapointCategory[attr] } = {
    dataset_version: Model.make(DatasetVersion),
  };

  key: keyof IDatasetDatapointCategory = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IDatasetDatapointCategory | string)[] } = {
    create: [],
    save  : [ 'label_en', 'label_fr' ],
  };
}

export class DatasetDatapointCategory extends DatasetDatapointCategoryModel implements IDatasetDatapointCategory {
  id!: number;

  dataset_version_id!: number;

  label_en!: string;

  label_fr!: string;

  dataset_version!: DatasetVersion;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
