/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ContractLine.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import ProductType            from './enums/ProductType';
import { Product }            from './Product';
import Collection             from 'library/Collection';
import { Campaign }           from './Campaign';

interface FlightProductPerformanceDatum {
  flight_id: number,
  product_id: number,
  repetitions: number,
  impressions: number,
}

export interface IContractLine {
  product_id: number,
  product_type: ProductType,
  flight_id: number,
  external_id: number,
  spots: number,
  media_value: number,
  discount: number,
  discount_type: 'relative' | 'absolute' | 'cpm'
  price: number,
  traffic: number,
  impressions: number,
  created_at: DateTime,
  updated_at: DateTime,

  product: Product,
  performances: FlightProductPerformanceDatum | null,
  campaigns: Collection<Campaign>
}

class ContractLineModel extends Model<IContractLine> {
  _slug = 'contract-line';

  basePath = '/v1/contracts/{contract_id}/flights/{flight_id}/lines';

  attributesTypes: { [attr in keyof IContractLine]?: (sourceAttr: any) => IContractLine[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    product   : Model.make(Product),
    campaigns : Collection.ofType(Campaign).make,
  };

  key: keyof IContractLine = 'product_id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IContractLine | string)[] } = {
    create: [],
    save  : [],
  };
}

export class ContractLine extends ContractLineModel implements IContractLine {
  created_at!: DateTime;

  discount!: number;

  discount_type!: 'relative' | 'absolute' | 'cpm';

  external_id!: number;

  flight_id!: number;

  impressions!: number;

  media_value!: number;

  price!: number;

  product_id!: number;

  product_type!: ProductType;

  spots!: number;

  traffic!: number;

  updated_at!: DateTime;

  product!: Product;

  performances!: FlightProductPerformanceDatum | null;

  campaigns!: Collection<Campaign>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
