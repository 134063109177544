/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - NewsCategory.ts
 */

import { Model }                                         from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor } from 'library/Model/types';

interface INewsCategory {
  category: string;
}

class NewsCategoryModel extends Model<INewsCategory> {
  _slug: string = 'news-categories';

  basePath: string = '/v1/dynamics/news/categories';

  key: string = 'category';

  attributesTypes: { [attr in keyof INewsCategory]?: (sourceAttr: any) => INewsCategory[attr] } = {};

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<INewsCategory> } = {
    create: {},
    save  : {},
  };
}

export class NewsCategory extends NewsCategoryModel implements INewsCategory {
  category!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
