/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Creative.ts
 */

import Collection                                  from 'library/Collection';
import { DateTime }                                from 'luxon';
import { Actor, Content, ExternalResource, Frame } from 'models';
import { ModelPersistAction }                      from 'library/Model/types';
import { Model }                                   from 'library/Model';

export enum CreativeError {
  BadFileType     = 'creatives.bad-file-format',
  BadDimensions   = 'creatives.bad-dimensions',
  NoMatchingFrame = 'creatives.no-matching-frame',
}

export enum CreativeType {
  Static      = 'static',
  Url         = 'url',
  HtmlPackage = 'html-package'
}

export interface CreativeProperties {
  mime?: string,

  extension?: 'png' | 'jpg' | 'mp4' | 'zip',
  checksum?: string,

  url?: string,
  thumbnail_url?: string,
  refresh_interval_minutes?: number,

  html_entry_point?: string,
}

export interface ICreative {
  id: number,
  type: CreativeType,
  owner_id: number,
  content_id: number,
  frame_id: number,
  original_name: string,
  duration: number,
  properties: CreativeProperties

  file_url: string,
  thumbnail_url: string,

  owner: Actor,
  content: Content,
  frame: Frame,

  external_representations: Collection<ExternalResource>

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

}

class CreativeModel extends Model<ICreative> {
  _slug = 'creative';

  basePath = '/v2/contents/{content_id}/creatives';

  attributesTypes: { [attr in keyof ICreative]?: (sourceAttr: any) => ICreative[attr] } = {
    external_representations: Collection.ofType(ExternalResource).make,
    owner                   : Model.make(Actor),
    content                 : Model.make(Content),
    frame                   : Model.make(Frame),

    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof ICreative = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ICreative | string)[] } = {
    create: [
      'frame_id',
      'type',

      'file',

      'name',
      'url',
      'refresh_interval_minute',
    ],
    save  : [],
  };
}

export class Creative extends CreativeModel implements ICreative {
  content!: Content;

  content_id!: number;

  created_at!: DateTime;

  deleted_at!: DateTime | null;

  duration!: number;

  external_representations!: Collection<ExternalResource>;

  file_url!: string;

  frame!: Frame;

  frame_id!: number;

  id!: number;

  original_name!: string;

  owner!: Actor;

  owner_id!: number;

  properties!: CreativeProperties;

  thumbnail_url!: string;

  type!: CreativeType;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onCreated(response: Partial<ICreative>): Promise<this> {
    await (new Content({ id: this.content_id })).invalidate(true, false);
    return super.onCreated(response);
  }

  async onDeleted(response: Partial<ICreative>): Promise<this> {
    await (new Content({ id: this.content_id })).invalidate(true, false);
    return super.onCreated(response);
  }
}
