/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Headline.ts
 */

import Collection                             from 'library/Collection';
import { DateTime }                           from 'luxon';
import { Actor, Capability, HeadlineMessage } from 'models';
import { Model }                              from 'library/Model';
import { ModelPersistAction }                 from 'library/Model/types';

export interface IHeadline {
  id: number,
  actor_id: number,
  style: string,
  end_date: DateTime,
  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime,

  actor: Actor,
  messages: Collection<HeadlineMessage>
  capabilities: Collection<Capability>
}

class HeadlineModel extends Model<IHeadline> {
  _slug = 'headline';

  basePath = '/v1/headlines';

  attributesTypes: { [attr in keyof IHeadline]?: (sourceAttr: any) => IHeadline[attr] } = {
    actor       : Model.make(Actor),
    messages    : Collection.ofType(HeadlineMessage).make,
    capabilities: Collection.ofType(Capability).make,
    end_date    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at  : (d) => DateTime.fromISO(d),
    updated_at  : (d) => DateTime.fromISO(d),
    deleted_at  : (d) => DateTime.fromISO(d),
  };

  key: keyof IHeadline = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IHeadline | string)[] } = {
    create: [ 'messages', 'style', 'end_date', 'capabilities' ],
    save  : [ 'style', 'end_date' ],
  };
}


export class Headline extends HeadlineModel implements IHeadline {
  actor!: Actor;

  actor_id!: number;

  capabilities!: Collection<Capability>;

  created_at!: DateTime;

  deleted_at!: DateTime;

  end_date!: DateTime;

  id!: number;

  messages!: Collection<HeadlineMessage>;

  style!: string;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
