/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - queryAllModels.ts
 */

import { Model as ModelRedux }                                          from './Model';
import { getQueryAllModelsKey }                                         from './queryKeys';
import { getQueryAllModelsFn }                                          from './queryFunctions';
import { CommonQueryOptions, ResponseHeaders }                          from './types';
import { QueryObserverOptions, useQuery, UseQueryResult, WithRequired } from '@tanstack/react-query';
import { queryClient }                                                  from './QueryClient';
import Collection                                                       from '../Collection';

export interface GetAllModelsOptions extends CommonQueryOptions {
}

export function getQueryAllModelsArguments<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  options: GetAllModelsOptions = {},
): WithRequired<QueryObserverOptions<{ models: Collection<Model> | null, headers: ResponseHeaders }>, 'queryKey'> {
  const {
          params    = {},
          relations = [],
          auth      = true,
          ...queryOptions
        }            = options;
  // Assemble params
  const mergedParams = { ...params, with: relations };

  return {
    ...queryOptions as QueryObserverOptions<{ models: Collection<Model> | null, headers: ResponseHeaders }>,
    queryKey: getQueryAllModelsKey(modelType, mergedParams),
    queryFn : getQueryAllModelsFn(modelType, mergedParams, { auth: options.auth, debug: options.debug }),
  };
}

export function useAllModels<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  options: GetAllModelsOptions = {},
): Omit<UseQueryResult, 'data'> & { models: Collection<Model>, headers: ResponseHeaders } {
  if ((new modelType()).isPaginated) {
    console.warn(`You should use 'useAllModelsPaginated' instead of useAllModels for paginated models (${ modelType.name })`);
  }

  const { data, ...queryState } = useQuery(getQueryAllModelsArguments(modelType, options));
  return {
    models : data?.models ? Collection.from(data.models) : new Collection<Model>(),
    headers: data?.headers ?? {},
    ...queryState,
  };
}

export async function getAllModels<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  options: GetAllModelsOptions = {},
) {
  return queryClient.fetchQuery(getQueryAllModelsArguments(modelType, options));
}