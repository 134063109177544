/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - AssetProperty.ts
 */

export enum AssetProperty {
  /**
   * Manufacturer of the device
   */
  Manufacturer      = 'MANUFACTURER',

  /**
   * Model of the asset
   */
  Model             = 'MODEL',


  /**
   * Serial number of the asset
   */
  SerialNumber      = 'SN',

  /**
   * Firmware type of the asset, for programmable microcontroller, embedded devices, etc.
   */
  Firmware          = 'FIRMWARE',

  /**
   * Version of the firmware on the asset.
   */
  FirmwareVersion   = 'FIRMWARE_VERSION',

  /**
   * Operating system on the device
   */
  Os                = 'OS',

  /**
   * Version of the operating system on the device
   */
  OsVersion         = 'OS_VERSION',

  /**
   * How long the asset has been running
   */
  Uptime            = 'UPTIME',

  /**
   * When was the last time the device pinged home/was alive
   */
  LastPing          = 'LAST_PING',

  /**
   * The temperature of the asset, in Celsius always
   */
  Temperature       = 'TEMPERATURE',

  /**
   * The volume setting
   */
  Volume            = 'VOLUME',

  /**
   * The brightness setting, 0.0 to 1.0
   */
  Brightness        = 'BRIGHTNESS',

  /**
   * Screen resolution in pixels, format
   *
   * {width: int, height: int}
   */
  ScreenResolution  = 'SCREEN_RESOLUTION',

  /**
   * Screen orientation, either PORTRAIT OR LANDSCAPE
   */
  ScreenOrientation = 'SCREEN_ORIENTATION',

  /**
   * Screenshots coming from the asset.
   *
   * {url: string, date: string/ISO-8601}[]
   */
  Screenshots       = 'SCREENSHOTS',

  /**
   * Capabilities available for the resource (Power controls, screenshots, etc.)
   */
  Capabilities      = 'CAPABILITIES',
}
