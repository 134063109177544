/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Content.ts
 */

import Collection                                                                                              from 'library/Collection';
import { DateTime }                                                                                            from 'luxon';
import { Actor, BroadcastTag, Creative, ExternalResource, Format, Layout, Library, Schedule, ScheduleContent } from 'models';
import {
  Model,
}                                                                                                              from 'library/Model';

export interface IContent {
  id: number,
  owner_id: number,
  library_id: number,
  layout_id: number,
  name: string,
  duration: number,

  is_approved: boolean,

  /**
   * Maximum scheduling duration for this content, in days
   */
  max_schedule_duration: number,

  /**
   * Maximum number of times this content can be scheduled
   */
  max_schedule_count: number,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  schedules_count: number,

  owner: Actor,
  layout: Layout,
  schedules: Collection<Schedule>,
  creatives: Collection<Creative>,
  external_representations: Collection<ExternalResource>
  broadcast_tags: Collection<BroadcastTag>
  library: Library

  /**
   * Schedule-specific settings of the content.
   * Available when loaded from a schedule
   */
  schedule_settings: ScheduleContent
}

class ContentModel extends Model<IContent> {
  _slug = 'content';

  basePath = '/v2/contents';

  attributesTypes: { [attr in keyof IContent]?: (sourceAttr: any) => IContent[attr] } = {
    created_at              : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at              : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at              : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    owner                   : Model.make(Actor),
    layout                  : Model.make(Layout),
    library                 : Model.make(Library),
    schedule_settings       : Model.make(ScheduleContent),
    schedules               : Collection.ofType(Schedule).make,
    creatives               : Collection.ofType(Creative).make,
    external_representations: Collection.ofType(ExternalResource).make,
    broadcast_tags          : Collection.ofType(BroadcastTag).make,
  };

  key: keyof IContent = 'id';

  routesAttributes = {
    create: {
      library_id: 'library_id',
      layout_id : 'layout_id',
      name      : 'name',
    },
    save  : {
      owner_id             : 'owner_id',
      library_id           : 'library_id',
      name                 : 'name',
      is_approved          : 'is_approved',
      max_schedule_count   : 'max_schedule_count',
      max_schedule_duration: 'max_schedule_duration',
      tags                 : (attr: IContent) => attr.broadcast_tags.pluck('id'),
    },
  };

}

export class Content extends ContentModel implements IContent {
  broadcast_tags!: Collection<BroadcastTag>;

  created_at!: DateTime;

  creatives!: Collection<Creative>;

  deleted_at!: DateTime;

  duration!: number;

  external_representations!: Collection<ExternalResource>;

  id!: number;

  is_approved!: boolean;

  layout!: Layout;

  layout_id!: number;

  library_id!: number;

  name!: string;

  owner!: Actor;

  owner_id!: number;

  schedules!: Collection<Schedule>;

  max_schedule_duration!: number;

  max_schedule_count!: number;

  updated_at!: DateTime;

  schedules_count!: number;

  library!: Library;

  schedule_settings!: ScheduleContent;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onCreated(response: Partial<IContent>): Promise<this> {
    await super.onCreated(response);
    await (new Library({ id: this.library_id })).invalidate();

    return this;
  }

  async onSaved(response: Partial<IContent>): Promise<this> {
    await super.onCreated(response);
    await (new Library({ id: this.library_id })).invalidate();

    return this;
  }

  async onDeleted(response: Partial<IContent>): Promise<this> {
    await super.onDeleted(response);
    await (new Library({ id: this.library_id })).invalidate();

    return this;
  }

  /*
   |--------------------------------------------------------------------------
   | Getters
   |--------------------------------------------------------------------------
   */
  isLocked = () => {
    return this.schedules.length > 0 || this.max_schedule_duration !== 0 || this.max_schedule_count !== 0;
  };

  canBeScheduled = (layout: Layout) => {
    const schedulesCount = this.schedules_count ?? this.schedules?.length ?? 0;
    return this.creatives.length === layout.frames.length &&
      (this.max_schedule_count !== 0 ? schedulesCount < this.max_schedule_count : true);
  };

  compatibleFormats(formats: Collection<Format>): Collection<Format> {
    return formats.filter(format => (format.layouts.filterBy('id', '===', this.layout_id).length > 0));
  }
}
