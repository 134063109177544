/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Breadcrumb.tsx
 */

import { BreadcrumbCtx }    from 'providers/BreadcrumbProvider/BreadcrumbProvider';
import React                from 'react';
import { useTranslation }   from 'react-i18next';
import './Breadcrumb.scss';
import { Link, useMatches } from 'react-router-dom';
import Collection           from 'library/Collection';
import { setTitle }         from 'library/utils';

const Breadcrumb = () => {
  const { tokens } = React.useContext(BreadcrumbCtx);
  const { t }      = useTranslation('breadcrumb');

  const matches = useMatches();

  const components: { label: string, path: string | null }[] = React.useMemo(() => {
    const match                                                = matches.reverse()[0];
    const components: { label: string, path: string | null }[] = [];

    if (!match) {
      return components;
    }

    const routeComponents = match.pathname.split('/').filter(rc => rc.length > 0);
    const params          = Collection.from(Object.entries(match.params).slice(1));

    let componentContext: string | null = null;
    let path                            = '';
    let i                               = 0;

    for (const routeComponent of routeComponents) {
      i++;
      path += '/' + routeComponent;

      const param = params.findBy(p => p[1], routeComponent);

      if (param === undefined) {
        const labelKey = componentContext ? `${ componentContext }_ctx.${ routeComponent }` : routeComponent;
        components.push({ label: t(labelKey), path: i < routeComponents.length ? path : null });
        componentContext = routeComponent;
        continue;
      }

      components.push({ label: tokens[param[0]] ?? '...', path: i < routeComponents.length ? path : null });
    }

    return components;
  }, [ matches, t, tokens ]);

  //Is their items defined ?
  if (components.length === 0) {
    setTitle('');
    return (
      <nav className="breadcrumb">
        <span className="current" key="home">{ t('neo-connect') }</span>
      </nav>
    );
  }

  setTitle(components.slice(-1)[0].label);

  return (
    <nav className="breadcrumb">
      { components.map((component, i) => {
        if (component.path === null) {
          return <span className="current"
                       key={ `${ i }-${ component.label }` }>
             { component.label }
           </span>;
        }

        return <Link to={ component.path }
                     key={ `${ i }-${ component.label }` }>
          { component.label }
        </Link>;
      }) }
    </nav>
  );
};

export default Breadcrumb;
