/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - BroadcastResource.ts
 */

import Collection                                                 from 'library/Collection';
import { Model }                                                  from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor }          from 'library/Model/types';
import { ExternalResource }                                       from './ExternalResource';
import BroadcastResourceType                                      from './enums/BroadcastResourceType';
import { BroadcastJob }                                           from './BroadcastJob';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { makeRequest }                                            from 'library/Request/Request';
import { DateTime }                                               from 'luxon';

export interface IBroadcastResource {
  id: number,
  type: BroadcastResourceType,

  /** Only if loaded through a job */
  name: string | null,
  /** Only if loaded through a job */
  access_id: number | null,
  /** Only if loaded through a job */
  deleted_at: DateTime | null,

  external_representations: Collection<ExternalResource>
  jobs: Collection<BroadcastJob>,
  resource: Record<string, any>
}

class BroadcastResourceModel extends Model<IBroadcastResource> {
  _slug = 'broadcast-resource';

  basePath = '/v1/broadcast-resources';

  attributesTypes: { [attr in keyof IBroadcastResource]?: (sourceAttr: any) => IBroadcastResource[attr] } = {
    external_representations: Collection.ofType(ExternalResource).make,
    jobs                    : Collection.ofType(BroadcastJob).make,
    deleted_at              : (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'id';

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IBroadcastResource> } = {
    create: [],
    save  : [],
  };
}

export class BroadcastResource extends BroadcastResourceModel implements IBroadcastResource {
  id!: number;

  type!: BroadcastResourceType;

  name!: string | null;

  access_id!: number | null;

  external_representations!: Collection<ExternalResource>;

  jobs!: Collection<BroadcastJob>;

  resource!: Record<string, any>;

  deleted_at!: DateTime | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  promote() {
    const path   = preparePathForSingleModelAction(this.basePath, '/_promote');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params);
  }

  getUrl() {
    if (!this.access_id) {
      return '';
    }

    switch (this.type) {
      case BroadcastResourceType.Campaign:
      case BroadcastResourceType.Schedule:
        return `/broadcast/campaigns/${ this.access_id }`;
      case BroadcastResourceType.Content:
        return `/broadcast/libraries/${ this.access_id }`;
      case BroadcastResourceType.Creative:
        return `/broadcast/contents/${ this.access_id }`;
    }
  }
}
