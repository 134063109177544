/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - FlightType.ts
 */

export const enum FlightType {
  // OOH
  Guaranteed = 'guaranteed',
  Bonus      = 'bonus',
  BUA        = 'bua',

  // MobileOptions
  Mobile     = 'mobile',
}
