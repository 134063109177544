/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Address.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { City }               from 'models';

export interface IAddress {
  id: number,
  line_1: string,
  line_2: string,
  zipcode: string,
  city_id: number,
  city: City
  geolocation: {
    type: 'Point',
    coordinates: [ lng: number, lat: number ]
  } | null
  timezone: string,
}

class AddressModel extends Model<IAddress> {
  _slug = 'address';

  basePath = '/v1/addresses';

  attributesTypes: { [attr in keyof IAddress]?: (sourceAttr: any) => IAddress[attr] } = {
    city: Model.make(City),
  };

  key: keyof IAddress = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IAddress | string)[] } = {
    create: [],
    save  : [],
  };
}

export class Address extends AddressModel implements IAddress {
  id!: number;

  line_1!: string;

  line_2!: string;

  zipcode!: string;

  city_id!: number;

  city!: City;

  geolocation!: {
    type: 'Point',
    coordinates: [ lng: number, lat: number ]
  } | null;

  timezone!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getStringRepresentation() {
    let string = this.line_1;
    if (this.line_2 && this.line_2 !== '') {
      string += this.line_2;
    }

    const zip = this.zipcode.substring(0, 3) + ' ' + this.zipcode.substring(3);

    string += `, ${ this.city.name } ${ this.city.province.slug } ${ zip }`;
    string += `, ${ this.city.province.country.name }`;

    return string;
  }
}
