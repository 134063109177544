/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - useScope.ts
 */

import { ScopeContext } from 'providers/ScopeProvider';
import React            from 'react';

const useScope = (defaultScope: number | null = null, force: boolean = false) => {
  const scope = React.useContext(ScopeContext);

  // We want this method to only be run on first render, even if the provided default scope changes later on
  React.useEffect(() => {
    if (defaultScope !== null && (force || !scope.scope)) {
      scope.set(defaultScope);
    }
    //eslint-disable-next-line
  }, []);

  return scope;
};

export default useScope;
