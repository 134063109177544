/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ScopeProvider.tsx
 */

import React                               from 'react';
import { useQuery }                        from '@tanstack/react-query';
import { makeRoute }                       from 'library/modelsUtils';
import { preparePathForSingleModelAction } from 'library/Model/utils';
import { Actor, IActor }                   from 'models';
import { HTTPMethod }                      from 'library/Request';
import { makeRequest }                     from 'library/Request/Request';
import Collection                          from 'library/Collection';

interface IScopeContext {
  scope: number | null,
  scopeName: string | null,
  set: (scope: number | null, name?: string) => void,
  hierarchy: Collection<Actor>,
  clear: () => void
}

export const ScopeContext = React.createContext<IScopeContext>({
  scope    : null,
  scopeName: null,
  hierarchy: new Collection<Actor>(),
  set      : () => {},
  clear    : () => {},
});

interface ScopeProviderProps {
  children: React.ReactNode,
  defaultScope?: number | null,
  ignoreCookie?: boolean
}

/**
 * The scope specifies filtering of all resources on the website.
 * Having it as a context allows for sharing knowledge of the scope around the entire App, and allows relevant
 * components to change the current scope, and reflect the change everywhere.
 * @return {JSX.Element}
 * @constructor
 */
const ScopeProvider = ({ children, defaultScope = null, ignoreCookie = false }: ScopeProviderProps) => {
  const cookie = React.useMemo(() => {
    const c = localStorage.getItem('neo-user-scope');
    if (!c) {
      return { id: null, name: null };
    }

    try {
      return JSON.parse(c);
    } catch (e) {
      return { id: null, name: null };
    }
  }, []);

  const [ scope, setScope ]         = React.useState(defaultScope || ignoreCookie ? null : cookie?.id);
  const [ scopeName, setScopeName ] = React.useState(cookie?.name || null);

  const handleSetScope = React.useCallback((rawNewScope: number | null, newName: string | null = null) => {
    if (!rawNewScope) {
      setScope(null);
      setScopeName(null);
      return;
    }

    const newScope = Number(rawNewScope);

    if (isNaN(newScope)) {
      return; // Do nothing on bad scope
    }

    setScope(newScope);
    setScopeName(newName);
  }, []);

  const handleClearScope = React.useCallback(() => {
    setScope(null);
    setScopeName(null);
  }, []);


  // Store the scope in a cookie
  React.useEffect(() => {
    if (ignoreCookie) {
      return;
    }

    try {
      localStorage.setItem('neo-user-scope', JSON.stringify({ id: scope, name: scopeName }));
    } catch (e) {
      // Could not store scope. Since the scope is an ease-of-use feature and is absolutely not critical to the website behaviour, we can safely ignore any error.
    }
  }, [ ignoreCookie, scope, scopeName ]);

  // We load the hierarchy of actor for the current scope. This way, it can be used in multiple places
  const { data: hierarchy } = useQuery({
    queryKey: [ 'actor-hierarchy', scope ],
    queryFn : ({ signal }) => {
      const route = makeRoute(preparePathForSingleModelAction((new Actor()).basePath, '/hierarchy'), HTTPMethod.get);
      return makeRequest<IActor[]>(route, { id: scope }, { compact: true }, undefined, undefined, signal)
        .then((response => Collection.ofType(Actor).make(response.data)));
    },
    enabled : scope !== null,
  });

  return (
    <ScopeContext.Provider value={ {
      scope,
      scopeName,
      set      : handleSetScope,
      clear    : handleClearScope,
      hierarchy: hierarchy ?? new Collection(),
    } }>
      { children }
    </ScopeContext.Provider>
  );
};

export default ScopeProvider;
