/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - RequestImpl.ts
 */

import axios, { AxiosError, AxiosInstance, AxiosResponse, CancelToken }                     from 'axios';
import isURL                                                                                from 'validator/lib/isURL';
import { DefaultResponse, HTTPMethod, QueryParameters, RequestBody, Route, UploadListener } from './Types';
import ServiceUnavailableException
                                                                                            from './exceptions/ServiceUnavailableException';
import UnauthorizedRequestException
                                                                                            from './exceptions/UnauthorizedRequestException';
import { fillURITokens, sanitizeBody }                                                      from './utils';
import * as process                                                                         from 'process';

/**
 * The Request class abstract Request mechanism to a distant API.
 */
class RequestImpl {

  //---------------------------------------------
  // Public
  /**
   * Stores the axios instance used to make Request.
   * The axios instance is created when the `init`  method is called
   * @type {null|axios}
   */
  #axios: AxiosInstance = axios.create();

  /**
   * All methods supported when making requests
   * @type {string[]}
   */
  #methods: string[] = [ 'get', 'post', 'put', 'patch', 'delete' ];

  //---------------------------------------------
  // Private
  //---------------------------------------------

  /**
   * Options used when checking if the given uri is valid or not
   * @type {validator.IsURLOptions}
   */
  #uriOptions = {
    require_tld                 : false,
    require_protocol            : false,
    require_host                : false,
    require_valid_protocol      : false,
    allow_underscores           : false,
    allow_trailing_dot          : false,
    allow_protocol_relative_urls: false,
    disallow_auth               : false,
  };

  /**
   * Authentication token to be attached to every request
   * @private
   */
  #authToken: string | null = null;

  #impersonatorToken: string | null = null;

  //---------------------------------------------
  /**
   * Set up the internal axios instance with the provided parameter
   */
  init = () => {
    // Build the axios instance
    this.#axios = axios.create({
      baseURL: process.env.CONNECT_API_URL,
    });

    // Attach our interceptors
    this.#axios.interceptors.response.use(
      this.__onValidResponse_impl,
      this.__onBadResponse_impl,
    );
  };

  setToken = (token: string) => {
    this.#authToken = token;
  };

  setImpersonatorToken = (token: string | null) => {
    this.#impersonatorToken = token;
  };

  #isImpersonating = () => this.#impersonatorToken !== null;

  validateRoute(route: Route) {
    const _route = { ...route };
    // Validate passed parameters
    // URI
    if (!('uri' in _route) || !isURL(_route.uri, this.#uriOptions)) {
      throw new Error('Route URI is either missing or invalid');
    }

    if (!('cache' in _route)) {
      _route.cache = true;
    }

    // Validate route Method
    if (!('method' in _route) || !this.#methods.includes(_route.method)) {
      throw new Error('Route method is either missing or invalid');
    }

    // Validate authentication is setup if required
    if (_route.auth && !this.#authToken) {
      throw new Error('Request require authentication but you don\'t appear to be logged in/no authentication token has been set.' +
        JSON.stringify(_route));
    }

    // Validate all required headers are available
    if (!_route.hasOwnProperty('headers')) {
      _route.headers = {};
    }

    return _route;
  }

  /**
   * Makes a HTTP Request and returns the response.
   * @param route route Object describing the endpoint to Request
   * @param params Parameters and their value to replace in the route URL. Parameter name will match {*} tokens in the route uri.
   * @param rawBody Body of the request. If the request uses the GET method, the body will be serialized and passed in the URL.
   * @param queryParams Parameters to put in the search part of the URI. On Get query, this parameter is ignored in favor of `body
   * @param uploadListener A callback for upload progress events
   * @param cancelToken An Axios CancelToken to allow for early cancellation of the request
   * @returns {Promise<Object|{error:string}>}
   */
  make = <T = DefaultResponse>(
    route: Route,
    params: QueryParameters | null = null,
    rawBody: RequestBody           = null,
    queryParams: QueryParameters   = {},
    uploadListener?: UploadListener,
    cancelToken?: CancelToken | AbortSignal,
  ): Promise<AxiosResponse<T>> => {
    // Make sure Request is initialized
    if (!this.#axios) {
      throw new Error('You need to call Request.init before making any Request.');
    }

    // Prepare everything needed for the request
    const _route = this.validateRoute(route);

    _route.uri = fillURITokens(_route.uri, params ?? {});
    // if (process.env.NODE_ENV !== 'production') {
    //   _route.headers!['XDEBUG_TRIGGER'] = 'start';
    // }

    const body = sanitizeBody(rawBody, _route.method);

    // Route is valid, lets execute the Request
    return this.__make_impl<T>(_route, body, queryParams, uploadListener, cancelToken);
  };

  /**
   * Makes a HTTP Request and returns the response.
   * @param route {{ uri: string, method: string, [auth]: boolean, headers }} Object describing how to
   * use the
   * route
   * @param payload Demographic to send
   * @param uploadListener A callback for upload progress events
   * @param queryParams
   * @param cancelToken
   * @returns {Promise<Object|{error:string}>}
   */
  __make_impl<T = Record<string, any>>(
    route: Route,
    payload: RequestBody,
    queryParams: QueryParameters = {},
    uploadListener?: UploadListener,
    cancelToken?: CancelToken | AbortSignal,
  ): Promise<AxiosResponse<T>> {
    let headers: Record<string, string> = {
      'Accept': 'application/json',
    };

    if (route.auth) {
      headers['Authorization'] = 'Bearer ' + this.#authToken;

      // When impersonating, we need to attach the real user token to the request as well
      if (this.#isImpersonating()) {
        headers['X-Impersonator-Authorization'] = 'Bearer ' + this.#impersonatorToken;
      }
    }

    // Attach all requested headers
    if (route.headers) {
      for (const header in route.headers) {
        headers[header] = route.headers[header];
      }
    }

    const params = route.method === HTTPMethod.get ? payload : queryParams;

    if (route.debug) {
      (params as Record<string, string>).XDEBUG_TRIGGER = 'start';
    }

    return this.#axios(
      route.uri,
      {
        method : route.method,
        headers: headers,
        data   : payload,
        params : params,

        responseType    : route.responseType ?? 'json',
        onUploadProgress: uploadListener ?? undefined,
        cancelToken     : cancelToken instanceof AbortSignal ? undefined : cancelToken,
        signal          : cancelToken instanceof AbortSignal ? cancelToken : undefined,
      },
    ) as Promise<AxiosResponse<T>>;
  }

  /**
   * Handles successful response from the API
   * @param response
   * @returns {*}
   * @private
   */
  __onValidResponse_impl = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  /**
   * Handles bad response from the API. returned errors are parsed and simplified for faster
   * and clearer usage down the line
   * @param error
   * @private
   */
  __onBadResponse_impl = (error: AxiosError<Record<string, any>>) => {

    // Has the request been cancelled ?
    if (axios.isCancel(error)) {
      return Promise.reject('cancelled');
    }

    console.error(error);

    const responseError = error as AxiosError<Record<string, any>>;

    // Did we receive a response from the server ?
    if (!responseError.response) {
      // No, we weren't able to communicate with the server, might be a network problem
      console.error('Could not communicate with server. Make sure the base URL is correct and that you are connected' +
        ' to the Internet');

      // TODO: handle no connection errors
      return Promise.reject({
        status  : null,
        code    : 'unreachable',
        message : 'Could not reach server',
        response: error,
      });
    }

    const response = responseError.response;

    if (response.status === 403) {
      throw new UnauthorizedRequestException();
    }

    if (response.status === 503) {
      throw new ServiceUnavailableException();
    }

    // We received a response, what kind is it ?
    if (response.status >= 500) {
      // We got a server error
      console.error('An error occurred on the server, please try again later');

      return Promise.reject({
        status : response.status,
        code   : 'server-error',
        message: response.data?.message ?? 'An error occurred on the server',
        data   : response.data,
      });
    }

    // Was it an error in our Request ?
    if (response.status >= 400) {
      // We made an error in our Request.
      // Standardized laravel output to our own
      return Promise.reject({
        status : response.status,
        code   : response.data.code,
        message: response.data.message,
        fields : response.data.errors,
      });
    }

    // The error happened during treatment, format is already good
    return Promise.reject(response);
  };
}

// We want this class to be a singleton
// eslint-disable-next-line import/no-anonymous-default-export
export default new RequestImpl();
