/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - UnavailabilityTranslation.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IUnavailabilityTranslation {
  unavailability_id: number,
  locale: string,

  reason: string,
  comment: string,
}

class UnavailabilityTranslationModel extends Model<IUnavailabilityTranslation> {
  _slug = 'unavailability';

  basePath = '/v1/unavailabilities/{unavailability_id}/translations';

  attributesTypes: { [attr in keyof IUnavailabilityTranslation]?: (sourceAttr: any) => IUnavailabilityTranslation[attr] } = {};

  key = 'locale' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IUnavailabilityTranslation)[] } = {
    create: [],
    save  : [ 'reason', 'comment' ],
  };

}

export class UnavailabilityTranslation extends UnavailabilityTranslationModel implements IUnavailabilityTranslation {
  comment!: string;

  locale!: string;

  reason!: string;

  unavailability_id!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
