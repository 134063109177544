/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - index.js
 */

import campaigns       from 'assets/locales/en/campaigns.json';
import actors          from './actors.json';
import assets          from './assets.json';
import auth            from './auth.json';
import breadcrumb      from './breadcrumb.json';
import broadcastTags   from './broadcast-tags.json';
import broadcast       from './broadcast.json';
import bursts          from './bursts.json';
import campaignPlanner from './campaign-planner.json';
import capabilities    from './capabilities.json';
import common          from './common.json';
import contents        from './contents.json';
import contracts       from './contracts.json';
import datasets        from './datasets.json';
import demographics    from './demographics.json';
import dynamics        from './dynamics.json';
import errors          from './errors.json';
import fields          from './fields.json';
import guests          from './guests.json';
import header          from './header.json';
import home            from './home.json';
import inventories     from './inventories.json';
import libraries       from './libraries.json';
import locations       from './locations.json';
import pricelists      from './pricelists.json';
import products        from './products.json';
import properties      from './properties.json';
import review          from './review.json';
import schedules       from './schedules.json';
import settings        from './settings.json';
import widgets         from './widgets.json';

const en = {
  actors,
  assets,
  auth,
  breadcrumb,
  'broadcast-tags'  : broadcastTags,
  broadcast         : broadcast,
  bursts,
  campaigns,
  capabilities,
  'campaign-planner': campaignPlanner,
  common,
  contents,
  datasets,
  demographics,
  dynamics,
  errors,
  fields,
  guests,
  header,
  home,
  inventories,
  libraries,
  locations,
  pricelists,
  products,
  properties,
  contracts,
  review,
  schedules,
  settings,
  widgets,
};

export default en;
