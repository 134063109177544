/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - BroadcasterConnection.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import BroadcasterType        from './enums/BroadcasterType';
import Collection             from 'library/Collection';
import { DisplayType }        from 'models';
import BroadcasterSettings    from './interfaces/BroadcasterSettings';

export interface IBroadcasterConnection {
  id: number,
  uuid: string,
  broadcaster: BroadcasterType,
  name: string,
  active: boolean,
  contracts: boolean,
  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null

  settings: BroadcasterSettings
  display_types: Collection<DisplayType>
}

class BroadCasterConnectionModel extends Model<IBroadcasterConnection> {
  _slug = 'broadcaster-connection';

  basePath = '/v2/broadcasters';

  attributesTypes: { [attr in keyof IBroadcasterConnection]?: (sourceAttr: any) => IBroadcasterConnection[attr] } = {
    created_at   : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at   : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at   : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    display_types: Collection.ofType(DisplayType).make,
  };

  key: keyof IBroadcasterConnection = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IBroadcasterConnection | string)[] } = {
    create: [
      'name',
      'type',
      'contracts',
      'certificate',
      'domain_id',
      'customer_id',
      'server_url',
      'token',
    ],
    save  : [
      'name',
      'contracts',
      'certificate',
      'domain_id',
      'customer_id',
      'server_url',
      'token',
    ],
  };
}

export class BroadcasterConnection extends BroadCasterConnectionModel implements IBroadcasterConnection {
  active!: boolean;

  broadcaster!: BroadcasterType;

  contracts!: boolean;

  created_at!: DateTime;

  deleted_at!: DateTime | null;

  id!: number;

  name!: string;

  settings!: BroadcasterSettings;

  display_types!: Collection<DisplayType>;

  updated_at!: DateTime;

  uuid!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
