/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - utils.ts
 */

import { ModelAttributes, ModelPersistAction } from './types';
import { UnauthorizedError }                   from '../exceptions';
import NetworkError                            from '../NetworkError';
import { RequestErrorResponse }                from '../Request';
import { getProperty }                         from 'dot-prop';

/**
 * This function takes the basepath of a model and add `/{id}` at the end of it if no `id` placeholder is already present in the path.
 * @param path
 * @param followupURI Additional uri to add after the {id}
 */
export function preparePathForSingleModelAction(path: string, followupURI?: string) {
  if (path.includes('{id}')) {
    return path;
  }

  let specificPath = path.replace(/\/+$/g, '/') + '/{id}';

  if (followupURI) {
    if (!followupURI.startsWith('/')) {
      followupURI = '/' + followupURI;
    }

    specificPath += followupURI;
  }

  return specificPath;
}

export function preparePathParameters<Attributes extends ModelAttributes>(
  path: string,
  model: { getKey(): any } & Attributes,
  additionalParameters: Record<string, any> = {},
) {
  // Extract placeholders from the path
  const keys = path.match(/{([a-z_.]+)}/ig)?.map(placeholder => placeholder.substring(1, placeholder.length - 1).trim()) ?? [];

  const params: Record<string, any> = {};
  for (const key of keys) {
    let value = undefined;

    if (key === 'id') {
      value = model.getKey();
    } else {
      value = getProperty(additionalParameters, key) ?? getProperty(model, key);
    }

    params[key] = value;
  }

  return params;
}

export function handleBadRequest(response: RequestErrorResponse | 'cancelled') {
  if (response === 'cancelled') {
    // Request was cancelled, do not throw any error
    return null;
  }

  if (response.status === 422) {
    throw response;
  }

  if (response.status === 403 || response.status === 401) {
    throw new UnauthorizedError(response);
  }

  if (response.status === 400) {
    throw response;
  }

  throw new NetworkError(response.status, response.data?.message);
}

export function preparePersistActionPayload<Attributes extends ModelAttributes>(
  action: ModelPersistAction,
  model: Attributes,
  additionalParameters: Record<string, any> = {},
) {
  const routeAttributes = model.routesAttributes[action];

  if (routeAttributes === undefined) {
    return {};
  }

  const payload = {} as Record<string, any>;

  if (Array.isArray(routeAttributes)) {
    for (const key of routeAttributes) {
      // If the key is already set in the payload, skip it
      if (payload[key as string] === undefined) {
        payload[key as string] = model[key];
      }
    }
  } else { // routeAttributes is not an array
    for (const [ routeAttributeName, routeAttributeRetriever ] of Object.entries(routeAttributes)) {
      if (typeof routeAttributeRetriever === 'string') {
        payload[routeAttributeName] = getProperty(model, routeAttributeRetriever);
      } else if (typeof routeAttributeRetriever === 'function') {
        payload[routeAttributeName] = routeAttributeRetriever(model);
      }
    }
  }

  return { ...payload, ...additionalParameters };
}
