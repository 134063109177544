/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - CampaignsGroup.ts
 */

import Collection   from 'library/Collection';
import { DateTime } from 'luxon';
import { Model }    from 'library/Model';

export interface ICampaignsGroup {
  id: number,
  actor_id: number,
  name: string,

  campaigns: Collection<{ id: number }>

  created_at: DateTime,
  updated_at: DateTime,
}

class CampaignsGroupModel extends Model<ICampaignsGroup> {
  _slug = 'campaign-group';

  basePath = '/v1/campaign-groups';

  attributesTypes: { [attr in keyof ICampaignsGroup]?: (sourceAttr: any) => ICampaignsGroup[attr] } = {
    campaigns: d => Collection.from(d),

    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof ICampaignsGroup = 'id';

  routesAttributes = {
    create: {
      name     : 'name',
      campaigns: (attr: ICampaignsGroup) => attr.campaigns.pluck('id'),
    },
    save  : {
      name     : 'name',
      campaigns: (attr: ICampaignsGroup) => attr.campaigns.pluck('id'),
    },
  };
}

export class CampaignsGroup extends CampaignsGroupModel implements ICampaignsGroup {
  id!: number;

  actor_id!: number;

  name!: string;

  campaigns!: Collection<{ id: number }>;

  created_at!: DateTime;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
