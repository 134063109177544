/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - useUIState.ts
 */

import React          from 'react';
import { isFunction } from '../utils';

/**
 * Takes a key and value, and store it in local storage for easy access from different part of the interface.
 * This is intended for user convenience (conserving selected tab between resource, etc.) and should not be used for
 * any business-related features
 * @param stateName
 * @param defaultValue
 */
export function useUIState<T>(stateName: string, defaultValue: T | (() => T)): [ T, (newValue: T) => void ] {
  const [ value, setValue ] = React.useState<T>(() => isFunction(defaultValue) ? defaultValue() : defaultValue);

  React.useMemo(() => {
    try {
      const item = localStorage.getItem(`connect-ui-state-${ stateName }`);

      if (!item) {
        return;
      }

      setValue(JSON.parse(item));
    } catch (e) {
      console.error('Could not load UI State', e);
      setValue(isFunction(defaultValue) ? defaultValue() : defaultValue);
    }
    // Ignore error because we don't want defaultValue in here
    // eslint-disable-next-line
  }, [ stateName ]);

  const update = React.useCallback((newValue: T) => {
    setValue(newValue);

    try {
      if (newValue === undefined) {
        localStorage.removeItem(`connect-ui-state-${ stateName }`);
      } else {
        localStorage.setItem(`connect-ui-state-${ stateName }`, JSON.stringify(newValue));
      }
    } catch (e) {
      console.error('Could not save UI State', e);
    }
  }, [ stateName ]);

  return [ value, update ];
}
