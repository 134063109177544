/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ExtractTemplate.ts
 */

import { Model }                             from 'library/Model';
import { ModelPersistAction }                from 'library/Model/types';
import { DateTime }                          from 'luxon';
import { DatasetVersion }                    from './DatasetVersion';
import Collection                            from 'library/Collection';
import { Extract, GeographicReportTemplate } from './index';

interface IExtractTemplate {
  id: number,
  name: string,
  description: string,
  dataset_version_id: number,
  geographic_report_template_id: number
  bounding_geographic_report_template_id: number | null

  created_at: DateTime,
  created_by: number | null,
  updated_at: DateTime,
  updated_by: number | null,
  deleted_at: DateTime | null,
  deleted_by: number | null,

  dataset_version: DatasetVersion,
  geographic_report_template: GeographicReportTemplate,
  bounding_geographic_report_template: GeographicReportTemplate | null,
  extracts: Collection<Extract>,
}

class ExtractTemplateModel extends Model<IExtractTemplate> {
  _slug = 'extractTemplate-type';

  basePath = '/v1/demographics/extracts-templates';

  attributesTypes: { [attr in keyof IExtractTemplate]?: (sourceAttr: any) => IExtractTemplate[attr] } = {
    dataset_version                    : Model.make(DatasetVersion),
    geographic_report_template         : Model.make(GeographicReportTemplate),
    bounding_geographic_report_template: Model.make(GeographicReportTemplate),
  };

  key: keyof IExtractTemplate = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IExtractTemplate | string)[] } = {
    create: [
      'name',
      'description',
      'dataset_version_id',
      'geographic_report_template_id',
      'bounding_geographic_report_template_id',
    ],
    save  : [ 'name', 'description' ],
  };
}

export class ExtractTemplate extends ExtractTemplateModel implements IExtractTemplate {
  id!: number;

  name!: string;

  description!: string;

  dataset_version_id!: number;

  geographic_report_template_id!: number;

  bounding_geographic_report_template_id!: number | null;

  created_at!: DateTime<true>;

  created_by!: number | null;

  updated_at!: DateTime<true>;

  updated_by!: number | null;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  dataset_version!: DatasetVersion;

  geographic_report_template!: GeographicReportTemplate;

  bounding_geographic_report_template!: GeographicReportTemplate | null;

  extracts!: Collection<any>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
