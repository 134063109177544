/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ScheduleReview.ts
 */

import { DateTime } from 'luxon';
import { Model }    from 'library/Model';
import { Actor }    from './Actor';

interface IScheduleReview {
  id: number,
  schedule_id: number,
  reviewer_id: number,
  approved: boolean,
  message: string,

  created_at: DateTime,
  updated_at: DateTime,

  reviewer: Actor,
}

class ScheduleReviewModel extends Model<IScheduleReview> {
  _slug = 'schedule-review';

  basePath = '/v2/schedules/{schedule_id}/reviews';

  attributesTypes: { [attr in keyof IScheduleReview]?: (sourceAttr: any) => IScheduleReview[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    reviewer  : Model.make(Actor),
  };

  key: keyof IScheduleReview = 'id';

  routesAttributes = {
    create: [
      'approved',
      'message',
    ],
    save  : [],
  };
}

export class ScheduleReview extends ScheduleReviewModel implements IScheduleReview {
  approved!: boolean;

  created_at!: DateTime;

  id!: number;

  message!: string;

  schedule_id!: number;

  reviewer_id!: number;

  updated_at!: DateTime;

  reviewer!: Actor;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

}
