/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ScheduleContent.ts
 */

import Collection                     from 'library/Collection';
import { DateTime }                   from 'luxon';
import { CampaignSchedule, Schedule } from 'models';
import { Model }                      from 'library/Model';

export interface IScheduleContent {
  id: number,
  schedule_id: number,
  content_id: number,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  disabled_formats_ids: Collection<{ schedule_content_id: number, format_id: number }>
}

class ContentModel extends Model<IScheduleContent> {
  _slug = 'schedule-content';

  basePath = '/v2/schedules/{schedule_id}/contents';

  attributesTypes: { [attr in keyof IScheduleContent]?: (sourceAttr: any) => IScheduleContent[attr] } = {
    created_at          : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at          : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at          : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    disabled_formats_ids: (d) => Collection.from(d),
  };

  key: keyof IScheduleContent = 'id';

  routesAttributes = {
    create: {
      'content_id': 'content_id',
    },
    save  : {
      'disabled_formats_ids': (attr: IScheduleContent) => attr.disabled_formats_ids.pluck('format_id'),
    },
  };

}

export class ScheduleContent extends ContentModel implements IScheduleContent {
  created_at!: DateTime;

  deleted_at!: DateTime;

  id!: number;

  schedule_id!: number;

  content_id!: number;

  updated_at!: DateTime;

  disabled_formats_ids!: Collection<{ schedule_content_id: number; format_id: number; }>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async onCreated(response: Partial<IScheduleContent>): Promise<this> {
    await super.onCreated(response);

    await Promise.allSettled([
      (new Schedule({ id: this.schedule_id })).invalidate(),
      (new CampaignSchedule()).invalidateAll(),
    ]);

    return this;
  }

  async onSaved(response: Partial<IScheduleContent>): Promise<this> {
    await super.onCreated(response);

    await Promise.allSettled([
      (new Schedule({ id: this.schedule_id })).invalidate(),
      (new CampaignSchedule()).invalidateAll(),
    ]);

    return this;
  }

  async onDeleted(response: Partial<IScheduleContent>): Promise<this> {
    await super.onDeleted(response);

    await Promise.allSettled([
      (new Schedule({ id: this.schedule_id })).invalidate(),
      (new CampaignSchedule()).invalidateAll(),
    ]);

    return this;
  }

  /*
   |--------------------------------------------------------------------------
   | Actions
   |--------------------------------------------------------------------------
   */

  async syncDisabledFormats(disabledFormats: Collection<number>) {
    this.disabled_formats_ids = disabledFormats.map(formatId => ({
      schedule_content_id: this.getKey(),
      format_id          : formatId,
    }));

    await this.save();
  }
}
