/*
 * Copyright 2022 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ScheduleStatus.ts
 */

enum ScheduleStatus {
  Draft    = 'draft',
  Pending  = 'pending',
  Approved = 'approved',
  Live     = 'live',
  Rejected = 'rejected',
  Expired  = 'expired',
  Trashed  = 'trashed',
}

export default ScheduleStatus;
