/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - AuthContext.ts
 */

import React         from 'react';
import { Actor }     from 'models';
import { AuthLevel } from 'library/Auth';

export interface IAuthCtx {
  authLevel: number,
  userId: number,
  user: Actor,
  startImpersonating: (actorId: number) => void,
  stopImpersonating: () => void,
  isImpersonating: boolean,
}

export const AuthContext = React.createContext<IAuthCtx>({
  authLevel         : AuthLevel.GUEST,
  userId            : 0,
  user              : new Actor(),
  startImpersonating: () => {},
  stopImpersonating : () => {},
  isImpersonating   : false,
});
