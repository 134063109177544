/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PricelistProduct.ts
 */

import { Model }              from 'library/Model';
import { DateTime }           from 'luxon';
import { ModelPersistAction } from 'library/Model/types';
import { PricingType }        from './enums/PricingType';
import { Pricelist }          from 'models';

interface IPricelistProduct {
  pricelist_id: number,
  product_id: number,
  pricing: PricingType,
  value: number,
  min: number,
  max: number,

  created_at: DateTime,
  updated_at: DateTime,
}

class PricelistProductModel extends Model<IPricelistProduct> {
  _slug = 'pricelist-product';

  basePath = '/v1/pricelists/{pricelist_id}/products';

  key = 'product_id';

  attributesTypes: { [attr in keyof IPricelistProduct]?: (sourceAttr: any) => IPricelistProduct[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [ 'product_id', 'pricing', 'value', 'min', 'max' ],
    save  : [ 'pricing', 'value', 'min', 'max' ],
  };
}

export class PricelistProduct extends PricelistProductModel implements IPricelistProduct {
  created_at!: DateTime;

  max!: number;

  min!: number;

  pricelist_id!: number;

  pricing!: PricingType;

  product_id!: number;

  updated_at!: DateTime;

  value!: number;

  constructor(attributes?: Partial<IPricelistProduct>) {
    super();
    this.setAttributes(attributes ?? {});
  }

  async onCreated(response: Partial<IPricelistProduct>): Promise<this> {
    await super.onCreated(response);
    await Promise.allSettled([
      (new Pricelist({ id: this.pricelist_id })).invalidate(),
      (new Pricelist()).invalidateAll(),
    ]);

    return this;
  }

  async onSaved(response: Partial<IPricelistProduct>): Promise<this> {
    await super.onSaved(response);
    await Promise.allSettled([
      (new Pricelist({ id: this.pricelist_id })).invalidate(),
      (new Pricelist()).invalidateAll(),
    ]);

    return this;
  }

  async onDeleted(response: Partial<IPricelistProduct>): Promise<this> {
    await super.onDeleted(response);
    await Promise.allSettled([
      (new Pricelist({ id: this.pricelist_id })).invalidate(),
      (new Pricelist()).invalidateAll(),
    ]);

    return this;
  }
}
