/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Search.ts
 */

import { Model } from 'library/Model';

export interface ISearchResult {
  id: number,
  parent_id: number | null,
  type: string,
  subtype: string,
  label: string,

  model: any,
}

class SearchModel extends Model<ISearchResult> {
  _slug = 'search';

  basePath = '/v1/search';

  attributesTypes: { [attr in keyof ISearchResult]?: (sourceAttr: any) => ISearchResult[attr] } = {};

  key: keyof ISearchResult = 'id';

  routesAttributes = {
    create: {},
    save  : {},
  };
}


export class Search extends SearchModel implements ISearchResult {
  id!: number;

  parent_id!: number | null;

  type!: string;

  subtype!: string;

  label!: string;

  model!: any;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
