/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Phone.ts
 */

import { DateTime }           from 'luxon';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';

export interface IPhone {
  id: number,
  number_country: string,
  number: string,

  created_at: DateTime,
  updated_at: DateTime,

  formatted_number: string,
}

class PhoneModel extends Model<IPhone> {
  _slug = 'phone';

  basePath = '/v1/phones';

  attributesTypes: { [attr in keyof IPhone]?: (sourceAttr: any) => IPhone[attr] } = {
    created_at: (d) => DateTime.fromISO(d),
    updated_at: (d) => DateTime.fromISO(d),
  };

  key = 'id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IPhone)[] } = {
    create: [],
    save  : [],
  };

}

export class Phone extends PhoneModel implements IPhone {
  created_at!: DateTime;

  formatted_number!: string;

  id!: number;

  number!: string;

  number_country!: string;

  updated_at!: DateTime;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
