/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - PillButton.tsx
 */

import React        from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import classnames   from 'classnames';

import './PillButton.scss';

interface PillButtonProps {
  tooltip?: string,

  children: React.ReactNode

  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void,

  selected?: boolean

  disabled?: boolean

  style?: React.CSSProperties,
}

export const PillButton = ({
                             tooltip,
                             children,
                             onClick,
                             selected: isSelected = false,
                             disabled: isDisabled = false,
                             style,
                           }: PillButtonProps) => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger
        className={ classnames('pill-button', { selected: isSelected }) }
        onClick={ onClick }
        disabled={ isDisabled }
        style={ style }>
        { children }
      </Tooltip.Trigger>
      { tooltip && <Tooltip.Content className="default-tooltip">
        { tooltip }
      </Tooltip.Content> }
    </Tooltip.Root>
  );
};
