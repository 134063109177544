/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryResourceRestriction.ts
 */

import { Model }           from 'library/Model';
import { DateTime }        from 'luxon';
import { UseQueryOptions } from '@tanstack/react-query';
import { makeRoute }       from '../library/modelsUtils';
import { HTTPMethod }      from '../library/Request';
import { makeRequest }     from '../library/Request/Request';

export interface IInventoryResourceRestriction {
  id: number,
  resource_id: number,

  name: string,
  comment: string,

  created_at: DateTime,
  created_by: number
  updated_at: DateTime
  updated_by: number
  deleted_at: DateTime | null
  deleted_by: number | null
}

class InventoryResourceRestrictionModel extends Model<IInventoryResourceRestriction> {
  _slug = 'inventories-resources-restrictions';

  basePath = '/v1/inventories-resources-restrictions';

  attributesTypes: { [attr in keyof IInventoryResourceRestriction]?: (sourceAttr: any) => IInventoryResourceRestriction[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IInventoryResourceRestriction = 'id';

  routesAttributes = {
    create: [ 'resource_id', 'name', 'comment' ],
    save  : [ 'name', 'comment' ],
  };
}

export class InventoryResourceRestriction extends InventoryResourceRestrictionModel implements IInventoryResourceRestriction {
  id!: number;

  resource_id!: number;

  name!: string;

  comment!: string;

  created_at!: DateTime<true>;

  created_by!: number;

  updated_at!: DateTime<true>;

  updated_by!: number;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  static allNamesQueryParams: () => UseQueryOptions<string[]> = () => {
    return {
      queryKey: [ (new InventoryResourceRestriction())._slug, 'names' ],
      queryFn : ({ signal }) => {
        const basePath = (new InventoryResourceRestriction()).basePath;
        const route    = makeRoute(basePath, HTTPMethod.get);

        return makeRequest<string[]>(route, null, null, undefined, undefined, signal)
          .then(response => response.data.sort());
      },
    };
  };
}
