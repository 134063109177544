/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - AuthPanel.tsx
 */

import React              from 'react';
import { useTranslation } from 'react-i18next';

interface AuthPanelProps {
  children: React.ReactNode;
}

const AuthPanel = ({ children }: AuthPanelProps) => {
  const { t } = useTranslation('auth');

  return (
    <main id="auth-panel">
      <div className="connect-logo"/>
      <h1 className="connect-title">Connect</h1>
      { children }
      <div className="legals">
        { t('legals') }
      </div>
    </main>
  );
};

export default AuthPanel;
