/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - GeographicReport.ts
 */

import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { DateTime }                                               from 'luxon';
import { GeographicReportTemplateAreaType }                       from './enums/GeographicReportTemplateAreaType';
import { FeatureCollection }                                      from '@turf/helpers';
import ReportStatus                                               from './enums/ReportStatus';
import { GeographicReportTemplate }                               from './GeographicReportTemplate';
import { Property }                                               from './Property';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { makeRequest }                                            from 'library/Request/Request';

export interface GeographicReportMetadata {
  // For 'Area' reports
  area_type: GeographicReportTemplateAreaType
  distance: number,
  distance_unit: string,
  routing: string,
  area: FeatureCollection,

  // For 'Customers' Reports
  source_file: string,
  source_file_type: string,
  source_file_format: string,

  executionTimeMs: number | null;
  error: Record<string, any> | null,
}

export interface IGeographicReport {
  id: number,
  uuid: string,
  template_id: number,
  property_id: number,
  metadata: GeographicReportMetadata,
  status: ReportStatus

  requested_at: DateTime,
  requested_by: number | null,
  processed_at: DateTime | null,
  deleted_at: DateTime | null,
  deleted_by: number | null,

  property: Property
  template: GeographicReportTemplate,
  values_count: number,
  source_file_url: string,
}

class GeographicReportModel extends Model<IGeographicReport> {
  _slug = 'geographic-report';

  basePath = '/v1/demographics/geographic-reports';

  attributesTypes: { [attr in keyof IGeographicReport]?: (sourceAttr: any) => IGeographicReport[attr] } = {
    property    : Model.make(Property),
    template    : Model.make(GeographicReportTemplate),
    requested_at: (d) => DateTime.fromISO(d),
    processed_at: (d) => DateTime.fromISO(d),
    deleted_at  : (d) => DateTime.fromISO(d),
  };

  key: keyof IGeographicReport = 'id';

  isPaginated = true;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IGeographicReport | string)[] } = {
    create: [ 'property_id', 'template_id' /* template_configuration_index */ ],
    save  : [],
  };
}

export class GeographicReport extends GeographicReportModel implements IGeographicReport {
  id!: number;

  uuid!: string;

  template_id!: number;

  property_id!: number;

  metadata!: GeographicReportMetadata;

  status!: ReportStatus;

  requested_at!: DateTime<true>;

  requested_by!: number | null;

  processed_at!: DateTime<true> | null;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  property!: Property;

  template!: GeographicReportTemplate;

  values_count!: number;

  source_file_url!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async forceProcess() {
    const path       = preparePathForSingleModelAction(this.basePath, `/_force_process`);
    const pathParams = preparePathParameters(path, this);
    const route      = makeRoute(path, HTTPMethod.post);

    await makeRequest<IGeographicReport>(route, pathParams);

    return Promise.all([
      this.invalidate(),
      this.invalidateAll(),
    ]);
  }
}
