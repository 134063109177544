/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Province.ts
 */

import { City, Market }       from 'models';
import Collection             from 'library/Collection';
import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { Country }            from './Country';

export interface IProvince {
  id: number,
  country_id: number,
  name: string,

  slug: string,
  country: Country

  cities: Collection<City>
  markets: Collection<Market>
}

class ProvinceModel extends Model<Province> {
  _slug = 'province';

  basePath = '/v1/countries/{country}/provinces';

  attributesTypes: { [attr in keyof Province]?: (sourceAttr: any) => Province[attr] } = {
    'cities' : Collection.ofType(City).make,
    'markets': Collection.ofType(Market).make,
    'country': Model.make(Country),
  };

  key: keyof Province = 'slug';

  routesAttributes: { [attr in ModelPersistAction]: (keyof Province | string)[] } = {
    create: [],
    save  : [],
  };
}

export class Province extends ProvinceModel implements IProvince {
  cities!: Collection<City>;

  country!: Country;

  country_id!: number;

  id!: number;

  markets!: Collection<Market>;

  name!: string;

  slug!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

}
