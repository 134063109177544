/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - useParameter.ts
 */

import React, { useContext } from 'react';
import Parameter             from 'library/Parameter';
import { ParametersContext } from './ParametersProvider';

export const useParameter = <T>(slug: Parameter) => {
  const parameters = useContext(ParametersContext);

  const param = React.useMemo(() => parameters.findBy('slug', slug), [ parameters, slug ]);

  return param?.value as T;
};
