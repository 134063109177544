/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - useUrlState.ts
 */

import { useSearchParams } from 'react-router-dom';
import React               from 'react';

/**
 * Provides a convenient way to manage a state in the URL
 * Returns argument work identically to React.useState
 *
 * Values has to be a string. Use a specialized version of the hook below to use other types
 * @param key
 * @param initialState
 */
export function useUrlState<T extends string | null>(
  key: string,
  initialState: T | (() => T),
): [ T, (state: T) => void ] {
  const [ searchParams, setSearchParams ] = useSearchParams();

  let urlValue = searchParams.get(key) as T;

  React.useEffect(() => {
    if (urlValue === null) {
      const initialValue = typeof initialState === 'function' ? initialState() : initialState;

      if (initialValue !== null && initialValue.length > 0) {
        searchParams.set(key, initialValue);
      } else {
        searchParams.delete(key);
      }

      setSearchParams(searchParams, { replace: true });
    }
    // This runs only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const set = React.useCallback((value: T | ((oldValue: T) => T)) => {
    const newValue = typeof value === 'function' ? value(urlValue) : value;

    if (newValue === null || newValue.length === 0) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, newValue);
    }

    setSearchParams(searchParams, { replace: true });
  }, [ key, searchParams, setSearchParams, urlValue ]);

  return [ urlValue, set ];
}

/**
 * Convenient hook to manage a string state in the URL without having to specify types
 * @param key
 * @param initialState
 */
export function useStringUrlState(
  key: string,
  initialState: string | (() => string),
): [ string | null, (state: string | null) => void ] {
  return useUrlState<string | null>(key, initialState);
}

export function useBooleanUrlState(key: string, initialState: boolean | (() => boolean)): [ boolean, (state: boolean) => void ] {
  const [ value, setValue ] = useUrlState<'1' | '0'>(key,
    () => (typeof initialState === 'function' ? (initialState()) : initialState) ? '1' : '0',
  );

  const setter = React.useCallback((state: boolean): void => setValue(state ? '1' : '0'), [ setValue ]);

  return [ value === '1', setter ];
}

export function useNullableBooleanUrlState(
  key: string,
  initialState: boolean | null | (() => boolean),
): [ boolean | null, (state: boolean | null) => void ] {
  const [ value, setValue ] = useUrlState<null | '1' | '0'>(key,
    () => {
      const initialValue = (typeof initialState === 'function' ? (initialState()) : initialState);
      return initialValue === null
             ? null
             : (initialValue ? '1' : '0');
    },
  );

  const setter = React.useCallback((state: boolean | null): void => {
      setValue(state === null
               ? null
               : (state ? '1' : '0'),
      );
    },
    [ setValue ],
  );

  return [ value === null ? null : (value === '1'), setter ];
}

export function useNumberUrlState(
  key: string,
  initialState: number | null | (() => number | null),
): [ number | null, (state: number | null) => void ] {
  const [ value, setValue ] = useUrlState<string | null>(key,
    () => {
      const value = (typeof initialState === 'function' ? (initialState()) : initialState);
      return value === null ? null : String(value);
    },
  );

  const setter = React.useCallback((state: number | null): void => setValue(state === null ? null : String(state)), [ setValue ]);

  const numberValue = Number(value);

  return [
    value === null
    ? null
    : (
      isNaN(numberValue)
      ? null : numberValue
    ), setter,
  ];
}
