/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - WeatherBundle.ts
 */

import { DateTime }                                      from 'luxon';
import { Model }                                         from 'library/Model';
import { ModelPersistAction, RouteAttributesDescriptor } from 'library/Model/types';
import WeatherBundleLayout                               from './enums/WeatherBundleLayout';
import { WeatherBundleTargeting }                        from './interfaces/WeatherBundleTargeting';
import WeatherBundleBackgroundSelection                  from './enums/WeatherBundleBackgroundSelection';
import Collection                                        from 'library/Collection';
import { Format }                                        from './Format';
import { WeatherBundleBackground }                       from './WeatherBundleBackground';

interface IWeatherBundle {
  id: number,
  name: string,
  flight_id: number | null,
  start_date: DateTime,
  end_date: DateTime,
  ignore_years: boolean,
  priority: number,
  layout: WeatherBundleLayout,
  targeting: WeatherBundleTargeting,
  background_selection: WeatherBundleBackgroundSelection,

  created_at: DateTime,
  created_by: number,
  updated_at: DateTime,
  updated_by: number,
  deleted_at: DateTime | null,
  deleted_by: number | null,

  formats: Collection<Format>
  backgrounds: Collection<WeatherBundleBackground>
}

class WeatherBundleModel extends Model<IWeatherBundle> {
  _slug: string = 'weather-bundle';

  basePath: string = '/v1/dynamics/weather/bundles';

  key: string = 'id';

  attributesTypes: { [attr in keyof IWeatherBundle]?: (sourceAttr: any) => IWeatherBundle[attr] } = {
    start_date : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    end_date   : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    formats    : Collection.ofType(Format).make,
    backgrounds: Collection.ofType(WeatherBundleBackground).make,
  };

  routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<IWeatherBundle> } = {
    create: {
      name                : 'name',
      flight_id           : 'flight_id',
      start_date          : model => model.start_date.toISODate(),
      end_date            : model => model.end_date.toISODate(),
      ignore_years        : 'ignore_years',
      priority            : 'priority',
      layout              : 'layout',
      background_selection: 'background_selection',
      format_ids          : model => model.formats?.pluck('id') ?? [],
    },
    save  : {
      name                : 'name',
      flight_id           : 'flight_id',
      start_date          : model => model.start_date.toISODate(),
      end_date            : model => model.end_date.toISODate(),
      ignore_years        : 'ignore_years',
      priority            : 'priority',
      layout              : 'layout',
      targeting           : 'targeting',
      background_selection: 'background_selection',
      format_ids          : model => model.formats?.pluck('id') ?? [],
    },
  };
}

export class WeatherBundle extends WeatherBundleModel implements IWeatherBundle {
  background_selection!: WeatherBundleBackgroundSelection;

  backgrounds!: Collection<WeatherBundleBackground>;

  created_at!: DateTime;

  created_by!: number;

  deleted_at!: DateTime | null;

  deleted_by!: number | null;

  end_date!: DateTime;

  flight_id!: number | null;

  id!: number;

  ignore_years!: boolean;

  layout!: WeatherBundleLayout;

  name!: string;

  priority!: number;

  start_date!: DateTime;

  targeting!: WeatherBundleTargeting;

  updated_at!: DateTime;

  updated_by!: number;

  formats!: Collection<Format>;


  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
