/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - InventoryResourceType.ts
 */

export enum InventoryResourceType {
  Product         = 'product',
  Property        = 'property',
  ProductCategory = 'product-category',

  PropertyType    = 'property-type',
  ScreenType      = 'screen-type',
}
